import React, { useState } from 'react'

export default function VrayGamma({}) {
    const [gammaCorrection , setGammaCorrection] = useState(false);

    function toggleGramma() {
         gammaCorrection!== true ? setGammaCorrection(true) : setGammaCorrection(false)
    } 

    return (

            <div className="form-group row mt-2">
                <div className="col-lg-10">
                    <div className="row">
                        <div className="col-lg-2 my-auto">
                            <div className="custom-control custom-switch custom-checkbox-success">
                                <input type="checkbox" className="custom-control-input" id="gammaCorrection" checked={gammaCorrection} />
                                <label className="custom-control-label" style={{userSelect: "none" }} htmlFor="gammaCorrection" onClick={()=>toggleGramma()}>Gamma correction </label>
                            </div>
                        </div>
                        <div className={gammaCorrection==false?'d-none fade-in':'d-block fade-in col-lg-8'}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <label htmlFor="inputGamma" >Input Gamma<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control col-form-label" id="inputGamma" placeholder="Input Gamma" value="2.0" />
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="outputGramma" >Output Gamma<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control col-form-label" id="outputGramma" placeholder="Output Gamma" value="1.1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}
