import React from 'react'


export default function RenderSettings({}) {
    return(
    <div>
    <hr />
    <div className="h5">Render Settings</div>
        <div className="form-group row">
            <div className="col-lg-10">
                <div className="row">
                    <label className="col-lg-2 col-form-label my-auto">Frames Per Node<span className="text-danger">*</span></label>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="form-group m-0">
                                    <input className="form-control" type="number" min="1" name="frame_per_task" value="1" />
                                </div>
                            </div>
                            <div className="col-lg-8 my-auto">
                                <p>Recommend set number of frames per node &gt; 1 if your scene require less than 10 minutes to render 1 frame</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="form-group row">
            <div className="col-lg-10">
                <div className="row">
                    <label className="col-lg-2 col-form-label my-auto">Render Priority:</label>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="form-group m-0">
                                    <select id="render_priority" className="form-control" name="render_priority">
                                        <option value="10">bronze - 0.004 Render Credits / GHz-hr - 20 render nodes</option>
                                        <option value="20">silver - 0.008 Render Credits / GHz-hr - 80 render nodes</option>
                                        <option value="30">gold - 0.016 Render Credits / GHz-hr - 200+ render nodes</option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    )
}