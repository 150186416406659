const initialState = {
    send_from_scene_analyze : null ,

    srf_cloud_type : null ,

    display_cloud_type : null ,

    scene_name : null ,

    scene_path : null ,

    working_folder_path : null ,

    software_version : null ,

    renderer_version : null ,

    scene_3dApp : null ,

    gcpu_type : null ,

    renderoutput_path : null ,

    multipassoutput_folder : null ,

    warning_file_type_error : null ,

    warning_scene_name_error : null ,

    farm_manager : null ,

    scene_history_info_id : null ,

    renderwidth : null ,

    renderheight : null ,

    ratio : null ,

    job_type : null ,

    start_frame : null ,

    end_frame : null ,

    frame_steps : null ,

    frame : null ,

    single_render_tile : null ,

    camera : null ,

    output_grammar_corection : null ,

    gamma_correction : null ,

    input_gamma : null ,

    output_gamma : null ,

    outputNameFile : null ,

    output_extension : null ,

    bmp_type : null ,

    jpg_quality : null ,

    jpg_smoothing : null ,

    cjpg_quality : null ,

    cjpg_smoothing : null ,

    cjp_quality : null ,

    cjp_smoothing : null ,

    png_type : null ,

    png_alpha : null ,

    png_interlaced : null ,

    tif_type : null ,

    tif_alpha : null ,

    tif_compression : null ,

    tif_dpi : null ,

    exr_main_layer_format : null ,

    exr_main_layer_type : null ,

    exr_compression : null ,

    rf_render_flags : null ,

    rf_perform_color_check : null ,

    rf_force_two_sided : null ,

    rf_render_hidden_objects : null ,

    rf_use_atmospherics_effects : null ,

    rf_use_super_black : null ,

    rf_perform_displacement_mapping : null ,

    rf_perform_render_effects : null ,

    rf_use_advanced_lighting : null ,

    rf_compute_advanced_lighting : null ,

    rf_toggles_output_dithering_paletted : null ,

    rf_toggles_output_dithering_true_color : null ,

    rf_convert_area_lights_to_points_sources : null ,

    frame_per_task : null ,

    render_priority : null 

  }

export default (state = initialState, action) => {
    switch (action.type) {


        case "update_send_from_scene_analyze" :
            return { ... state , send_from_scene_analyze : action.send_from_scene_analyze }
            
        /** Update */
        case "update_user_name" :
            return { ... state , user_name : action.user_name }
            
        /** Update */
        case "update_srf_cloud_type" :
            return { ... state , srf_cloud_type : action.srf_cloud_type }
            
        /** Update */
        case "update_display_cloud_type" :
            return { ... state , display_cloud_type : action.display_cloud_type }
            
        /** Update */
        case "update_scene_name" :
            return { ... state , scene_name : action.scene_name }
            
        /** Update */
        case "update_scene_path" :
            return { ... state , scene_path : action.scene_path }
            
        /** Update */
        case "update_working_folder_path" :
            return { ... state , working_folder_path : action.working_folder_path }
            
        /** Update */
        case "update_software_version" :
            return { ... state , software_version : action.software_version }
            
        /** Update */
        case "update_renderer_version" :
            return { ... state , renderer_version : action.renderer_version }
            
        /** Update */
        case "update_scene_3dApp" :
            return { ... state , scene_3dApp : action.scene_3dApp }
            
        /** Update */
        case "update_gcpu_type" :
            return { ... state , gcpu_type : action.gcpu_type }
            
        /** Update */
        case "update_renderoutput_path" :
            return { ... state , renderoutput_path : action.renderoutput_path }
            
        /** Update */
        case "update_multipassoutput_folder" :
            return { ... state , multipassoutput_folder : action.multipassoutput_folder }
            
        /** Update */
        case "update_warning_file_type_error" :
            return { ... state , warning_file_type_error : action.warning_file_type_error }
            
        /** Update */
        case "update_warning_scene_name_error" :
            return { ... state , warning_scene_name_error : action.warning_scene_name_error }
            
        /** Update */
        case "update_farm_manager" :
            return { ... state , farm_manager : action.farm_manager }
            
        /** Update */
        case "update_scene_history_info_id" :
            return { ... state , scene_history_info_id : action.scene_history_info_id }
            
        /** Update */
        case "update_renderwidth" :
            return { ... state , renderwidth : action.renderwidth }
            
        /** Update */
        case "update_renderheight" :
            return { ... state , renderheight : action.renderheight }
            
        /** Update */
        case "update_ratio" :
            return { ... state , ratio : action.ratio }
            
        /** Update */
        case "update_job_type" :
            return { ... state , job_type : action.job_type }
            
        /** Update */
        case "update_start_frame" :
            return { ... state , start_frame : action.start_frame }
            
        /** Update */
        case "update_end_frame" :
            return { ... state , end_frame : action.end_frame }
            
        /** Update */
        case "update_frame_steps" :
            return { ... state , frame_steps : action.frame_steps }
            
        /** Update */
        case "update_frame" :
            return { ... state , frame : action.frame }
            
        /** Update */
        case "update_single_render_tile" :
            return { ... state , single_render_tile : action.single_render_tile }
            
        /** Update */
        case "update_camera" :
            return { ... state , camera : action.camera }
            
        /** Update */
        case "update_output_grammar_corection" :
            return { ... state , output_grammar_corection : action.output_grammar_corection }
            
        /** Update */
        case "update_gamma_correction" :
            return { ... state , gamma_correction : action.gamma_correction }
            
        /** Update */
        case "update_input_gamma" :
            return { ... state , input_gamma : action.input_gamma }
            
        /** Update */
        case "update_output_gamma" :
            return { ... state , output_gamma : action.output_gamma }
            
        /** Update */
        case "update_outputNameFile" :
            return { ... state , outputNameFile : action.outputNameFile }
            
        /** Update */
        case "update_output_extension" :
            return { ... state , output_extension : action.output_extension }
            
        /** Update */
        case "update_bmp_type" :
            return { ... state , bmp_type : action.bmp_type }
            
        /** Update */
        case "update_jpg_quality" :
            return { ... state , jpg_quality : action.jpg_quality }
            
        /** Update */
        case "update_jpg_smoothing" :
            return { ... state , jpg_smoothing : action.jpg_smoothing }
            
        /** Update */
        case "update_cjpg_quality" :
            return { ... state , cjpg_quality : action.cjpg_quality }
            
        /** Update */
        case "update_cjpg_smoothing" :
            return { ... state , cjpg_smoothing : action.cjpg_smoothing }
            
        /** Update */
        case "update_cjp_quality" :
            return { ... state , cjp_quality : action.cjp_quality }
            
        /** Update */
        case "update_cjp_smoothing" :
            return { ... state , cjp_smoothing : action.cjp_smoothing }
            
        /** Update */
        case "update_png_type" :
            return { ... state , png_type : action.png_type }
            
        /** Update */
        case "update_png_alpha" :
            return { ... state , png_alpha : action.png_alpha }
            
        /** Update */
        case "update_png_interlaced" :
            return { ... state , png_interlaced : action.png_interlaced }
            
        /** Update */
        case "update_tif_type" :
            return { ... state , tif_type : action.tif_type }
            
        /** Update */
        case "update_tif_alpha" :
            return { ... state , tif_alpha : action.tif_alpha }
            
        /** Update */
        case "update_tif_compression" :
            return { ... state , tif_compression : action.tif_compression }
            
        /** Update */
        case "update_tif_dpi" :
            return { ... state , tif_dpi : action.tif_dpi }
            
        /** Update */
        case "update_exr_main_layer_format" :
            return { ... state , exr_main_layer_format : action.exr_main_layer_format }
            
        /** Update */
        case "update_exr_main_layer_type" :
            return { ... state , exr_main_layer_type : action.exr_main_layer_type }
            
        /** Update */
        case "update_exr_compression" :
            return { ... state , exr_compression : action.exr_compression }
            
        /** Update */
        case "update_rf_render_flags" :
            return { ... state , rf_render_flags : action.rf_render_flags }
            
        /** Update */
        case "update_rf_perform_color_check" :
            return { ... state , rf_perform_color_check : action.rf_perform_color_check }
            
        /** Update */
        case "update_rf_force_two_sided" :
            return { ... state , rf_force_two_sided : action.rf_force_two_sided }
            
        /** Update */
        case "update_rf_render_hidden_objects" :
            return { ... state , rf_render_hidden_objects : action.rf_render_hidden_objects }
            
        /** Update */
        case "update_rf_use_atmospherics_effects" :
            return { ... state , rf_use_atmospherics_effects : action.rf_use_atmospherics_effects }
            
        /** Update */
        case "update_rf_use_super_black" :
            return { ... state , rf_use_super_black : action.rf_use_super_black }
            
        /** Update */
        case "update_rf_perform_displacement_mapping" :
            return { ... state , rf_perform_displacement_mapping : action.rf_perform_displacement_mapping }
            
        /** Update */
        case "update_rf_perform_render_effects" :
            return { ... state , rf_perform_render_effects : action.rf_perform_render_effects }
            
        /** Update */
        case "update_rf_use_advanced_lighting" :
            return { ... state , rf_use_advanced_lighting : action.rf_use_advanced_lighting }
            
        /** Update */
        case "update_rf_compute_advanced_lighting" :
            return { ... state , rf_compute_advanced_lighting : action.rf_compute_advanced_lighting }
            
        /** Update */
        case "update_rf_toggles_output_dithering_paletted" :
            return { ... state , rf_toggles_output_dithering_paletted : action.rf_toggles_output_dithering_paletted }
            
        /** Update */
        case "update_rf_toggles_output_dithering_true_color" :
            return { ... state , rf_toggles_output_dithering_true_color : action.rf_toggles_output_dithering_true_color }
            
        /** Update */
        case "update_rf_convert_area_lights_to_points_sources" :
            return { ... state , rf_convert_area_lights_to_points_sources : action.rf_convert_area_lights_to_points_sources }
            
        /** Update */
        case "update_frame_per_task" :
            return { ... state , frame_per_task : action.frame_per_task }
            
        /** Update */
        case "update_render_priority" :
            return { ... state , render_priority : action.render_priority }
            
        case "update_scene_json" :
            return { ...state, ...action , ...{ type : undefined } } 
        default:
            return state

    }
};
