import React from 'react'
import WOARenderPriority from '../ParamSettings/RenderPriority'
import WOAHoudiniSoftwareVersion from './SoftwareVersion'
import WOAHoudiniEngineVersion from './EngineVersion'
import WOAHoudiniROPToRender from './ROPToRender'
import WOAHoudiniOutputFormat from './OutputFormat'
import WOAHoudiniOutputExtension from './OutputExtension'
import WOAStartFrame from '../ParamSettings/StartFrame'
import WOAEndFrame from '../ParamSettings/EndFrame'
import WOAFrameStep from '../ParamSettings/FrameStep'
import WOARenderOutputFileNoExtension from '../ParamSettings/RenderOutputFileNoExtension'



export default function WOAHoudini () {
  return(
      <div>
        <div className="row" id="renderer_environment_container" >
            <div className="col-lg-6">
              <WOAHoudiniSoftwareVersion />
            </div>
            <div className="col-lg-6">
              <WOAHoudiniEngineVersion />
            </div>
        </div>

      
        <div className="row">
          <div className="col-lg-4" id="start_frame_form_group">
            <WOAStartFrame />
          </div>
          <div className="col-lg-4" id="end_frame_form_group">
            <WOAEndFrame />
          </div>
          <div className="col-lg-4" id="frame_steps_form_group">
            <WOAFrameStep />
          </div>
          <div className="col-lg-12" id="output_driver_form_group">
            <WOAHoudiniROPToRender />
          </div>
          <div className="col-lg-4" id="renderoutput_file_form_group">
            <WOARenderOutputFileNoExtension />
          </div>
          <div className="col-lg-8" id="output_format_form_group">
              <div className="row">
                <div className="col-lg-6">
                  <WOAHoudiniOutputFormat />
                </div>
                <div className="col-lg-6">
                  <WOAHoudiniOutputExtension />
                </div>
              </div>
          </div>
      </div>
      <div className="row">
        <div className="col-lg-10">
          <WOARenderPriority />
        </div>
        <div className="col-lg-2 text-right mt-auto">
          <button className="btn btn-success btn-block text-dark form-group">
          <i className="fad fa-star-of-david me-2"></i>Start Render Job
          </button>
        </div>
      </div>
    </div>

  )
}
