import React from 'react'
import { MDBBtn, MDBNavItem, MDBLink, MDBTabContent, MDBTabPane, MDBRow, MDBCol ,MDBNav} from 'mdbreact';
import QuickTips from '../../components/QuickTips/QuickTips';

class TransferMethod extends React.Component {
    state = {
        activeItemPills: '1'
    };
    
    togglePills = tab => () => {
        const { activePills } = this.state;
        if (activePills !== tab) {
            this.setState({
                activeItemPills: tab
            });
        }
    };
    render() {
    const { activeItemPills } = this.state;
    return (
        <div >
            <div className="d-lg-flex d-block">
                <span className="my-auto h3 mb-0">Transfer Method</span>
                <div className="ms-auto">
                <QuickTips />
                </div>
            </div>
            <div className="card-body bg-dark text-white text-break rounded mb-2">
                <p className="my-2"><i className="fad fa-check"></i> SuperRenders Farm now connected with your folder <strong><i className="fas fa-folder me-1"></i>GoogleDrive/superrenders_shimakazevn1</strong></p>
                <p className="my-2"><i className="fad fa-check"></i> Your render outputs will be synced to <strong><i className="fas fa-folder me-1"></i>GoogleDrive/superrenders_shimakazevn1/SuperRendersOutput</strong></p>
                <p className="my-2"><i className="fad fa-check"></i> This is your secondary file storage on SuperRenders Farm. We recommend to use this storage with projects larger than 5GB in size.</p>
            </div>
        <div className="p-0 col-lg-7 m-auto"> 
            
            <MDBNav className='nav-pills'>
            <MDBNavItem>
              <MDBLink to='#' active={activeItemPills === '1'} onClick={this.togglePills('1')} link >
                <i className="fab fa-google-drive me-2"></i>Google Drive
              </MDBLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBLink to='#' active={activeItemPills === '2'} onClick={this.togglePills('2')} link>
              <i className="fab fa-dropbox me-2"></i>DropBox 
              </MDBLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent activeItem={activeItemPills} className="mt-2 card card-body">
            <MDBTabPane tabId='1'>
            <div className="text-light">
                <div className="text-center h5">
                Quick guide:
                </div>
                <p className="my-2"><i className="fad fa-hand-point-right"></i> Provide your GoogleDrive's email account as below. SuperRenders will share a folder to your account</p>
                <p className="my-2"><i className="fad fa-hand-point-right"></i> Add this folder to your GoogleDrive, then you will connected to SuperRenders Farm.</p>                               
                <div className="form-group mt-5">
                    <label className="form-label semibold">Your Email </label>
                    <div className="form-control-wrapper">
                        <input type="email" className="form-control" placeholder="Your e-mail" />
                        <small className="font-italic text-muted">P/s: If you have large project source and already in GoogleDrive, just let us know. Our support team will connect your GoogleDrive directly.</small>
                    </div>
                </div>
                <div className="text-center mt-5">
                    <MDBBtn className="m-2" color="primary">Get Share Folder</MDBBtn>
                </div>
            </div>
            </MDBTabPane>
            <MDBTabPane tabId='2'>
              <div className="text-light">
                <div className="text-center h5">
                    Quick guide:
                </div>
                <p className="my-2"><i className="fad fa-hand-point-right"></i> Provide your DropBox's email account as below. SuperRenders will share a folder to your account</p>
                <p className="my-2"><i className="fad fa-hand-point-right"></i> Add this folder to your DropBox, then you will connected to SuperRenders Farm.</p>                               
                <div className="form-group mt-5">
                    <label className="form-label semibold">Your Email </label>
                    <div className="form-control-wrapper">
                        <input type="email" className="form-control" placeholder="Your e-mail" />
                        <small className="font-italic text-muted">P/s: If you have large project source and already in GoogleDrive, just let us know. Our support team will connect your DropBox directly.</small>
                    </div>
                </div>
                <div className="text-center mt-5">
                    <MDBBtn className="m-2" color="primary">Get Share Folder</MDBBtn>
                </div>
              </div>
            </MDBTabPane>
          </MDBTabContent>
            
        
        </div>
    </div>
      )}
}
export default TransferMethod ;