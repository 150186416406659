import React, { useState } from 'react'

import SpaceFilemanager from '../../components/Spaces/Filemanager/SpaceFilemanager'
import SpacesAlert from '../../components/Spaces/Alert/SpacesAlert'
import SpacesUploadRow from '../../components/Spaces/UploadRow/SpacesUploadRow'
import './spaces.scss'
import Stores from '../../redux/store'
import Logger from '../../utils/debug'
import Translate from '../../components/Translate'
import Tippy from '@tippyjs/react'
import QuickTips from '../../components/QuickTips/QuickTips'

function Spaces () {


    const [value, setValue] = useState()
    const refresh = ()=>{
        // re-renders the component
        setValue({})
        
    }
  return (

      <div >
        <div className="d-flex">
            <div className="h3 text-light m-0">
                <Translate text="SRF Spaces" />
                <Tippy content={<Translate text="rebuild your working folder"/>} placement="right" >
                    <div className="btn btn-floating btn-sm text-light"  onClick={refresh}>
                    <i className="fad fa-sync" />
                    </div> 
                </Tippy>
                <small className="d-block" >{ Stores.getState().UserStates.uwf  }</small>
            </div>
            <QuickTips />
        </div>
        <SpacesAlert />
        <SpacesUploadRow />
        <SpaceFilemanager />
      </div>
  )
}

export default Spaces ;