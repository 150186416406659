import React  from 'react'

export default function WOAHoudiniROPToRender({}) {

    return (
        <div className="form-group">
            <label className="form-label semibold">ROP to Render*</label>
            <div className="form-control-wrapper">
            <input id="output_driver" type="text" className="form-control" placeholder="i.e: /out/mantra1"  />
            </div>
        </div>
    )
}