import React from 'react'

export default function WOARenderPriority(){
    return (
        <div className="form-group">
            <label htmlFor="render_priority" className="control-label">Render Priority*</label>
            <select id="render_priority" className="form-control srf_require_field">
            <option value={10}>bronze - 0.004 Render Credits / GHz-hr - 20 render nodes</option>
            <option value={20}>silver - 0.008 Render Credits / GHz-hr - 80 render nodes</option>
            <option value={30}>gold - 0.016 Render Credits / GHz-hr - 200+ render nodes</option>
            </select>
        </div>
    )
}