import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {  Link, useParams } from "react-router-dom";
import S3Downloader from '../../utils/S3.Download';
import Logger from "../../utils/debug";
import { SpaceRedirect } from "../../utils/SpaceRedirect";
import swal from 'sweetalert';
import SceneAnalyzeForm from "../../forms/SceneAnalyze.jsx";
import Stores from "../../redux/store";
import axios from "axios";
import env from "../../configs/env";
import SceneAnalyzeStatus from "../FolderManager/SceneAnalyzeStatus";
import FileExtensionIcon from "../FolderManager/FileExtensionIcon";
import FileHintTippy from "../FolderManager/FileHintTippy";
import { useState } from "react";

/**
 * S3 Download Handler
 * 
 * @param {AWS.S3} S3 Amazon S3 Class
 * @param {Spaces.fileType} files Spaces File 
 * @returns 
 */
const DownloadFolder =  (S3 = {}  , files) => {
  
  if(!localStorage.getItem("uwf")) return window.location = "/login"
  const USERNAME = localStorage.getItem("uwf")
  
  const REALPATH = `${USERNAME}${files.path}/`
  
  const s3Download = new S3Downloader( )
  
  s3Download.initial(REALPATH, S3)

  s3Download.fetchAllSubItems() ;

  s3Download.on('data',  data => {
    // Logger('DOWNLOAD_ZIP:SUCCESS',data)
  })
}

/**
 * Listen to user click at scene file.
 * 
 * @param {Space.FileItem} item Space file type
 */
function sceneClickHandle(item){

  /** If Scene was not found in analyzed scene */
  if( item.sceneStatus.scene_status == "SceneNotFound" ){
    /** If scene is failed or not analyzed */
    return swal({
      title: "Analyze your scene?",
      text: "It is recommended that you should analyze the scene before starting to render your project!",
      icon: "info",
      buttons: {
        cancel: false,
        render: {
          text: "Render now",
          value:"render"
        },
        analyze: {
          text: "Analyze now",
          value: "analyze",
        },
      }
    }).then((value) => {
      if (value === "render") {
        SpaceRedirect({to :"/new-render-job/"+item.name})
      } 
      if (value === "analyze") {
        var modal = document.querySelector(`.analyze-modal-id-${item.id}`);
        var container = modal.querySelector(".container");
          modal.classList.add("d-block")

          modal.addEventListener("click", function (e) {
          if (e.target !== modal && e.target !== container) return;     
          modal.classList.remove("d-block");
        });
      }
      
    })
  }

  /** If current scene is waiting for analyzing  */
  if(['WaitingForAnalyzing' , 'InAnalyzing' ].indexOf(item.sceneStatus.scene_status) >= 0 ) {
    return swal({
      title: "This scene is busy",
      text  : "Please check back your scene status and go back here when scene analyzed complete",
      icon : "warning",
      buttons : {
        ok : true,
        goto : {
          text : "Go to Scene Analysis",
          className: "bg-success text-to-dark",
          value : "goSceneAnalysis"
        }
      }
    }).then( val => {
      if(val=="goSceneAnalysis") SpaceRedirect({to : "/list-scene-analyze/"})
    })
  }

  /** If analyzed success  */
  if( item.sceneStatus.scene_status == "AnalyzeCompleted" ) {
    return swal({
      title: "This scene has been analyzed!",
      text : "You can start rendering a job using the latest analysis or perform the analysis process again!" ,
      icon : "success",
      buttons : {
        // Open render modal without Analysis JSON
        skip : {
          text : "Skip",
          value : 'skip',
        },
        // open Analyze Modal
        reAnalyze : {
          text : "Reanalyze",
          value : 'Reanalyze',
        },
        // Continue to Scene Analyze Render Job
        continue : {
          text : "Continue",
          value : "continue"
        }
      }
    }).then( val => {
      
      /** Go to Scene Analyze to render */ 
      if(val=="continue") SpaceRedirect({to : `/scene-analyze/${item.sceneStatus.scene_id}`})

      /** Open scene analyze modal */
      if( val == "Reanalyze") {
        var modal = document.querySelector(`.analyze-modal-id-${item.id}`);
        var container = modal.querySelector(".container");
        modal.classList.add("d-block")

        modal.addEventListener("click", function (e) {
          if (e.target !== modal && e.target !== container) return;     
          modal.classList.remove("d-block");
        })
      }

      /** When user click skip and go to render job without analyze */
      if( val == "skip") SpaceRedirect({to : `/new-render-job/${item.path}`})

    })
  }

  /** Analyzed scene fail */
  if( item.sceneStatus.scene_status == "AnalyzeFailed" ) {
    return swal({
      title: "The analysis process failed!",
      text : "The analysis process failed, please try to analyze again!",
      icon : "error",
      buttons : {
        cancel : true,
        reAnalyze : {
          text  : "Reanalyze",
          value : "reanalyze",
        }
      }
    }).then((value) => {
      if (value === "render") {
        SpaceRedirect({to :"/new-render-job/"+item.name})
      } 
      if (value === "reanalyze") {
        var modal = document.querySelector(`.analyze-modal-id-${item.id}`);
        var container = modal.querySelector(".container");
          modal.classList.add("d-block")

          modal.addEventListener("click", function (e) {
          if (e.target !== modal && e.target !== container) return;     
          modal.classList.remove("d-block");
        });
      }
      
    })
  }

}

/**
 * Scene analyze submit handle
 * 
 * @param {HTMLFormElement} formEvent Form Submit event 
 * @param {FormData} formData Form data
 */
function submitSceneAnalyze( formEvent , sceneData) {

  /**
   * Initial get user data
   */
  const UserStates = Stores.getState().UserStates


  /**
   * DEBUG TO CHECK USER DATA
   * 
   */
  Logger("SCENE_FORM:SUBMIT:WARN", sceneData)

  /**
   * Cloud type stored in database is GoogleDrive or Dropbox.
   * But when use for api. we need to change from "GoogleDrive" to "gDrive" and "Dropbox" to "dbx"
   * 
   * @param {String} cloudType GoogleDrive || Dropbox
   * 
   */
  function cloudTypeRefactor( cloudType ){

    // GoogleDrive to gDrive
    if( cloudType === "GoogleDrive" ) return "gDrive"

    // Dropbox to dbx
    if( cloudType === "Dropbox" ) return "dbx"

    // If cloudType null we return awss3.
    return "awss3"

  }
  /**
   * Submit to scene analyze api
   * @method POST
   * 
   */
  axios.post(
    `${env.APP_API}/analyze-job/create/scene-analyze`, // API URL 
    /**
     * Config form data to api
     */
    {
      scene_path : `/${sceneData.item.Key}`, // S3 Path is scene_path too
      source_cloud_type : cloudTypeRefactor(UserStates.cloudFolderType) , // Idk
      software_app : sceneData.formData.app, // 3D Engine Name
      gcpu_type : sceneData.formData.renderType, // CPU or GPU render engine
      software_version : sceneData.formData.softwareVersion, // Version of 3D Engine
      renderer_version : sceneData.formData.renderEngineVersion , // Render Version of 3D Engine
    },
    /**
     * Config authorization hearer 
     * 
     */
    {
      headers : {
        Authorization : `Bearer ${localStorage.getItem("token")}`
      }
    }
  )
  .then(res => {
    /**
     * Success
     * 
     */
    Logger("FORM_SUBMIT:RESPONSE:SUCCESS" , res.data )

    /**
     * Close Modal on Success
     * 
     */
    document.querySelector(".analyze-modal").classList.remove("d-block")

    /**
     * Notify to client
     * 
     */
    if(res.data.status == "success"){
      swal({
        title : "Analyzing your scene !",
        icon : "success",
        text : "Check your analyzing scene now ?",
        buttons  : {
          cancel : true,
          view : {
            text : "Analyze scene list",
            value : "go"
          }
        }
      }).then( value => {
        if( value == "go"){
          return SpaceRedirect({to : "/render-dashboard"})
        }
      })
    }
    

  })
  .catch(err => {
    /**
     * Failure
     */
    Logger("FORM_SUBMIT:RESPONSE:FAIL", "API return an error ")
    
  })

}

/**
 * Item element in grid view 
 * @param {File} item
 */
function SceneGridItem({item}){
  const [sceneStatus, setSceneStatus] = useState({})
  return (
      <div key={item.id} className="folderGrid col-xl-3 col-lg-4 col-md-6 my-2">
        <div className="cursor-pointer" onClick={() =>{ sceneClickHandle({...item, sceneStatus }) } }>
          <div className="folder-srf d-flex align-items-center px-2 bg-dark rounded">
              <Tippy content={<FileHintTippy item={item} /> }>
                <div className="my-auto ms-2 me-3 pt-3 pb-2">
                    <figure className="avatar avatar-sm">
                    { <FileExtensionIcon fileExtension={item.fileExtension} /> } 
                    </figure>
                </div>
              </Tippy>
              <div className="d-inline-block text-truncate mt-2" style={Styles.FolderItemName} >
                  {item.name} 
              </div>
          </div>
        </div>
        <div className="action-srf">

        {/** Get Scene Analyze Status */}
        <SceneAnalyzeStatus path={item.path} onSceneStatus={ e => setSceneStatus(e) }  />

        <div className="moreAction">
            <button type="button" className="btn btn-dark btn-floating" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="ti-more-alt"></i>
            </button>
            <ul className="dropdown-menu dropdown-menu-end" >
              <li><button className="dropdown-item" type="button">Download</button></li> 
              <li><button className="dropdown-item" type="button">Delete</button></li> 
            </ul>
        </div>
        </div>
        {/** On user click element modals */}
        <div className={`modal analyze-modal analyze-modal-id-${item.id} fade-in show`} tabIndex="-1" role="dialog">
          <div className="container modal-dialog  modal-lg modal-dialog-centered" role="document">
            <div className="modal-content bg-dark">
            
              {/** Analyze Scene Form */}
                <SceneAnalyzeForm 
                  scene={item} // if sceneStatus updated, render modal by status value
                  onSubmit={
                    (formEvent , formData) => {
                      // Submit scene analyze handle
                      submitSceneAnalyze( formEvent , {formData, item, app: item.fileExtension})
                    }} />

            </div>
          </div>
        </div>
      </div>
    )
  
}

/**
 * Folder Element in grid view
 * @returns {React.Component}
 */
function FolderItem({item,path,columns}){
  return (
    <div key={item.id} className="folderGrid col-xl-3 col-lg-4 col-md-6 my-2">
      <Link to={`${item.path}`}>
        <div className="d-flex align-items-center folder-srf px-2 bg-dark rounded">
          <Tippy
            content={
              <div className="p-2 text-break">
                <h5 className="m-0">
                  {item.name.length === 1 && isNaN() && path === ""
                    ? "Hard Drive (" + item.name + ":)"
                    : item.name}
                </h5>
              </div>
            }
          >
            <div className="my-auto ms-2 me-3 pt-3 pb-2">
              <figure className="avatar avatar-sm">
                {item.name.length === 1 && isNaN() && path === "" ? (
                  <span className="avatar-title rounded-pill">
                    <img src="https://statics.superrendersfarm.com/app/assets/media/image/hhd.png" alt="hhd" />
                  </span>
                ) : (
                  <span className="avatar-title rounded-pill">
                    <img src="https://statics.superrendersfarm.com/app/assets/media/image/folder.png" alt="folder" />
                  </span>
                )}
              </figure>
            </div>
          </Tippy>
          <div className="my-auto w-100">
            <div
              className="d-inline-block text-truncate mt-2"
              style={ Styles.FolderItemName }
            >
              {(() => {
                if (item.name.length === 1 && isNaN() && path === "") {
                  return "(" + item.name + ":)";
                } else return item.name;
              })()}
            </div>
          </div>
        </div>
      </Link>
        <div className="action-srf">
          <div className="moreAction">

            <button type="button" className="btn btn-dark btn-floating" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="ti-more-alt"></i>
            </button>
            
            <ul className="dropdown-menu dropdown-menu-end" >
              <li><a className="dropdown-item" href="#" onClick={()=> { DownloadFolder(columns,item)}} >Download</a></li>
              <li><button className="dropdown-item" type="button">Delete</button></li> 
            </ul>

          </div>
        </div>
      
    </div>
  );
}

/**
 * 
 * @param {File} files JS File
 * @param {DataTable.row} columns 
 */
function FileManager({files,columns}) {
  const { path } = useParams();
  const items = files.map((item) => {
    /**
     * Return Folder type template 
     * 
     * 
     */
    if (item.fileType === "folder") {
      return <FolderItem item={item} path={path} columns={columns} />
    }

    /**
     * Return scene item template
     * @param fileExtension
     * - hip || hipnc || hiplc || ma || mb || nk || aep || blend
     * 
     */
    if (
      (item.fileType === "file") &&
      (item.fileExtension === "max") | // 3Ds Max
      (item.fileExtension === "c4d") | // Cinema4D
      (item.fileExtension === "blend") | // Blender
      (item.fileExtension === "ma") | // Maya
      (item.fileExtension === "mb") | // Maya 
      (item.fileExtension === "hip") | // Houdini
      (item.fileExtension === "hipnc") | // Houdini
      (item.fileExtension === "hiplc") | // Houdini
      (item.fileExtension === "nk") | // Nuke
      (item.fileExtension === "aep") // Adobe After Effects
    ) {
      return <SceneGridItem item={item} />
    }
    if (item.fileType === "file" && item.name.length >= 1) {

      /*** Another file item template */
      return (
        <div key={item.id} className="folderGrid col-xl-3 col-lg-4 col-md-6 my-2">
          <div className="d-flex align-items-center folder-srf px-2 bg-dark rounded">
            
            <Tippy content={<FileHintTippy item={item} />}>
              <div className="my-auto ms-2 me-3 pt-3 pb-2">
                <figure className="avatar avatar-sm">
                  <span className="avatar-title rounded-pill">
                    <img
                      src="https://statics.superrendersfarm.com/app/assets/media/image/file.png"
                      alt="Unknown File Extension"
                    />
                  </span>
                </figure>
              </div>
            </Tippy>

            <div className="my-auto w-100">
              <div className="d-inline-block text-truncate mt-2" style={Styles.SceneItemName}> 
              {item.name}
              </div>
            </div>
          </div>

          <div className="action-srf">
            <div className="moreAction">
              <div className="btn btn-floating btn-dark bg-danger-bright" onClick={() => alert("Delete this file!")}>
                <i className="fad fa-trash-alt"></i>
              </div>
            </div>
          </div>

        </div>
      )
    }
  })

  return items

}

/*** JS StyleSheet */
const Styles = {
  /*** Grid view scene file title*/
  SceneItemName : {cursor: "default",userSelect: "none",maxWidth: "85%"},
  FolderItemName : {userSelect: "none",maxWidth: "85%"}
}

export default FileManager;
