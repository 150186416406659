import React from 'react'

export default function WOAMaxRenderFlag() {
    return (
        <div className="">
              <div className="row" id="render-flags-box">
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_perform_color_check" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_perform_color_check" className="form-check-label">Perform Color Check</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_force_two_sided" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_force_two_sided" className="form-check-label">Force Two-Sided</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_render_hidden_objects" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_render_hidden_objects" className="form-check-label">Render Hidden Objects</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_use_atmospherics_effects" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_use_atmospherics_effects" className="form-check-label">Use Atmospherics Effects</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_use_super_black" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_use_super_black" className="form-check-label">Use Super Black</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_perform_displacement_mapping" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_perform_displacement_mapping" className="form-check-label">Perform Displacement Mapping</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_perform_render_effects" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_perform_render_effects" className="form-check-label">Perform Render Effects</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_use_advanced_lighting" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_use_advanced_lighting" className="form-check-label">Use Advanced Lighting</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_compute_advanced_lighting" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_compute_advanced_lighting" className="form-check-label">Compute Advanced Lighting</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_toggles_output_dithering_paletted" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_toggles_output_dithering_paletted" className="form-check-label">Toggles Output Dithering (paletted)</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_toggles_output_dithering_true_color" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_toggles_output_dithering_true_color" className="form-check-label">Toggles Output Dithering (true-color)</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-check">
                    <input id="rf_convert_area_lights_to_points_sources" type="checkbox" className="form-check-input" />
                    <label htmlFor="rf_convert_area_lights_to_points_sources" className="form-check-label">Convert Area Lights To Points Sources</label>
                  </div>
                </div>
              </div>
            </div>
          
    )
}