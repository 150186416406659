import React, { useState } from 'react'
import NavBar from './Widgets/NavBar'
import SideBar from './Widgets/SideBar'
import './header.scss'

function Header() {
    const [toggled, setToggled] = useState(false);
    
    const handleToggleSidebar = (value) => {
      setToggled(value);
    };
        return (
            <div className="d-print-none">

                {/**
                    SideBar App Navigation
                    * Contains 
                    - List Links
                    - 
                */}
                <SideBar toggled={toggled} handleToggleSidebar={handleToggleSidebar}/>
             
                <div className="header">
                    <div className="header-container">

                        {/**
                            Header Navigation Bar 
                        */}
                        <NavBar
                        toggled={toggled}
                        handleToggleSidebar={handleToggleSidebar}
                        />
                        
                    </div>
                </div>
            </div>
        )
    }
export default Header;