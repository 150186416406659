
import React , {useState}  from 'react';
import './render-dashboard.scss'
import DashboardJobButton from "../../components/RenderDashboard/JobButton" 
import DashboardJobName from '../../components/RenderDashboard/JobName'
import JobCollapse from '../../components/JobCollapse/JobCollapse'
import QuickTips from '../../components/QuickTips/QuickTips'
import ListSceneAnalysis from '../ListSceneAnalysis/ListSceneAnalysis'
import DashboardStatus from '../../components/RenderDashboard/Status/Status'


export default function RenderDashboard()  {

  function RenderDashboardList(){
    const [downIcon, setDownIcon] = useState("");
    function toggleDownIcon(){
      downIcon !== "" ?setDownIcon(""):setDownIcon("down")
    }
    return (
      <div className="rounded bg-dark my-2 p-2">
        <div className="row">
            <div className="col-lg-7 my-auto">
              <div className="d-flex">
                <button className="btn text-light my-auto me-2 d-none d-lg-block" onClick={()=>toggleDownIcon()}>
                    <i className={downIcon+" fad fa-chevron-right animate-icon"} ></i>
                </button>
                <DashboardJobName jobID="1123123"  Name="test.max" Frame="0 - 899" Camera="VRayCam001" Priority="Bronze" RenderBy="Cpu" Submitted="2 min ago" Output="concai nit" />
              </div>
            </div>
            <div className="col-lg-3 my-auto">
                <DashboardStatus Status="Rendering..."  />
            </div>
            <div className="col-lg-2 my-auto">
                <button className="btn bg-info-bright btn-floating text-light d-inline-flex d-lg-none" onClick={()=>toggleDownIcon()}>
                    <i className={downIcon+" fad fa-chevron-right animate-icon"} ></i>
                </button>
                <DashboardJobButton />
            </div>
            {downIcon!==""?<div className="col-lg-12 fade-in">
            <JobCollapse />
            </div>: ""}
        </div>
      </div>
      )
    }


      return (
        <div >
          <div className="d-lg-flex d-block mb-5">
            <div className="h4 text-light my-auto">Render Dashboard</div>
            <div className="ms-auto">
              <QuickTips />
            </div>
          </div>
         

          <ul className="nav nav-pills" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home"
                role="tab" aria-controls="pills-home" aria-selected="true">
                <h5 className="m-0">ALL JOB <span className="bg-danger p-1 rounded">20</span></h5>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile"
                role="tab" aria-controls="pills-profile" aria-selected="false">
                <h5 className="m-0">ANALYZE COMPLETED <span className="bg-danger p-1 rounded">20</span></h5>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-contact"
                role="tab" aria-controls="pills-contact" aria-selected="false">
                <h5 className="m-0">RENDER COMPLETED <span className="bg-danger p-1 rounded">20</span></h5>
              </a>
            </li>
            <div className="p-0 col-lg-2 ms-auto">
              <select className="form-control">
                <option selected>Sort by modified time</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                aria-labelledby="pills-home-tab"><RenderDashboardList /> <ListSceneAnalysis />
            </div>
            <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                aria-labelledby="pills-profile-tab"> 
                <ListSceneAnalysis />
              </div>
            <div className="tab-pane fade" id="pills-contact" role="tabpanel"
                aria-labelledby="pills-contact-tab">            
                <RenderDashboardList />
            </div>
          </div>
      
        </div>
      )
    }