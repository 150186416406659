import React from 'react'

export default function WOACamera() {
    return (
        <div id="camera_form_group" className="form-group">
            <label id="camera_label" className="form-label semibold">Camera</label>
            <div className="form-control-wrapper" id="camera_form_group_container_1">
            <input id="camera" type="text" className="form-control" placeholder="Leave blank to select active camera in your scene"  />
            </div>
        </div>
    )
}