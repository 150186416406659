import axios from 'axios'
import {decode} from 'jsonwebtoken'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import swal from 'sweetalert'
import env from '../../../configs/env'
import Logger from '../../../utils/debug'

function Account() {

  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [repeatNewPassword, setRepeatNewPassword] = useState("")

  const token = localStorage.getItem("token") 

  const history = useHistory()
  /**
   *  Change password submit handle 
   * 
   */
  function submitChangePassword(e) {

    /**
     * Stop default event handle
     * 
     */
    e.preventDefault()


    /**
     * Validate form 
     * 
     */
    if( newPassword !== repeatNewPassword ){
      /**
       * new pasword doesn't match
       * 
       */
      Logger("NEW_PASSWORD:NOTMATCH:FAIL", "New password and repeat doesn't match!!")

      /**
       * TODO : Handle Change password alert here 
       * 
       */
      return alert("New password not match!")
    }

    /**
     *  Go Go API
     *  
     * 
     */
    axios.put(
      `${env.APP_API}/user/me/change-password`,
      {
        current_password : currentPassword,
        new_password : newPassword
      },
      {
        headers : {
          Authorization : `Bearer ${token}`
        }
      })
      .then( res => {
        /**
         * Debug
         * 
         */
        Logger("CHANGE_PASSWORD:RESPONSE:OK", res.data)
        
        /**
         * If success
         */
        if(res.data.status == "success"){
          /**
           * Debug
           * 
           * 
           */
          Logger("CHANGED_PASSWORD:RESPONSE:SUCCESS", "Your password has been updated! ")

          /**
           * Settimeout loggout
           */
          Logger("_LOGOUT:WARN", "Your session has been expired, logout in 5 seconds")

          setTimeout(() => {

            /**
             * Clear all localStorage
             */
            Logger("LOCALSTORAGE:CLEAR:WARN", "Clearing localStorage")
            localStorage.clear()

            /**
             * Redirect to login page 
             * 
             */
            history.push("/login")
            
          }, 5 * 1000)

          return swal({
            title : "Success",
            text: "Your session has been expire, you will be logout after 5 second"
          })
        }
      })

    
  }


  return (
    <div className="tab-pane fade" id="v-pills-account" role="tabpanel" aria-labelledby="v-pills-account-tab">
      <div className="mb-4">
        <h4>Security</h4>
      </div>
    <div className="border-bottom mb-4"></div>
    <div className="row">
      <div className="col-md-6">
        <form onSubmit={ e => submitChangePassword(e) }>
          <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label>Email (Can't change email )</label>
                    <input type="text" disabled={true} className="form-control" defaultValue={ decode(localStorage.getItem("token")).email } />
                </div>
                <div className="form-group">
                    <label>New Password</label>
                    <input type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} id="input-password" className="form-control" name="password" placeholder="Between 6-100 characters (Optional)" />
                </div>
                <div className="form-group">
                    <label>New Password Repeat</label>
                    <input type="password" value={repeatNewPassword} onChange={e => setRepeatNewPassword(e.target.value)} id="input-passwordc" className="form-control" name="passwordc" placeholder="Required only if selecting a new password" />
                </div>
                <hr />
                <div className="form-group">
                    <label>Current Password</label>
                    <input type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} className="form-control" />
                </div>
                
                <div className="text-muted font-italic">
                * You must confirm your current password to make changes
                </div>
            </div>
          </div>
          <button className="btn btn-primary">Save Changes</button>
        </form>
      </div>
    </div>
  </div>
  )
}
export default Account ;