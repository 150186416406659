import React, { useState } from 'react'
import {MDBBtn} from "mdbreact";
import { Link } from 'react-router-dom';
import env from '../../configs/env';
import axios from 'axios';
import Logger from '../../utils/debug';
import { useEffect } from 'react';

export default function ResendEmail({}) {

    const [isSent, setIsSent] = useState(false)
    const [registedEmail, setRegistedEmail] = useState(null)
    const [isReachedSentCount, setIsReachedSentCount] = useState(false);

    useEffect(() => {

        setInterval(() => {
            if(localStorage.getItem("verified")){
                window.close() 
            }
        }, 1000);

        /**
         * Remove resent mail at if time has been expired 
         * 
         */
        if(! (localStorage.getItem("resent_mail_at") && ( localStorage.getItem("resent_mail_at") - Date.now()  > 0 ))) {
            localStorage.removeItem("resent_mail_at")
        }

        /**
         * Check is user sent more one confirmation email per 5 minutes
         * 
         */
        if(localStorage.getItem("resent_mail_at") && ( localStorage.getItem("resent_mail_at") - Date.now()  > 0 )){
            setIsReachedSentCount(true)
        }
    }, [] )

    function submitForm(e){
        e.preventDefault()

        if(registedEmail && !localStorage.getItem("resent_mail_at")){

            /**
             * 5 Minutes
             * 
             */
            const resend_limit =  5 * 60 * 1000

            /**
             * Disable resend button for {resend_limit} minutes
             * 
             * 
             */

            /**
             * Set limit time to localStorage
             * 
             */
            localStorage.setItem("resent_mail_at", resend_limit + Date.now())
            localStorage.setItem("registed_email", registedEmail)
            

            /**
             * Send request to API.
             * 
             * 
             */
            axios.post(`${env.APP_API}/user/activate/resend`,{email : registedEmail })
            .then( response => {
                Logger("RESEND:RESPONSE:OK" , response.data)
                setIsSent(true)

            })
            .catch(response => {
                Logger("RESEND:REPONSE:FAIL" , "Oops!! API Return a failure !")
            })

        }
    }

    if(isReachedSentCount){
        return (
            <div className="h-100">
                <div className="bg-login"></div>
                <div className="logoLogin">
                <a href="https://superrendersfarm.com/" ><img src="https://statics.superrendersfarm.com/app/assets/media/image/logo-001.png" alt="Homepage" /></a>
                </div>
                <div className="h-100 align-items-center d-flex justify-content-center fade-in">
                    <div className="login bg-dark rounded p-3 col-xl-3 col-lg-5 col-sm-6 shadow" >
                    <div className="card-body">
                        <div className="text-center h4">
                            Oops ! please wait ...
                        </div>
                        <div className="text-center text-muted">
                            We limit each user to only one confirmation email every 5 minutes.
                        </div>
                    </div>
                    <div className="mt-2 p-3">
                        <Link to="/login">Back to login page</Link>
                    </div>
                    </div>
                </div>
            </div>)
    }
    if(isSent) {
        return (
            <div className="h-100">
                <div className="bg-login"></div>
                <div className="logoLogin">
                <a href="https://superrendersfarm.com/" ><img src="https://statics.superrendersfarm.com/app/assets/media/image/logo-001.png" alt="Homepage" /></a>
                </div>
                <div className="h-100 align-items-center d-flex justify-content-center fade-in">
                    <div className="login bg-dark rounded p-3 col-xl-3 col-lg-5 col-sm-6 shadow" >
                    <div className="card-body">
                        <div className="text-center h4">
                            Email has been sent !
                        </div>
                        <div className="text-center text-muted">
                            We have sent you an account confirmation email, please check your mailbox, it may be in the spam folder
                        </div>
                    </div>
                    <div className="mt-2 p-3">
                        <Link to="/login">Back to login page</Link>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="h-100">
            <div className="bg-login"></div>
            <div className="logoLogin">
            <a href="https://superrendersfarm.com/" ><img src="https://statics.superrendersfarm.com/app/assets/media/image/logo-001.png" alt="Homepage" /></a>
            </div>
                <div className="h-100 align-items-center d-flex justify-content-center fade-in">
                    <div className="login bg-dark rounded p-3 col-xl-3 col-lg-5 col-sm-6 shadow" >
                    <div className="card-body">
                        <form onSubmit={ (e) => {submitForm(e)} }>
                            <div className="text-center h4">
                            Resend Confirmation Email
                            </div>
                            <div className="text-center text-muted">
                            Please enter the email address you used to sign up. A link with instructions to verify will be emailed to you.
                            </div>
                            <input type="email" required="true" value={registedEmail} onChange={ e => setRegistedEmail(e.target.value) } className="form-control rounded my-3" placeholder="Your email" />
                            <MDBBtn type="submit" color="success" className="btn-block text-dark" >Resend verify link</MDBBtn>
                        </form>
                    </div>
                    </div>
                </div>
        </div>
    )
}