import React  from 'react'
import Footer from '../components/Footer/Footer'
import Modal from '../modals/Modal'
import Header from '../components/Header/Header'


const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  
  return (
        <div className="layout-wrapper">
          <div className="content-wrapper">
            <div className="content-body">
              <div className="content fade-in">
              {children}
              <Modal />
              <Footer />
              </div>
            </div>
          </div>
          
        </div>
    )
  };

export default DefaultLayout