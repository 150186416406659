import React, { useState } from 'react'
import RenderSettings from '../RenderSettings'

export default function MaxRenderFlags({}) {

    return (
        <div>
        
            <div className="row" id="render-flags-box">
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_perform_color_check" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_perform_color_check" className="custom-control-label semibold">Perform Color Check</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_force_two_sided" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_force_two_sided" className="custom-control-label semibold">Force Two-Sided</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_render_hidden_objects" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_render_hidden_objects" className="custom-control-label semibold">Render Hidden Objects</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_use_atmospherics_effects" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_use_atmospherics_effects" className="custom-control-label semibold">Use Atmospherics Effects</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_use_super_black" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_use_super_black" className="custom-control-label semibold">Use Super Black</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_perform_displacement_mapping" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_perform_displacement_mapping" className="custom-control-label semibold">Perform Displacement Mapping</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_perform_render_effects" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_perform_render_effects" className="custom-control-label semibold">Perform Render Effects</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_use_advanced_lighting" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_use_advanced_lighting" className="custom-control-label semibold">Use Advanced Lighting</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_compute_advanced_lighting" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_compute_advanced_lighting" className="custom-control-label semibold">Compute Advanced Lighting</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_toggles_output_dithering_paletted" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_toggles_output_dithering_paletted" className="custom-control-label semibold">Toggles Output Dithering (paletted)</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_toggles_output_dithering_true_color" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_toggles_output_dithering_true_color" className="custom-control-label semibold">Toggles Output Dithering (true-color)</label>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="custom-control custom-checkbox custom-checkbox-success">
                    <input id="rf_convert_area_lights_to_points_sources" type="checkbox" className="custom-control-input" defaultValue={1} />
                    <label htmlFor="rf_convert_area_lights_to_points_sources" className="custom-control-label semibold">Convert Area Lights To Points Sources</label>
                    </div>
                </div>
            </div>

           <RenderSettings />

        </div>
    )}