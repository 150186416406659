import { MDBBtn } from 'mdbreact';
import React from 'react'

class CreateTicket extends React.Component {
    render() {
      return (
        <div >
          <div className="h4 text-light">Open a Support Ticket</div>
            <div className="card col-lg-6 mx-auto p-0">
              <div className="card-body bg-dark">
                <h4 className="text-white text-center my-5">
                  The support team is here to help. Please send us a message to get detailed. 
                  <h6 className="text-muted mt-2">(Expect a reply between 8am - 6:30pm PST Monday through Friday.)</h6>
                </h4>
                <div className="form-group">
                  <div className="form-floating">
                    <input type="text" className="form-control" name="ticket_subject" id="ticket_subject" placeholder="Describe your subject *" />
                    <label for="ticket_subject">Title Ticket</label>
                  </div>
                </div>
              
                <div className="form-group">
                  <div className="form-floating">
                    <textarea className="form-control" placeholder="Describe your question, comment, or issue *" id="ticket_description" style={{height:300}}></textarea>
                    <label for="ticket_description">Content Ticket</label>
                  </div>
                </div>
                
                <div className="form-group">
                  <div className="form-floating">
                    <input type="email" className="form-control" name="user_email" id="user_email" placeholder="Your Email *"  />
                    <label for="user_email">Email</label>
                  </div>
                </div>

                <div className="text-center">
                  <div id="message_post" />
                  <MDBBtn color="primary" type="submit" name="submitf" id="btn_submitf" >CREATE TICKET</MDBBtn>
                </div>
              </div>
          </div>
        </div>

  )}
}
export default CreateTicket ;