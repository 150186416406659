import React from 'react'

export default function WOATo() {
    return (
        <div className="form-group">
            <label id="end_frame_label" className="form-label semibold">To*</label>
            <div className="form-control-wrapper">
            <input id="end_frame" type="number" className="form-control srf_require_field" placeholder="To"  />
            <small className="text-muted" />
            </div>
        </div>
    )
}