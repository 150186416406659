import React from 'react'
import { Link } from 'react-router-dom';

export default function DashboardJobButton() {
    return (
        <div className="btn-group float-end">
            <Link to="/job-detail/11212" className="btn bg-info-bright"><i className="fad fa-info me-2" />VIEW DETAIL</Link>
            <button type="button" className="btn bg-info-bright text-white dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="sr-only">Toggle Dropdown</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
                <a className="dropdown-item" href="#"><i className="fad fa-stop-circle me-2" />Stop Render</a>
                <a className="dropdown-item" href="#"><i className="fal fa-sync-alt me-2" />Sync output</a>
                <a className="dropdown-item" href="#"><i className="fad fa-trash me-2" />Delete</a>
                <div className="dropdown-divider" />
                <a className="dropdown-item" href="#"><i className="fad fa-question-circle me-2" />Support ticket</a>
            </div>
        </div>
    )
}