import React from 'react';

/**
 * Tippy content 
 * 
 * @param {React.Component} content 
 * 
 */
export default function FileHintTippy({item}) {
    return (
      <div className="p-2 text-break">
        <h5>{item.name}</h5>
        <hr className="border-white" />
        <p className="m-0">File type: {item.fileExtension}</p>
        <p className="m-0">
          File size: {(parseInt(item.size) / 1024).toFixed(2) + "MB"}
        </p>
        <p className="m-0">Modifided: {item.modified}</p>
      </div>)
  }