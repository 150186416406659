import React from 'react'
import WOARenderPriority from '../ParamSettings/RenderPriority'
import WOAMaxAnimation from './Animation/Animation'
import WOAMaxEngineVersion from './EngineVersion'
import WOAMaxGamma from './Gamma'
import WOAMaxRenderFlag from './RenderFlag'
import WOAMaxSoftwareVersion from './SoftwareVersion'
import WOAMaxStillImage from './StillImage/StillImage'

export default function WOAMax () {
  return(
    <div>
      <div className="row" id="renderer_environment_container" >
          <div className="col-lg-6">
            <WOAMaxEngineVersion/>
          </div>
          <div className="col-lg-6">
            <WOAMaxSoftwareVersion />         
          </div>
      </div>

      <div className="nav nav-pills mb-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">
        <a className="nav-item nav-link active" id="v-pills-render-max" data-bs-toggle="pill" href="#render_type_animation" role="tab" aria-controls="render_type_animation" aria-selected="true">Animation</a>
        <a className="nav-item nav-link" id="v-pills-render-maya" data-bs-toggle="pill" href="#render_type_singlestill" role="tab" aria-controls="render_type_singlestill" aria-selected="false">Still Image</a>
      </div>

      <div className="tab-content card card-body mb-2" id="v-pills-tabContent">
        <div className="tab-pane fade show active" id="render_type_animation" role="tabpanel" aria-labelledby="render_type_animation-tab">
          <WOAMaxAnimation />
        </div>
        <div className="tab-pane fade" id="render_type_singlestill" role="tabpanel" aria-labelledby="render_type_singlestill-tab">
          <WOAMaxStillImage />
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-10">
              <WOAMaxRenderFlag />
            </div>

            <div className="col-lg-2">
            <WOAMaxGamma />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10">
          <WOARenderPriority />
        </div>
        <div className="col-lg-2 text-right mt-auto">
          <button className="btn btn-success btn-block text-dark form-group">
          <i className="fad fa-star-of-david me-2"></i>Start Render Job
          </button>
        </div>
      </div>
     
    </div>
      

  )
}