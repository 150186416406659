import React from 'react'
import { MDBTooltip } from 'mdbreact'
import { Link } from 'react-router-dom'

function DashboardJobName({jobID,Name,Frame,Camera,Priority,Output,RenderBy,Submitted }) {
  return ( 
    <div>
      <div className="job-name text-white me-2 text-truncate">
        <Link to={"/job-detail/"+jobID}>ID #{jobID} </Link>
        <MDBTooltip domElement tag="span" placement="top">
          <span>- Scene: {Name}</span>
          <span>{Name}</span>
        </MDBTooltip>
      </div>
      <div className="d-lg-inline-flex d-block">
        <span className="text-white me-2 mt-1">Output: 
          <MDBTooltip domElement tag="span" placement="top">
            <Link to={Output} className="text-success text-wrap"> <i className="fab fa-google-drive"/>Google Drive</Link>
            <span>{Output}</span>
          </MDBTooltip>
        </span>  
        <div>
          <div className="badge bg-success-bright me-2 mt-1">Priority: {Priority}</div>
          <div className="badge bg-success-bright me-2 mt-1">Frames: {Frame}</div>
          <div className="badge bg-success-bright me-2 mt-1">Camera: {Camera}</div>
          <div className="badge bg-success-bright me-2 mt-1">Render by: {RenderBy}</div>
          <div className="badge bg-success-bright me-2 mt-1">Submitted: {Submitted}</div>
        </div>
      </div>
      
    </div>
  )
}
export default DashboardJobName ;
