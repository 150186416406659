import React from 'react';

/**
 * Return icon by file extension
 * 
 * @param {React.Component} {fileExtension}
 */
 export default function FileExtensionIcon({fileExtension}){

    if (fileExtension === "max") {
      return (
        <span className="avatar-title rounded-pill">
          <img src="https://statics.superrendersfarm.com/app/assets/media/image/max.png" alt="max" />
        </span>
      );
    }
    if (fileExtension === "c4d") {
      return (
        <span className="avatar-title rounded-pill">
          <img src="https://statics.superrendersfarm.com/app/assets/media/image/c4d.png" alt="c4d" />
        </span>
      );
    }
    if (fileExtension === "blend") {
      return (
        <span className="avatar-title rounded-pill">
          <img
            src="https://statics.superrendersfarm.com/app/assets/media/image/blender.png"
            alt="blender"
          />
        </span>
      );
    }
    if (fileExtension === "ma") {
      return (
        <span className="avatar-title rounded-pill">
          <img
            src="https://statics.superrendersfarm.com/app/assets/media/image/maya.png"
            alt="Maya Extension"
          />
        </span>
      );
    }
    if (fileExtension === "mb") {
      return (
        <span className="avatar-title rounded-pill">
          <img
            src="https://statics.superrendersfarm.com/app/assets/media/image/maya.png"
            alt="Maya Extension"
          />
        </span>
      );
    }
    if (fileExtension === "hip") {
      return (
        <span className="avatar-title rounded-pill">
          <img
            src="https://statics.superrendersfarm.com/app/assets/media/image/houdini.png"
            alt="Houdini Extension"
          />
        </span>
      );
    }
    if (fileExtension === "hipnc") {
      return (
        <span className="avatar-title rounded-pill">
          <img
            src="https://statics.superrendersfarm.com/app/assets/media/image/houdini.png"
            alt="Houdini Extension"
          />
        </span>
      );
    }
    if (fileExtension === "hiplc") {
      return (
        <span className="avatar-title rounded-pill">
          <img
            src="https://statics.superrendersfarm.com/app/assets/media/image/houdini.png"
            alt="Houdini Extension"
          />
        </span>
      );
    }
    if (fileExtension === "nk") {
      return (
        <span className="avatar-title rounded-pill">
          <img
            src="https://statics.superrendersfarm.com/app/assets/media/image/nuke.png"
            alt="Nuke Extension"
          />
        </span>
      );
    }
    if (fileExtension === "aep") {
      return (
        <span className="avatar-title rounded-pill">
          <img
            src="https://statics.superrendersfarm.com/app/assets/media/image/ae.png"
            alt="Adobe After Effect Extension"
          />
        </span>
      );
    } else
      return (
        <span className="avatar-title rounded-pill">
          <img
            src="https://statics.superrendersfarm.com/app/assets/media/image/file.png"
            alt="Unknown File Extension"
          />
        </span>
      );
  }