import React from 'react'

export default function WOAAEEngineVersion () {
    return(
        <div className="form-group">
            <label htmlFor="render_engine_version" className="form-control-label semibold">Render Engine Version*</label>
            <select id="render_engine_version" className="form-control srf_require_field">
            <option value="classic_3d" >Classic 3D</option>
            
            </select>
        </div>
    )
}