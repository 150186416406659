import React, { useState } from 'react'
import { connect } from 'react-redux';
import Logger from '../../../utils/debug';
import Translate from '../../Translate'


function SpacesAlert (prop){

  Logger("ZIP:WARN" , "is" , prop.zip_found )

  return (<div>
    {
      prop.zip_found ?
      (<div className="alert alert-danger alert-dismissible alert-with-border fade-in" role="alert">
          <i className="fad fa-exclamation me-2 text-danger" />
          <Translate text="Zip file not supported! it's recommended to extract it and reupload to your shared folder" />
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="btn-close">
            <i className="fad fa-times-circle" />
          </button>
      </div> ):
      <div></div>
    }
  </div>)

}

/**
 * Oh look at this line :> very hard to read.
 * Dont worry, this line map redux store to prop of component, and remember the state of store to re-render once time.
 * 
 * 
 */
export default connect(state => ({ zip_found : state.SpaceStates.zip_found }))(React.memo(SpacesAlert)) ;