import React  from 'react'

export default function VrayFrameBuffer({}) {

    return (
        <div>
            <div className="card">
                <div className="card-header">Vray Frame Buffer</div>
                <div className="card-body">
                    <div className="custom-control custom-checkbox custom-control-inline custom-checkbox-success">
                        <input type="checkbox" id="checkbox_buildIn_frame_buffer" name="checkbox_buildIn_frame_buffer" className="custom-control-input" />
                        <label className="custom-control-label" for="checkbox_buildIn_frame_buffer">Enable build-in frame buffer</label>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-4 my-auto">
                                    <div className="custom-control custom-checkbox custom-control-inline custom-checkbox-success">
                                        <input type="checkbox" id="checkbox_RawFileName" name="checkbox_RawFileName" className="custom-control-input" />
                                        <label className="custom-control-label" for="checkbox_RawFileName">V-ray raw image file</label>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text">V-ray raw file name</label>
                                        </div>
                                        <input type="text" className="form-control" id="RawFileName" placeholder="RawFileName.Ext" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-4 my-auto">
                                    <div className="custom-control custom-checkbox custom-control-inline custom-checkbox-success">
                                        <input type="checkbox" id="checkbox_SplitFileName" name="checkbox_SplitFileName" className="custom-control-input" />
                                        <label className="custom-control-label" for="checkbox_SplitFileName">Separate render channels</label>
                                    </div>  
                                </div>
                                <div className="col-lg-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text">Split channels file name</label>
                                        </div>
                                        <input type="text" className="form-control" id="SplitFileName" placeholder="SplitFileName.Ext" />
                                    </div>
                                </div>
                            </div>
                         
                        </div>

                        
                        
                        </div>
                </div>
            </div>
        </div>
    

    )
}