import React from 'react'

function CommonIssuesList(){
    return (
        <ul className="text-white" style={{listStyleType: 'square'}}>
            <li style={{paddingBottom: '5px'}}><a rel="noopener noreferrer" href="https://support.superrendersfarm.com/hc/en-us/articles/360022991933-General-recommend-for-rendering-3ds-Max-project" target="_blank">- General recommend for rendering 3ds Max project</a></li>
            <li style={{paddingBottom: '5px'}}><a rel="noopener noreferrer" href="https://support.superrendersfarm.com/hc/en-us/articles/360022844433-How-to-Package-a-3ds-Max-file-to-Super-Renders-Farm-" target="_blank">- How to "Package" a 3ds Max file to Super Renders Farm?</a></li>
            <li style={{paddingBottom: '5px'}}><a rel="noopener noreferrer" href="https://support.superrendersfarm.com/hc/en-us/articles/360022725154-3Ds-MAX-Versions-Render-engine-and-plugins-supported" target="_blank">- 3Ds MAX Versions, Render engine and plugins supported</a></li>
            <li style={{paddingBottom: '5px'}}><a rel="noopener noreferrer" href="https://support.superrendersfarm.com/hc/en-us/articles/360022824674-Image-after-render-not-full-image" target="_blank">- Image after render not full image</a></li>
            <li style={{paddingBottom: '5px'}}><a rel="noopener noreferrer" href="https://support.superrendersfarm.com/hc/en-us/articles/360022823934-Iray-rendering-enabled" target="_blank">- Iray rendering enabled</a></li>
            <li style={{paddingBottom: '5px'}}><a rel="noopener noreferrer" href="https://support.superrendersfarm.com/hc/en-us/articles/360022823114-No-lights-in-scene" target="_blank">- No lights in scene</a></li>
            <li style={{paddingBottom: '5px'}}><a rel="noopener noreferrer" href="https://support.superrendersfarm.com/hc/en-us/articles/360022997333-Can-t-detect-your-render-camera" target="_blank">- Can't detect your render camera</a></li>
            <li style={{paddingBottom: '5px'}} target="_blank"><a href="https://superrendersfarm.com/supported-softwares">- Support 3D softwares &amp; plugin</a></li>
        </ul>
    )
}

export default CommonIssuesList