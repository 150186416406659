import React from 'react'
import './footer.scss'

class Footer extends React.Component {
    render() {
      return (
          <footer className="fixed-bottom">
              <button type="button" className="btn btn-light float-end mb-2 me-3">Have a Question? Let's talk !</button>
          </footer>
      )}
}
export default Footer ;