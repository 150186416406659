import {DefaultLayout} from '../layouts'
import Form from '../layouts/Form'
import React from 'react'
import GettingStarted from '../views/GettingStarted'
import RenderDashboard from '../views/RenderDashboard'
import Spaces from '../views/Spaces/Spaces'
import JobDetail from '../views/JobDetail/JobDetail'
import JobsHistory from '../views/JobsHistory/JobsHistory'
import Plugins from '../views/Plugins/Plugins'
import TransferMethod from '../views/TransferMethod/TransferMethod'
import BuyCredits from '../views/BuyCredits/BuyCredits'
import CreateTicket from '../views/CreateTicket/CreateTicket'
import SignUp from '../views/SignUp/SignUp'
import Settings from '../views/Settings/Settings'
import PaymentInvoices from '../views/PaymentInvoices/PaymentInvoices'
import CostCaculator from '../views/CostCaculator/CostCaculator'
import GoogleDrive from '../views/GoogleDrive/GoogleDrive'
import Dropbox from '../views/Dropbox/Dropbox'
import NewRenderJob from '../views/NewRenderJob/NewRenderJob'
import ForgetPass from '../views/ForgetPass/ForgetPass'
import { Redirect } from 'react-router'
import Login from '../views/SignIn/Login'
import VerifyAccount from '../views/VerifyAccount/VerifyAccount'
import ResendEmail from '../views/ResendEmail/ResendEmail'
import SceneAnalyze from '../views/SceneAnalyze/SceneAnalyze'


export default [
    {
        path: "/",
        exact: true,
        layout: DefaultLayout,
        middlewares : () => {
            return false 
        },
        component: () => <GettingStarted />
    },
    {
        path: "/render-dashboard",
        exact: true,
        layout: DefaultLayout,
        component: () => <RenderDashboard />
    },{
        path: "/spaces",
        layout: DefaultLayout,
        component: () => <Spaces />
    },
    {
        path: "/job-detail/:id",
        exact: true,
        layout: DefaultLayout,
        component: (props) => <JobDetail {...props} />
    },
    {
        path: "/jobs-history",
        exact: true,
        layout: DefaultLayout,
        component: () => <JobsHistory />
    },
    
    {
        path: "/plugins",
        exact: true,
        layout: DefaultLayout,
        component: () => <Plugins />
    },
    {
        path: "/transfer-method",
        exact: true,
        layout: DefaultLayout,
        component: () => <TransferMethod />
    },
    {
        path: "/buy-credits",
        exact: true,
        layout: DefaultLayout,
        component: () => <BuyCredits />
    },
    
    {
        path: "/cost-calculator",
        exact: true,
        layout: DefaultLayout,
        component: () => <CostCaculator />
    },
    {
        path: "/create-ticket",
        exact: true,
        layout: DefaultLayout,
        component: () => <CreateTicket />
    },
    {
        path: "/payment-invoices",
        exact: true,
        layout: DefaultLayout,
        component: () => <PaymentInvoices />
    },
    {
        path: "/settings",
        exact: true,
        layout: DefaultLayout,
        component: (props) => <Settings {...props}  />
    },
    {
        path: "/login",
        public : true ,
        onRefused : "",
        middlewares : () => {

            /**
             * Clear localStorage before go to Login page
             * 
             * 
             */
            localStorage.removeItem("token")

            return true 


        },
        exact: true,
        layout: Form,
        component: () => <Login />
    },
    {
        path: "/sign-up",
        exact: true,
        public : true ,
        onRefused : "",
        layout: Form,
        component: () => <SignUp />
    },
    {
        path: "/dropbox",
        layout: DefaultLayout,
        component: () => <Dropbox />
    },
    {
        path: "/google-drive",
        layout: DefaultLayout,
        component: () => <GoogleDrive />
    },
    {
        path: "/new-render-job/:path(.*)",
        exact: true,
        layout: DefaultLayout,
        component: (props) => <NewRenderJob {...props} />
    },
    {
        path: "/forgot-password",
        exact: true,
        onRefused : "",
        public : true ,
        layout: Form,
        component: () => <ForgetPass />
    },
    {
        path : "/forgot-password/:token",
        exact: true,
        onRefused : "",
        public : true ,
        layout: Form,
        component: () => <ForgetPass />

    },
    {
        path: "/resend-email",
        exact: true,
        onRefused : "",
        public : true ,
        layout: Form,
        component: () => <ResendEmail />
    },
    {
        path: "/verify-account",
        exact: true,
        onRefused : "",
        public : true ,
        layout: Form,
        component: () => <VerifyAccount />
    },
    {
        path : "/verify-account/:token",
        exact: true,
        onRefused : "",
        public : true ,
        layout: Form,
        component: () => <VerifyAccount />

    },
    /**
     * Logout URL Handler
     * 
     */
    {
        path : "/logout",
        exact : true ,
        layout : DefaultLayout,
        public : true ,
        middlewares : () => {

            /**
             * Clear localStorage before go to Login page
             * 
             * 
             */
            localStorage.clear()

            /**
             * Passed middleware
             */
            return true 

        },
        /**
         * Return to Login
         * 
         * @returns {React.Component}
         */
        component : () => { return <Redirect to="login" /> },
    },

    {
        path: "/scene-analyze/:id",
        exact: true,
        layout: DefaultLayout,
        component: (props) => <SceneAnalyze {...props} />
    },
]
