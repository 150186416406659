import React, { useState } from 'react'
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useEffect } from 'react';
import axios from 'axios';
import env from '../../configs/env';
import AuthorizationHeaders from '../../utils/Authorization';

/** Scene analyze form */
import SceneAnalyze3DMax from '../../scenes/maxVray';
import swal from 'sweetalert';
import FolderSkeleton from '../../components/Spaces/Filemanager/FolderSkeleton';



export default function SceneAnalyze({}) {

    /**
     * READ ME
     * 
     * Cuz every 3d application must be has a different scene analyze page.
     * so this page just get JSON data and require scene analyze page by 
     * JSON's 3D application.
     * 
     * Ps: Imagine this page just like a middleware
     * 
     */


    const [sceneAnalyzeJson, setSceneAnalyzeJson] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    var {id} = useParams()
    var history = useHistory()
    
    /** Run once time  */
    useEffect(() => {

        /** Get Scene analyze data from api  */
        axios.get(`${env.APP_API}/render-job/pre-create/analyzed-scene/${parseInt(id)}`, {
            
            headers : AuthorizationHeaders() // Bearer token

        }).then(res => {
            
            /** Append data  */
            setSceneAnalyzeJson(res.data.data)
            /** Set loading state to fail */
            setIsLoading(false)
        }).catch(err => {

            /** Scene id not found or api return error state */
            return swal({
                title : "Scene not found!",
                closeOnEsc : true,
                text : "Scene analyze id not found, please check back your scene id",
            }).then(val => {

                /** Redirect to list scene analyze  */
                history.push("/render-dashboard")
                
            })
        })

    }, [])

    /** If current page is loading data, show this component */
    if( isLoading ) return <FolderSkeleton />

    /** Get application name from json */
    let applicationName = sceneAnalyzeJson.data_render_job.scene_3dApp|| false 

    /** return application form */
    switch( applicationName ){

        /** 3ds Max */
        case "3dsmax" :
            return <SceneAnalyze3DMax scene={sceneAnalyzeJson} />

        /** Cinema 4D */
        case "c4d" : 
            return <SceneAnalyze3DMax scene={sceneAnalyzeJson} />

        /** Maya */
        case "maya" :
            return <SceneAnalyze3DMax scene={sceneAnalyzeJson} />
        
        /** Nuke */
        case "nuke" :
            return <SceneAnalyze3DMax scene={sceneAnalyzeJson} />
        
        /** Blendder */
        case "blend":
            return <SceneAnalyze3DMax scene={sceneAnalyzeJson} />

        /** After Effects @deprecated */
        case "after-effects":
            return <SceneAnalyze3DMax scene={sceneAnalyzeJson} />

        /** After Effects */
        case "aep" :
            return <SceneAnalyze3DMax scene={sceneAnalyzeJson} />

        /** Houdini */
        case "houdini":
            return <SceneAnalyze3DMax scene={sceneAnalyzeJson} />

        /** Not listed application */
        default :
            return <SceneAnalyze3DMax scene={sceneAnalyzeJson} />
    }







    
    



    
}