import React from 'react'

export default function WOABWorkingFolderPath () {
    return(
        <div className="form-group" id="working_folder_path_form_group">
            <label id="working_folder_path_label" className="form-label semibold">Working Folder Path*</label>
            <div className="form-control-wrapper form-control-icon-left"  style={{position:"relative"}}>
                <input id="working_folder_path" type="text" className="form-control" placeholder="Project Path or Scene Folder Path" style={{paddingLeft:53}} />
                <div className="text-muted" style={{left:15,width:38,height:37,lineHeight:"37px",textAlign:"center",position:"absolute",top:0}}><i className="fa fa-folder" ></i> N:</div>
            </div>
        </div>
    )
}
