import React from 'react'

export default function LightManagement({}) {

    return (
        <div className="accordion custom-accordion">
            <div className="accordion-row">
                <a href="#" className="accordion-header">
                <span>Light Management</span>
                <i className="accordion-status-icon close fa fa-chevron-up"></i>
                <i className="accordion-status-icon open fa fa-chevron-down"></i>
                </a>
                <div className="accordion-body">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>No</th>
                            <th>Light name</th>
                            <th>State</th>
                            <th>Action turn On/Off</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>ALL</td>
                                <td id="light-state-1">On</td>
                                <td>
                                    <div className="custom-control custom-switch custom-checkbox-success">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch3" checked />
                                        <label className="custom-control-label" for="customSwitch3"></label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="accordion-row">
                <a href="#" className="accordion-header">
                <span>Render Elements</span>
                <i className="accordion-status-icon close fa fa-chevron-up"></i>
                <i className="accordion-status-icon open fa fa-chevron-down"></i>
                </a>
                <div className="accordion-body">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                            <th>No</th>
                            <th>Element Name</th>
                            <th>Enabled</th>
                            <th>Element Filename</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            <td>VRaySelfIllumination</td>
                            <td> True </td>
                            <td>6_VRaySelfIllumination.tga</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )}