import React from 'react'
import { MDBRow, MDBCol, MDBBtn} from "mdbreact";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import env from '../../configs/env';
import Logger from '../../utils/debug';
import TermsAndConditions from '../../modals/TermsAndConditions/TermsAndConditions';

class SignUp extends React.Component {
    state = {
        name: "",
        email: "",
        password: "",
        spassword: "",
        redirect: null
      };
    constructor(props) {
      super(props);
    }
    
      submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";

        if(this.state.password == this.state.spassword ) {
          Logger("REGISTER:REQUEST:WARN", "Sending register ! ")
          axios.post( `${env.APP_API}/user/register`,{
            /**
             * 
             */
            "email": this.state.email,
            "name": this.state.name,
            "password": this.state.spassword
          })
          .then(res => {
            /**
             * 
             */
            if(res.data) {
              
              Logger("REGISTER:RESPONSE:OK", res.data , true)

              if(res.data.user.flag_verified == 0 ) {
                
                localStorage.setItem("registed_email", res.data.user.email )
                // this.props.history.push("/verify-account")
                this.setState({ redirect: "/verify-account" })

              }else {

                this.setState({ redirect: "/login" })
              }
            }
          })
          .catch( res => {
            /**
             * 
             */
            if(res.response)
              Logger('REGISTER:RESPONSE:FAIL', res.response.data)

            /**
             * 
             * 
             */
          })

        }

      };
    
      changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
      };
 
    render() {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />
      }
      
      return (
        <div className="h-100">
        <div className="bg-login"></div>
        <div className="logoLogin">
          <a href="https://superrendersfarm.com/" ><img src="https://statics.superrendersfarm.com/app/assets/media/image/logo-001.png" alt="Homepage" /></a>
        </div>

          <div className="h-100 align-items-center d-flex justify-content-center fade-in">
          
            <div className="login bg-dark rounded p-3 col-xl-4 col-lg-6 shadow" >
              <div className="card-body">
                <div className="text-center h4">
                    Create your Account
                </div>
                <div>
                
            </div>
            <form
            className="needs-validation"
            onSubmit={this.submitHandler}
            noValidate
          >
            <MDBRow className="mt-4">
                            
            <MDBCol md="12" className="mb-3">
            <label
              htmlFor="defaultFormRegisterConfirmEx3"
              className="grey-text"
            >
              Full Name
            </label>
            <input
              value={this.state.name}
              onChange={this.changeHandler}
              type="text"
              id="defaultFormRegisterConfirmEx3"
              className="form-control"
              name="name"
              placeholder="Your full name"
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </MDBCol>
              <MDBCol md="12" className="mb-3">
                <label
                  htmlFor="defaultFormRegisterConfirmEx3"
                  className="grey-text"
                >
                  Email
                </label>
                <input
                  value={this.state.email}
                  onChange={this.changeHandler}
                  type="email"
                  id="defaultFormRegisterConfirmEx3"
                  className="form-control"
                  name="email"
                  placeholder="Your Email address"
                />
                <small id="emailHelp" className="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </MDBCol>
            </MDBRow>

                  <MDBRow>
                    <MDBCol md="6" className="mb-3">
                      <label
                        htmlFor="defaultFormRegisterPasswordEx4"
                        className="grey-text"
                      >
                        Password
                      </label>
                      <input
                        value={this.state.password}
                        onChange={this.changeHandler}
                        type="password"
                        id="defaultFormRegisterPasswordEx4"
                        className="form-control"
                        name="password"
                        placeholder="Your Password"
                        required
                      />
                      <div className="invalid-feedback">
                        Please provide a valid Password.
                      </div>
                      <div className="valid-feedback">Looks good!</div>
                    </MDBCol>
                    <MDBCol md="6" className="mb-3">
                      <label
                        htmlFor="defaultFormRegisterPasswordEx4"
                        className="grey-text"
                      >
                      Confirm Password
                      </label>
                      <input
                        value={this.state.spassword}
                        onChange={this.changeHandler}
                        type="password"
                        id="defaultFormRegisterPasswordEx4"
                        className="form-control"
                        name="spassword"
                        placeholder="Confirm Password"
                        required
                      />
                      <div className="invalid-feedback">
                        Please provide a valid Password.
                      </div>
                      <div className="valid-feedback">Looks good!</div>
                    </MDBCol>
                  </MDBRow>
                  <div className="mb-3">
                    <div className="text-muted">By registering an account with Super Renders Farm, you accept the <span data-bs-toggle="modal" data-bs-target="#TermsAndConditions" className="text-success cursor-pointer">terms and conditions.</span>
                    </div>

                    <TermsAndConditions />
                  </div>
                  <MDBBtn color="success" type="submit" className="btn-block text-dark shadow">
                    Sign me up
                  </MDBBtn>
                </form> 
                  <div className="text-muted mt-4">Already have account? <Link to="/login" className="font-weight-bold">Login now !</Link></div>
               
             
              </div>
            </div>
        </div>
    </div>

      )}
}
export default SignUp ;