import React from 'react'

class Invoice extends React.Component {
    render() {
      return (
        <div className="tab-pane fade" id="v-pills-invoice" role="tabpanel" aria-labelledby="v-pills-invoice-tab">
          <div className="mb-4">
            <h4>Invoice Information</h4>
          </div>
          <div className="border-bottom mb-4"></div>
            <div className="row">
              <div className="col-md-6">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Name (e.g Company Name)</label>
                        <input type="text" className="form-control" />
                      </div>
                     
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Address</label>
                            <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>VAT Code</label>
                            <input type="text" className="form-control"  />
                        </div>
                    </div>
                  </div>
                  <button className="btn btn-primary">Save Changes</button>
                </form>
              </div>
            </div>
          </div>
        
         
      )}
}
export default Invoice ;