import React from 'react'

export default function WOARenderOutputFileNoExtension(){
    return (
        <div className="form-group">
            <label className="form-label semibold">Render Output File*</label>
            <div className="form-control-wrapper">
                <input id="renderoutput_file" type="text" className="form-control" placeholder="e.g: filename"  />
            </div>
        </div>
    )
}