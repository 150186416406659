import React, { useEffect, useState } from 'react'
import {MDBBtn} from "mdbreact";
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import env from '../../configs/env';
import Logger from '../../utils/debug';

function ForgetPass () {

  const [isSent, setIsSent] = useState(false)
  const [isCanEditPassword, setIsCanEditPassword] = useState(false)
  const [isDone, setIsDone] = useState(false)
  /**
   * Fields state
   * 
   */
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [userId, setUserId] = useState(null);
  
  const history = useHistory()
  
  // get token from url 
  const {token} = useParams()

  useEffect(() => {

    /**
     * If Router has token params
     * 
     */
    if( token && token != ""  ){
      axios.get(`${env.APP_API}/user/reset-password/${token}`)
      .then( res => {

        /**
         * Debug
         * 
         */
        Logger("RESET_PASS_VERIFY:RESPONSE:OK", res.data)


        /**
         * 
         * 
         */
        if(res.data.status == "success" ) {
          
          /**
           * Change form to resetable password
           * 
           */
          setIsCanEditPassword(true)

          /**
           * Set user_id 
           */
          setUserId(res.data.user_id)
        }

      })

    }

  }, [])

  /**
   * Submit form 
   * 
   * @param {Event} e DomEvent
   * 
   */
  function submitForm(e) {

    
    /**
     * Stop default event handle
     * 
     */
    e.preventDefault()

    /**
     * Verify
     * 
     */
    axios.post(`${env.APP_API}/user/forgot-password`,{email})
    .then( res => {
      /**
       * Debug Logger
       */
      Logger( "FORGOT_PASSWORD:RESPONSE:OK", res.data )
      if(res.data.status == "success") {
        setIsSent(true)
      }
    })

  }


  /**
   * On Reset Form Submit
   * 
   * @param {Document.Event} e 
   * 
   */
  function resetPasswordSubmit(e){
    /**
     * Stop default form submit event 
     * 
     */
    e.preventDefault()

    /**
     * Check password and confirm password.
     * if doesn't match, handle a event !!
     * 
     * TODO : @assign hieu : Handle a notification here 
     * 
     */
    if( password !== confirmPassword ){
      return alert("Hey!!!! Confirm password doesn't match! ")
    }

    /**
     * Send to api
     * 
     */
    axios.post(`${env.APP_API}/user/reset-password`, { user_id : userId.toString(), password })
    .then( res => {
      /**
       * On success 
       * 
       * Debug
       * 
       */
      Logger("RESET_PASSWORD:RESPONSE:OK", res.data)

      /**
       * Check is success
       * 
       */
      if(res.data.status == "success"){
        setIsDone(true)

        setTimeout(() => {
          history.push("/")
        }, 10*1000);
      }


    })

  }


  /**
   * If reset done, return this 
   * 
   */
  if(isDone ) {
    return (
      <div className="h-100">
        <div className="bg-login"></div>
        <div className="logoLogin">
          <a href="https://superrendersfarm.com/"><img src="https://statics.superrendersfarm.com/app/assets/media/image/logo-001.png" alt="Homepage" /></a>
        </div>
        <div className="h-100 align-items-center d-flex justify-content-center fade-in">
          <div className="login bg-dark rounded p-3 col-xl-3 col-lg-5 col-sm-6 shadow">
            <div className="card-body">
              <div className="text-center h4">
                Your password has been updated
              </div>

              <div className="text-center h4 my-3 fade-in text-success">
                <i className="fad fa-1x fa-check-circle"></i>
              </div>

              <div className="text-center text-muted">
                Your password has been updated, You will be redirected to the login in 10 seconds
              </div>
              <div className="mt-2 p-3">
                <Link to='/' color="success" className="btn btn-block btn-success text-dark">Go to login manualy</Link>
              </div>
            </div>
          </div>
        </div>
      </div>)

  }



  /**
   * Reset password form
   * 
   * 
   */
  if(isCanEditPassword ) {
    return (
      <div className="h-100">
        <div className="bg-login"></div>
        <div className="logoLogin">
          <a href="https://superrendersfarm.com/"><img src="https://statics.superrendersfarm.com/app/assets/media/image/logo-001.png" alt="Homepage" /></a>
        </div>
        <div className="h-100 align-items-center d-flex justify-content-center fade-in">
          <div className="login bg-dark rounded p-3 col-xl-3 col-lg-5 col-sm-6 shadow">
            <div className="card-body">
              <form onSubmit={ e => resetPasswordSubmit(e) }>
                <div className="text-center h4">
                  Reset your password 
                </div>

                <div className="text-center text-muted">
                  Please do not reload this page, you will not be able to change your password if the page is reloaded
                </div>

                <input type="password" value={password} onChange={e => setPassword(e.target.value)} name='password' required={true} className="form-control rounded my-3" placeholder="New password" />

                <input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} name='confirm_password' required={true} className="form-control rounded my-3" placeholder="Type it again" />

                <MDBBtn type="submit" color="success" className="btn-block text-dark">Reset password</MDBBtn>
                
                <div className="mt-2 p-3">
                    <Link to="/login">Back to login page</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>) 
  }

  /**
   * If Sent a mail to user
   * 
   */
  if(isSent) {
    return (
      <div className="h-100">
        <div className="bg-login"></div>
        <div className="logoLogin">
          <a href="https://superrendersfarm.com/"><img src="https://statics.superrendersfarm.com/app/assets/media/image/logo-001.png" alt="Homepage" /></a>
        </div>
        <div className="h-100 align-items-center d-flex justify-content-center fade-in">
          <div className="login bg-dark rounded p-3 col-xl-3 col-lg-5 col-sm-6 shadow">
            <div className="card-body">
              <form onSubmit={ e => submitForm(e) }>
                <div className="text-center h4">
                  Email has been sent ! 
                </div>

                <div className="text-center h4 my-3 fade-in text-success">
                  <i className="fad fa-1x fa-check-circle"></i>
                </div>

                <div className="text-center text-muted">
                We have sent you an account confirmation email, please check your mailbox, it may be in the spam folder
                </div>
                <div className="mt-2 p-3">
                    <Link to="/login">Back to login page</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>)
  }

  /**
   * Return a form. use type email to get reset session
   * 
   */
  return (
    <div className="h-100">
      <div className="bg-login"></div>
      <div className="logoLogin">
        <a href="https://superrendersfarm.com/"><img src="https://statics.superrendersfarm.com/app/assets/media/image/logo-001.png" alt="Homepage" /></a>
      </div>
      <div className="h-100 align-items-center d-flex justify-content-center fade-in">
        <div className="login bg-dark rounded p-3 col-xl-3 col-lg-5 col-sm-6 shadow">
          <div className="card-body">
            <form onSubmit={ e => submitForm(e) }>
              <div className="text-center h4">
                Forgotten Password
              </div>
              <div className="text-center text-muted">
                Please enter the email address you used to sign up. A link with instructions to reset your password
                will be emailed to you.
              </div>
              <input value={email} onChange={e => setEmail(e.target.value)} type="email" required={true} className="form-control rounded my-3" placeholder="Your email" />

              <MDBBtn type="submit" color="success" className="btn-block text-dark">Email password reset link</MDBBtn>
            </form>
          </div>
        </div>
      </div>
    </div>)
}
export default ForgetPass ;