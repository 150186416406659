import React from 'react'
import { Link } from 'react-router-dom';

class DownloadSyncApp extends React.Component {
    render() {
      return (
        <div className="modal fade" id="sync-app"  aria-labelledby="sync-appLabel" aria-hidden="true" >
        <div className="modal-dialog modal-lg modal-dialog-centered" >
            <div className="modal-content bg-dark">
            <div className="modal-body">
              <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="btn-close"></button>

              <div>
                <div className="text-light text-center">
                <div style={{ marginBottom: '10px'}}>
                    <div>Need to install plug-in for file transfer</div>
                    <div>If you have already installed the plug-in, please<a href="superrenderssync://" id="enable_plugin"> click here to enable</a></div>
                </div>
                <div style={{marginBottom: '15px'}}>
                    <a id="link_plugin_upload" href="https://srf-client-apps.s3.amazonaws.com/SuperRendersSync Setup 1.0.22.exe" download className="btn btn-inline btn-success text-dark">Download the latest version</a>
                </div>
                <div style={{marginBottom: '10px', marginTop: '10px'}}>Please contact supportcenter@superrendersfarm.com if you find any problem.</div>
                <b><i className="fa fa-warning"  style={{color: '#f29824'}} /> Note: if the Windows Security blocks the software, please allow it </b><a href="https://support.superrendersfarm.com/hc/en-us/articles/360046544473" target="_blank" rel="noopener noreferrer">learn more...</a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
      )}
}
export default DownloadSyncApp ;