import React, { useEffect } from "react"
import Translate from "../components/Translate"
import { NavLink, Redirect } from "react-router-dom";
import axios from "axios";
import env from "../configs/env";
import { connect } from "react-redux";
import Logger from "../utils/debug";
import Stores from "../redux/store";


const GettingStarted =  props => {

  return (
      <div >
          <div className="h2 text-light">
            <Translate text="GENERAL QUICK START" />
          </div>
          <div className="h5 mb-2">
              <Translate text="How to render on our render farm" />
          </div>
          <div className="card">
              <div className="card-body bg-dark">
                  <div className="d-flex">
                      <div className="me-3 text-light h1">
                          <i className="fad fa-folder-upload"></i>
                      </div>
                      <div>
                          <div>
                              <Translate text="STEP 1. UPLOAD" />
                          </div>
                          <p>
                            <Translate text="Remember to Package Your Project before uploading to Super Renders Farm space." />
                            <a target="blank" href="https://support.superrendersfarm.com/hc/en-us/articles/360023844234-Package-your-projects" className="text-success">(see how)</a>
                          </p> 
                          <NavLink to="/spaces" className="btn btn-success text-dark btn-sm"><Translate text="Upload now" /></NavLink>
                      </div>
                  </div>

                  <div className="d-flex mt-4">
                      <div className="me-3 text-light h1">
                          <i className="fad fa-rocket-launch"></i>
                      </div>
                      <div>
                          <div>
                              <Translate text="STEP 2. RENDERING" />
                          </div>
                          <Translate text="Click on your scene file - make a render job - start rendering." />                            
                          <div>
                          <span className="font-weight-bold"><Translate text="Quick tips" />:</span>
                          <a href="#" className="btn btn-dark btn-sm m-1">
                            <img className="me-2" src="../../assets/media/image/max.png" alt="max" width="15px" height="15px" /> 3DS Max
                          </a>
                          <a href="#" className="btn btn-dark btn-sm m-1">
                            <img className="me-2" src="../../assets/media/image/c4d.png" alt="c4d" width="15px" height="15px" /> Cinema 4D
                          </a>
                          <a href="#" className="btn btn-dark btn-sm m-1">
                            <img className="me-2" src="../../assets/media/image/blender.png" alt="blender" width="15px" height="15px" /> Blender
                          </a>
                          <a href="#" className="btn btn-dark btn-sm m-1">
                            <img className="me-2" src="../../assets/media/image/maya.png" alt="maya"  width="15px" height="15px" /> Maya
                          </a>
                          <a href="#" className="btn btn-dark btn-sm m-1">
                            <i className="fab fa-youtube me-2 text-danger" /> Videos
                          </a>
                        </div>
                      </div>
                  </div>

                  <div className="d-flex mt-4">
                      <div className="me-3 text-light h1">
                          <i className="fad fa-download"></i>
                      </div>
                      <div>
                          <div>
                          <Translate text="STEP 3. DOWNLOAD" />
                          </div>
                          <Translate text="Connect to your GoogleDrive or Dropbox to get your render output files." />
                          <div>
                          <span className="font-weight-bold"><Translate text="Method"/>:</span>
                          <a href="#" className="btn btn-dark btn-sm m-1">
                            <i className="fab fa-google-drive me-2"></i> Google Drive
                          </a>
                          <a href="#" className="btn btn-dark btn-sm m-1">
                          <i className="fab fa-dropbox me-2"></i> Dropbox
                          </a>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
};


/**
 * Mapping Redux State to React Component Props
 * 
 */
const ReduxProps = ({UserStates}) => ({
  uwf : UserStates.uwf
})

export default connect( ReduxProps )(GettingStarted)