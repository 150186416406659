import React from 'react'

export default function ArnoldOutputPNG({}) {

    return (
        <div className="PNG_config card card-body">
        <legend>PNG Configuration:</legend>
        <div className="form-group row">
            <div className="col-lg-2">
                <label>Color:</label>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="PNG_optimizedPalette" name="PNG_color" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="PNG_optimizedPalette">Optimized palette</label>
                </div>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="PNG_RGB_24bit" name="PNG_color" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="PNG_RGB_24bit">RGB 24 bit (16.7 Million)</label>
                </div>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="PNG_RGB_48bit" name="PNG_color" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="PNG_RGB_48bit">RGB 48 bit (281 Million)</label>
                </div>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="PNG_grayscale_8bit" name="PNG_color" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="PNG_grayscale_8bit">Grayscale 8 bit (256)</label>
                </div>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="PNG_grayscale_16bit" name="PNG_color" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="PNG_grayscale_16bit">Grayscale 16 bit (65,536)</label>
                </div>
            </div>
        </div>
        <div className="form-group row">

            <label className="col-lg-2">Properties:</label>

            <div className="col-lg-2">
                <div className="form-group">
                    <div className="custom-control custom-checkbox custom-checkbox-success custom-checkbox-success">
                        <input type="checkbox" className="custom-control-input" id="png_alphaChannel" name="png_alphaChannel" />
                        <label className="custom-control-label" htmlFor="png_alphaChannel">Alpha channel</label>
                    </div>
                </div>
            </div>
            <div className="col-lg-2">
                <div className="form-group">
                    <div className="custom-control custom-checkbox custom-checkbox-success custom-checkbox-success">
                        <input type="checkbox" className="custom-control-input" id="png_interlaced" name="png_interlaced" />
                        <label className="custom-control-label" htmlFor="png_interlaced">Interlaced</label>
                    </div>
                </div>
            </div> 
        </div>
    </div>  
    )
}