import axios from 'axios';
import { MD5 } from 'crypto-js';
import React, { useEffect } from 'react';
import { useState } from 'react';
import env from '../../configs/env';
/**
 * Check scene analyze status
 * @returns {React.Component}
 */

function SceneAnalyzeStatus( {path , onSceneStatus } ){

    {/** Path must be have / ahead */}
    let realpath = `/${path}` 

    const [isLoading, setIsLoading] = useState(true)
    const [isAnalyzedSuccess, setIsAnalyzedSuccess] = useState(false)
    const [isNotAnalyzed, setIsNotAnalyzed] = useState(false)

    useEffect(() => {
    
        /** Get Analyze scene status */
        axios.get(`${env.APP_API}/analyze-job/get/scene-analyze-status/${MD5(realpath)}`,
        /** Authorization Bearer */
        { headers : { Authorization : `Bearer ${localStorage.getItem("token")}` }})
        /** Response */
        .then( res => {


            /** Call a callback to set state of scene to analyzed complete */
            if(onSceneStatus){
                onSceneStatus(res.data)
            }


            /**
             * Scene Analyzed and return ok state :3
             */
            if(res.data.scene_status == "AnalyzeCompleted")
            {
                setIsNotAnalyzed(false)
                setIsLoading(false)
                setIsAnalyzedSuccess(true)
                return 0
            }

            /** If Current are analyzing */
            if(  ['WaitingForAnalyzing' , 'InAnalyzing' ].indexOf(res.data.scene_status) == -1 )
                setIsLoading(false)


        }).catch((err) => {

            /** TODO: Replace message to status code */
            /** Is current scene is not analyzed */
            if( !err.response.data.status || err.response.data.message == "Scene not found" ){ 
                setIsLoading(false)
                setIsNotAnalyzed(true)
                
                /** Call a callback to set state of scene to analyzed complete */
                if(onSceneStatus){
                    onSceneStatus( { scene_status : "SceneNotFound"} )
                }

                return 0

            }
            
            /**
             * Analyze failed
             */
             if(err.response.data.message == "AnalyzeFailed")
             {
                 setIsNotAnalyzed(false)
                 setIsLoading(false)
                 setIsAnalyzedSuccess(false)
                                 
                /** Call a callback to set state of scene to analyzed complete */
                if(onSceneStatus){
                    onSceneStatus( {scene_status : "AnalyzeFailed"} )
                }

                return 0
             }
             
        })

    }, [])

    /** Is loaded */
    return <div className="hide-on-hover" style={{transition:".25s"}}>
        {
            isLoading ? // Check is loading state
            <i className="fad fa-circle-notch bg-light p-2 rounded-circle spin-this text-white"></i> : 

            isNotAnalyzed ?  // Current scene was not analyed
                <div className="btn btn-dark btn-floating "> <i className="fas fa-question-circle fade-in"></i></div> :

            isAnalyzedSuccess  ?  //check is current scene is AnalyzeComplete 
                <div className="btn bg-success btn-floating "><i className="fas fa-check text-dark fade-in"></i></div> :

            // If current scene was fail
            <div className="btn bg-danger btn-floating"><i className="fas fa-exclamation fade-in"></i></div> 
        }
    </div>
}

export default SceneAnalyzeStatus  