import React from 'react'
import WOARenderPriority from '../ParamSettings/RenderPriority'
import WOAC4DSoftwareVersion from './SoftwareVersion'
import WOAC4DEngineVersion from './EngineVersion'
import WOAStartFrame from '../ParamSettings/StartFrame'
import WOAEndFrame from '../ParamSettings/EndFrame'
import WOAFrameStep from '../ParamSettings/FrameStep'
import WOABWorkingFolderPath from '../ParamSettings/WorkingFolderPath'

export default function WOAC4D () {
    return(
      <div>
            <div className="row" id="renderer_environment_container" >
              <div className="col-lg-6">
                <WOAC4DSoftwareVersion />
              </div>
              <div className="col-lg-6">
                <WOAC4DEngineVersion />
              </div>
              </div>
         
              <WOABWorkingFolderPath />

           <div className="row">
        
            </div>
            <div className="row">
              <div className="col-lg-4" id="start_frame_form_group">
                <WOAStartFrame />
              </div>
              <div className="col-lg-4" id="end_frame_form_group">
                <WOAEndFrame />
              </div>
              <div className="col-lg-4" id="frame_steps_form_group">
                <WOAFrameStep />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10">
                <WOARenderPriority />
              </div>
              <div className="col-lg-2 text-right mt-auto">
                <button className="btn btn-success btn-block text-dark form-group">
                <i className="fad fa-star-of-david me-2"></i>Start Render Job
                </button>
              </div>
            </div>
          </div>
        )
}
