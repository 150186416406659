import Tippy from '@tippyjs/react';
import { MDBBtn } from 'mdbreact';
import React from 'react'
import QuickTips from '../../components/QuickTips/QuickTips';
import Translate from '../../components/Translate';


function CostCaculator() {
  return <div >
    <div className="d-flex">
        <div className="h3 text-light m-0">
            <Translate text="Cost Calculator" />
            <small className="d-block" ></small>
        </div>
        <QuickTips />
    </div>
    <div className="card">
      <div className="card-body bg-dark">
        <h4 className="mb-3">1. Your Project</h4>
        <div className="row render-engines">
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="form-group">
              <div className="form-floating">
                <select className="form-select" id="project_type" aria-label="Floating label select example">
                  <option value="animation" defaultValue="selected">Animation</option>
                  <option value="single-frame">Single Frame</option>
                </select>
                <label for="project_type">Project type*</label>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="form-group">
              <div className="form-floating">
                <input type="number" className="form-control" name="number_of_frames" id="number_of_frames" placeholder="number of frame" />
                <label for="number_of_frames">Number of frame*</label>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="form-group">
              <div className="form-floating">
                <input type="number" className="form-control" name="rendertime_mins" id="rendertime_mins" placeholder="time in minutes" />
                <label for="rendertime_mins">Rendertime (Min)*</label>
              </div>
            </div>
          </div>
        </div>
        <h4 className="mt-3">2. Your Workstation</h4>
      
        <div className="nav nav-pills my-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <a className="nav-item nav-link active" id="v-pills-CPU-tab" data-bs-toggle="pill" href="#v-pills-CPU" role="tab" aria-controls="v-pills-CPU" aria-selected="true">CPU</a>
          <a className="nav-item nav-link" id="v-pills-GPU-tab" data-bs-toggle="pill" href="#v-pills-GPU" role="tab" aria-controls="v-pills-GPU" aria-selected="false">GPU</a>
        </div>
        <div className="tab-content" id="v-pills-tabContent">
      
        <div className="tab-content">
          <div className="tab-pane fade show active" id="v-pills-CPU" role="tabpanel" aria-labelledby="v-pills-CPU-tab">
            <div className="row render-engines">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                  <div className="form-floating">
                    <select name="processor_brand" id="processor_brand" className="form-select">
                      <option value="AMD">AMD</option>
                      <option value="Intel">Intel</option>
                      <option value="Intel XEON">Intel XEON</option>
                    </select>
                    <label for="processor_brand">Brand*</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                  <div className="form-floating">
                    <select name="processor_model" id="processor_model" className="form-select">
                      <option value="Bronze">Bronze</option>
                      <option value="D-15xx">D-15xx</option>
                      <option value="E-2xxx">E-2xxx</option>
                      <option value="E3-1xxx">E3-1xxx</option>
                      <option value="E3-1xxx v2">E3-1xxx v2</option>
                      <option value="E3-1xxx v3">E3-1xxx v3</option>
                      <option value="E3-1xxx v5">E3-1xxx v5</option>
                      <option value="E3-1xxx v6">E3-1xxx v6</option>
                      <option value="E5-1xxx">E5-1xxx</option>
                      <option value="E5-1xxx v2">E5-1xxx v2</option>
                      <option value="E5-1xxx v3">E5-1xxx v3</option>
                      <option value="E5-1xxx v4">E5-1xxx v4</option>
                      <option value="E5-2xxx">E5-2xxx</option>
                      <option value="E5-2xxx v2">E5-2xxx v2</option>
                      <option value="E5-2xxx v3">E5-2xxx v3</option>
                      <option value="E5-2xxx v4">E5-2xxx v4</option>
                      <option value="E5xxx">E5xxx</option>
                      <option value="E7-8870">E7-8870</option>
                      <option value="Gold">Gold</option>
                      <option value="i7-3xxx">i7-3xxx</option>
                      <option value="L5xxx">L5xxx</option>
                      <option value="Platinum">Platinum</option>
                      <option value="Silver">Silver</option>
                      <option value="W-2xxx">W-2xxx</option>
                      <option value="W3xxx">W3xxx</option>
                      <option value="W5xxx">W5xxx</option>
                      <option value="X3440">X3440</option>
                      <option value="X5xxx">X5xxx</option>
                      <option value="other">Other</option>
                    </select>
                    <label for="processor_model">Model*</label>
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                  <div className="form-floating">
                    <select name="processor_type" id="processor_type" className="form-select">
                      <option value="37.73437500" defaultValue="selected">E5-1620</option>
                      <option value="44.65032857">E5-1650</option>
                      <option value="40.50505000">E5-1660</option>
                    </select>
                    <label for="processor_typemodel_label">Type*</label>
                  </div>
            
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6">
         
                <div className="form-group">
                  <div className="form-floating">
                    <input type="number" className="form-control" name="processor_ghz" id="processor_ghz" placeholder="GHz" />
                    <label for="processor_ghz_label">GHz*</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                  <div className="form-floating">
                    <input type="number" className="form-control" name="processor_cores" id="processor_cores" placeholder="Cores"/>
                    <label for="processor_cores_label">Cores*</label>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="form-group">
                  <div className="form-floating">
                    <input type="number" className="form-control" name="processor_cpus" id="processor_cpus" placeholder="Number of CPU(s)" />
                    <label for="processor_cpus_label">Number of CPU(s)*</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="v-pills-GPU" role="tabpanel" aria-labelledby="v-pills-GPU-tab">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <label for="octanebench_score_label">Enter your Computers OctaneBench Score
                  <a target="_blank" href="http://render.otoy.com/octanebench/" rel="noopener noreferrer"><i className="fa fa-info-circle" /></a></label>
                  <input type="number" className="form-control" name="octanebench_score" id="octanebench_score" />
                </div>
              </div>
            </div>
          </div>
        </div>
      
          </div>
            
        <h4 className="my-3">3. Render Estimation</h4>
        <div className="row render-engines">
          <div id="est_cost_loader" className="srfloader" style={{display: 'none'}} />
          <div className="col-sm-12"><h5 className="text-success">RENDER TIME</h5></div>
        </div>
        <div className="row">
          <div className="col-sm-3">Bronze: <span id="est_rendertime_low">1.4 hours</span></div>
          <div className="col-sm-3">Silver: <span id="est_rendertime_medium">21.1 minutes</span></div>
          <div className="col-sm-3">Gold: <span id="est_rendertime_high">8.5 minutes</span></div>
          <div className="col-sm-3 text-success" >On Your Workstation: <span id="est_rendertime_your_workstaion">3.5 days</span></div>
        </div>
        <br />
        <div className="row">
          <div className="col-sm-12"><h5 className="text-success">COST</h5></div>
        </div>
        <div className="row">
          <div className="col-sm-3">Bronze: $<span id="est_cost_low">34.85</span> <span className="hidden-xs" style={{fontWeight: 'normal'}}><i>(20 nodes)</i></span></div>
          <div className="col-sm-3">Silver: $<span id="est_cost_medium">69.7</span> <span className="hidden-xs" style={{fontWeight: 'normal'}}><i>(80 nodes)</i></span></div>
          <div className="col-sm-3">Gold: $<span id="est_cost_high">139.39</span> <span className="hidden-xs" style={{fontWeight: 'normal'}}><i>(200 nodes)</i></span></div>
          <div className="col-sm-3">&nbsp;</div>
        </div>
        <br />
      </div>
    </div>
  </div>

}


export default CostCaculator ;

