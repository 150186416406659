import jsZip from "jszip"
import aws, { S3 } from "aws-sdk"
import fetch from "node-fetch"
import SystemEventManager from "events"
import streamSaver from "streamsaver"
import streamBrowserify from "stream-browserify"
import ponyfill from "web-streams-ponyfill"
import buffer from "buffer"
import Logger from "./debug"
export default class AWSDownloader extends SystemEventManager {

    download_path = ''
    queue_list    = [] 
    downloading_file = []
    files = [] 
    AWS = {} 
    config = {}
    folder_name = ''
    _file_index = 0 
    bucket_url = ''
    parsedURLList=[]

    max_session = 6
    _workers = 0 
    zipFolder = new jsZip()

    writeStream 

    initial( path , AWS )  {

        // Initial AWS 
        this.AWS = AWS 
        this.download_path = path
        this.folder_name = path.split("/")[path.split("/").length -2 ]
        this.config = AWS.config
        aws.config.update(AWS.config)
        // Logger('DOWNLOAD' , AWS.config)
        this.bucket_url = `https://${AWS.config.Bucket}.s3-accelerate.dualstack.amazonaws.com` 

        // TODO : Remove comment in next line when go live !!! 
        streamSaver.mitm = "/mitm.html"
        streamSaver.WritableStream = ponyfill.WritableStream
        this.writeStream = new streamSaver.createWriteStream(`${this.folder_name}.zip`).getWriter()

    }

    /**
     * Recursive download files
     * Improve performance
     * 
     * @returns {None}
     */
    async DownloadManagerRecursive() {
        // 
        if(this._file_index >= this.parsedURLList.length  ) return 0 ;
        let downloadId = this._file_index
        /**
         * 
         * Loops
         */
        // var loop_times = this.max_session - this._workers

        /**
         * Logger debug
         */
        // Logger('DOWNLOAD' , `RECURSIVE DOWNLOADER : TIMES =  `, loop_times )

        /**
         * Downloader
         * 
         */
        await this.DownloadWorkerAsync(downloadId)
            .then( async ()  => {

                /**
                 * Increse position
                 * 
                 */
                this._file_index++
                /**
                 * Logger debug
                 */
                Logger("QUEUE_INSERT:WARN", this.parsedURLList[downloadId].name.split("/").pop(),downloadId )
                await this.DownloadManagerRecursive()
            })
    }

    /**
     * Still a downloader worker but have a promise
     * 
     * @returns {Worker}
     */
    async DownloadWorkerAsync(downloadId){
        // if(this._file_index >= this.parsedURLList.length - 1 ) return 0 ;
        const id = downloadId
        const { url , name} = this.parsedURLList[downloadId]
        let rs = streamBrowserify.Readable()
        let reader
        rs._read = () => {
            let p = reader || (reader = fetch(url).then(
                res => {
                    this.emit("data", { filename : name })
                    return res.body.getReader() ;
                }))
            
            p.then(reader => reader.read().then(({ value, done }) =>
                {
                    rs.push( done ? null : new Buffer(value) );
                    if(done) {
                        Logger("DOWNLOADING:SUCCESS", name.split("/").pop() , id )
                    }
                }
                )
            )

        }
        this.zipFolder.file(name, rs , {base64:true, binary:true})
    }


    /**
     * Start download action
     * 
     * @returns {}
     */
    async StartDownload() {
        window.Buffer = buffer.Buffer
        Logger("TOTAL_FILES:OK", this.parsedURLList.length )
        await this.DownloadManagerRecursive()
        
        this.zipFolder.generateInternalStream({
            type: 'uint8array',
            streamFiles: true
        })
        .on('data', data => this.writeStream.write(data))
        .on('error', err => console.error(err))
        .on('end', () => {
            this.writeStream.close();
        })
        .resume();
        return 0 
          
    }

    /**
     * Download Manager Sync
     * 
     * @returns {}
     */
    DownloadManager() {
        if(this._file_index >= this.parsedURLList.length - 1 ) return 0 ;

        /** 
         * START DOWNLOADER
         * 
         */
        this.DownloadWorker()

        /**
         * RECURSIVE
         * 
         */
        this._file_index++
        this.DownloadManager()
    }


    /**
     * Download and push to Zip
     * 
     * @returns {}
     */
    DownloadWorker(){
        if(this._file_index >= this.parsedURLList.length - 1 ) return 0 ;

        const { url , name} = this.parsedURLList[this._file_index]
        let rs = streamBrowserify.Readable()
        let reader
        rs._read = () => {
            let p = reader || (reader = fetch(url).then(
                res => {
                    this.emit("data", { filename : name })
                    return res.body.getReader() ;
                }))
            
            p.then(reader => reader.read().then(({ value, done }) =>
                rs.push( done ? null : new Buffer(value) ))
            )

        }
        this.zipFolder.file(name, rs , {base64:true, binary:true})
    }


    Queue() {
        this.files.forEach( (file , index ) => {
            this.parsedURLList.push({url : `${this.bucket_url}/${file.Key}`, name : file.Key })
        } )
        // Logger("INITIAL_DOWNLOAD:SUCESS",this.parsedURLList)
        this.StartDownload();
    }

    async fetchPath( continueToken = '' ){
        var params = {
            Prefix : this.download_path,
            Bucket : this.AWS.config.Bucket,
        }
        if(continueToken != "") params.ContinuationToken = continueToken

        const s3 = new S3()

        await s3.listObjectsV2(params).promise()

        .then(data => {
            
            if(data.IsTruncated) {
                Logger("FETCH_ALL_OBJECT:WARN" ,  "This array has been truncated !")
                
                this.files = [...this.files , ...data.Contents ]

                return this.fetchPath(data.NextContinuationToken)
            }

            Logger('DOWNLOAD' ,  "Start download ")
            this.files = [...this.files , ...data.Contents ]
            this.Queue()
            return 0
        })


    }

    async fetchAllSubItems() {
        await this.fetchPath()
    }
}

