import React from 'react'

export default function WOAHoudiniOutputFormat(){
    return (
        <div className="form-group m-0">
            <label id className="form-label semibold">Output format</label>
            <div className="form-control-wrapper">
                <select id="select_output_format" className="form-control">
                    <option selected>Choose output format...</option>
                    <option value="F3">F3</option>
                    <option value="F4">F4</option>
                    <option value="F5">F5</option>
                    <option value="F6">F6</option>
                </select>
            </div>
            <small>This value must be matched with your node setting!</small>
        </div>
    )
}