
import React from 'react'

export default function ArnoldOutputTGA({}) {

    return (
    <div className="card card-body">
        <legend>Targa Image Control:</legend>
        <div className="form-group row">
            <div className="col-lg-2">
                <label>Bits-Per-Pixel :</label>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="tga_16" name="TGA_BPP" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="tga_16">16</label>
                </div>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="tga_24" name="TGA_BPP" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="tga_24">24</label>
                </div>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="tga_32" name="TGA_BPP" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="tga_32">32</label>
                </div>
            </div>
        </div>
        <div className="form-group row">
        
            <label className="col-lg-2">Properties:</label>
        
            <div className="col-lg-2">
                <div className="form-group">
                    <div className="custom-control custom-checkbox custom-checkbox-success custom-checkbox-success">
                        <input type="checkbox" className="custom-control-input" id="tga_compress" name="tga_compress" />
                        <label className="custom-control-label" htmlFor="tga_compress">Compress</label>
                    </div>
                </div>
            </div>
            <div className="col-lg-2">
                <div className="form-group">
                    <div className="custom-control custom-checkbox custom-checkbox-success custom-checkbox-success">
                        <input type="checkbox" className="custom-control-input" id="tga_alphaSplit" name="tga_alphaSplit" />
                        <label className="custom-control-label" htmlFor="tga_alphaSplit">Alpha Split</label>
                    </div>
                </div>
            </div> 
            <div className="col-lg-2">
                <div className="form-group">
                    <div className="custom-control custom-checkbox custom-checkbox-success custom-checkbox-success">
                        <input type="checkbox" className="custom-control-input" id="tga_Pre_MultipliedAlpha" name="tga_Pre_MultipliedAlpha" />
                        <label className="custom-control-label" htmlFor="tga_Pre_MultipliedAlpha">Pre-Multiplied Alpha</label>
                    </div>
                </div>
            </div> 
        </div>
    </div>  
    )
}