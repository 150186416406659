import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory  } from 'react-router-dom'
import Layout from '../Spaces/Layout'
import { connect } from 'react-redux'
import ListView from "./ListView"
import GridView from "./GridView"
import { useEffect } from 'react'


/**
 * SubRouter Component
 * @returns {React.Component}
 */
const FolderManager = ( props ) => {

  const history = useHistory()

  /**
   * Listen of url changed handle
   * 
   */
     useEffect(() => {
      return history.listen((location) => { 
        
        let the_path = location.pathname
        
        if(location.pathname == "/")  the_path = ""
        
        /** Folder Contents */
        if(props.onUrlChanged)
          props.onUrlChanged(the_path) 

      }) 
    },[history]) 

  /**
   * get prop from props :X 
   * 
   */
  const { path , data , isLoading , isRootPath, gridStyle } = props 

  /**
   * If is root directory, show upload section
   * 
   */
  if( isRootPath && data == [] ) {
    
    /**
     * Return folder data 
     * 
     */

  }
  
  /**
   * If user choosing grid view, then show grid view components
   * 
   */
  if( gridStyle == "gridView" )  return (<GridView {...props} />)

  /**
   * If user choose listing view, return this 
   * 
   */
  return ( <ListView  {...props} /> )
  

}

export default FolderManager
  