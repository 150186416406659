import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function FolderSkeleton({}) {
    

  /**
   * Skeleton of folder before browser got data from server-side.
   * 
   * 
   * 
   * @returns {React.Component}
   */
   return (
    <div className="col-12" >
      <div className="loading-space d-flex align-items-center justify-content-center">
        <div className="spinner-border spinner-border-lg fast" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div> 
  )

}