import React from 'react'

export default function WOARangeFrom() {
    return (
        <div className="form-group">
            <label id="start_frame_label" className="form-label semibold">Range From*</label>
            <div className="form-control-wrapper">
                <input id="start_frame" type="number" className="form-control srf_require_field" placeholder="Start From" />
                <small className="text-muted" />
            </div>
        </div>
          
    )
}