import React from 'react';
import { useState } from 'react';
import Logger from '../../utils/debug';


/**
 * Return Scene Analyze Form
 * 
 * 
 * @returns {React.Component}
 */

function MaxSceneAnalyze({ scene , onSubmit }){

    /**
     * Initial Form Properties
     * 
     */
    const [softwareVersion, setSoftwareVersion] = useState("3dsmax_2022")
    const [renderEngineVersion, setRenderEngineVersion] = useState("vray_5")
    const [renderType, setRenderType] = useState('cpu')
  
    /**
     * Handle submit event 
     * 
     * @param {HTMLFormElement} e Event
     * 
     */
    function _onSubmit(e, item) {
        /**
         * Stop default event handle
         */
        e.preventDefault();

        /**
         * Check is hook available
         * 
         */
        if(onSubmit)
            /**
             * Call hook with event data 
             * @param {HTMLFormElement} e 
             * @param {Spaces.FileType} item
             * @param {String} softwareVersion
             */
            return onSubmit( e , {softwareVersion,renderEngineVersion,renderType, app: "3dsmax"} )

        /**
         * Debug
         * 
         */
        Logger("SCENE_ANALYZE:SUBMIT:WARN", "Submit form handle" )
    }

    /**
     * Scene Path input
     * 
     */
    function ScenePath() {
        return (
            <div className="form-group">
                <label className="form-label semibold">Scene Path</label>
                <div className="form-control-wrapper form-control-icon-left">
                    <input id="scene_analyze_path" type="text" value={`${scene.Key.replace( `${localStorage.getItem("uwf")}`, "" ).replace("//", "/")}`} className="form-control" disabled placeholder="Scene Path" style={{paddingLeft:50}} />
                    <div
                        style={{left:36,width:38,height:38,lineHeight:"40px",textAlign:"center",position:"absolute",top:53}}>
                        <i className="fa fa-folder"></i> N:</div>
                </div>
            </div>
        )
    }


    /**
     * Get processor type options
     * 
     * @returns {React.Component}
     */
    function ProcessorType() {
        return (
            <div className="form-group">
                <div className="form-check form-check-inline me-5">
                    <input type="radio" id="cpu" name="ProcessorType" className="form-check-input cursor-pointer"  onChange={()=> setRenderType("cpu")} checked={renderType === "cpu"} />
                    <label className="form-check-label cursor-pointer" htmlFor="cpu">CPU</label>
                </div>
                <div className="form-check form-check-inline">
                    <input type="radio" id="gpu" name="ProcessorType" className="form-check-input cursor-pointer"  onChange={() => setRenderType("gpu")} checked={renderType === "gpu"} />
                    <label className="form-check-label cursor-pointer" htmlFor="gpu">GPU</label>
                </div>
            </div>
            
        )
    }

    /**
     * Form select software version
     * 
     * @returns {HTMLFormSelector}
     */
    function ChooseSoftwareVersion(){
        return(
            <div className="col-lg-6">
                <div className="form-group">
                    <label htmlFor="software_version" className="form-control-label semibold">Software Version*</label>
                    <select value={softwareVersion} onChange={ e => setSoftwareVersion(e.target.value) } id="software_version" className="form-control srf_require_field">
                        <option value="3dsmax_2022">Autodesk 3ds Max 2022</option>
                        <option value="3dsmax_2021">Autodesk 3ds Max 2021</option>
                        <option value="3dsmax_2020">Autodesk 3ds Max 2020</option>
                        <option value="3dsmax_2019">Autodesk 3ds Max 2019</option>
                        <option value="3dsmax_2018">Autodesk 3ds Max 2018</option>
                        <option value="3dsmax_2017">Autodesk 3ds Max 2017</option>
                        <option value="3dsmax_2016">Autodesk 3ds Max 2016</option>
                        <option value="3dsmax_2015">Autodesk 3ds Max 2015</option>
                    </select>
                </div>
            </div>
        )
    }


    /**
     * Form select render engine version
     * 
     * @return {React.Component}
     * 
     */
    function ChooseRenderEngineVersion(){
        return (

            <div className="col-lg-6">
                <div className="form-group">
                    <label htmlFor="render_engine_version" className="form-control-label semibold">Render Engine Version*</label>
                    <select id="render_engine_version" value={renderEngineVersion} onChange={e => setRenderEngineVersion(e.target.value)} className="form-control srf_require_field">
                    {renderType==="cpu"?
                        <> 
                         <optgroup label="V-Ray">
                            <option value="vray_5">V-Ray 5 (CPU)</option>
                            <option value="vray_next">V-Ray Next 4.3 (CPU)</option>
                            <option value="vray_4_2_cpu">V-Ray Next 4.2 (CPU)</option>
                            <option value="vray_3">V-Ray 3.x</option>
                            <option value="vray4phoenix4">V-Ray 4 Phoenix 4</option>
                            <option value="vray3phoenix3">V-Ray 3 Phoenix 3</option>
                        </optgroup>
                        <optgroup label="Corona">
                            <option value="corona6">Corona 6</option>
                            <option value="corona5">Corona 5</option>
                            <option value="corona4">Corona 4</option>
                            <option value="corona3">Corona 3</option>
                            <option value="corona2">Corona 2</option>
                            <option value="corona17">Corona 1.7</option>
                            <option value="corona16">Corona 1.6</option>
                        </optgroup>
                        <optgroup label="Arnold">
                            <option value="arnold4_0_1_cpu">MAXtoA 4</option>
                            <option value="arnold3">MAXtoA 3</option>
                        </optgroup>
                        <optgroup label="3ds Max">
                            <option value="quicksilver_hardware">Quicksilver Hardware</option>
                            <option value="art">ART</option>
                            <option value="scanline">Scanline</option>
                            <option value="veu_file">VEU File</option>
                        </optgroup>
                        </> : <>
                        <optgroup label="V-Ray">
                            <option value="vray_5_gpu">V-Ray 5 (GPU)</option>
                            <option value="vray_next_43_gpu">V-Ray Next 4.3 (GPU)</option>
                        </optgroup>
                        <optgroup label="Arnold">
                            <option value="arnold4_0_1_gpu">MAXtoA 4 (GPU)</option>
                        </optgroup>
                        <optgroup label="Redshift">
                            <option value="redshift_3_0_gpu">Redshift 3.0 (GPU)</option>
                        </optgroup>
                        </>
                    }
                    
                    
                    
                    </select>
                </div>
            </div>
        )
    }


    /**
     * Modal Popup
     * 
     * 
     */
    return(
        <div className="modal-body">
            <form onSubmit={ e => _onSubmit(e) } >

                {/** Scene path */}
                <ScenePath />

                {/** Choose render processor  */}
                <ProcessorType />

                <div className="row" id="selectSceneAnalyzeVersion">

                    {/** Choose software version  */}
                    <ChooseSoftwareVersion />

                    {/** Choose render engine version */}
                    <ChooseRenderEngineVersion/>
                </div>

                <div style={{textAlign: 'center', paddingTop: '20px'}}>
                    <button id="btnAnalyze" className="btn btn-inline btn-info ladda-button waves-effect waves-light"
                        data-style="expand-right" data-step={1}>
                        <span className="ladda-label textBtnAnalyze">Analyze Scene</span>
                        <span className="ladda-spinner" />
                        <span className="ladda-spinner" /></button>
                </div>
            </form>
        </div>)
}

export default MaxSceneAnalyze 