import React from 'react'
import { NavLink } from 'react-router-dom';
import AnalysisResultPanel from './AnalysisResult.Panel';
import CommonIssuesList from './CommonIssues.Panel';

class JobCollapse extends React.Component {
    render() {
      return (
            <div className="row  mt-2">
              <div className="col-lg-2">
              
                {/**
                    Listing button of Job Detail
                    
                    * Contains
                    - Analysis Results
                    - Render Log
                    - Commons issue
                    - Support Ticket
                */}
                <div className="list-group" id="list-tab" role="tablist">
                    <button className="list-group-item list-group-item-action active" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="home">Analysis result</button>
                    <button className="list-group-item list-group-item-action" data-bs-toggle="modal" data-bs-target="#render-log">Render log</button>
                    <button className="list-group-item list-group-item-action" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="messages">Common issues</button>
                    <NavLink className="list-group-item list-group-item-action text-light font-weight-normal" to="/create-ticket" >Support ticket</NavLink>
                </div>

              </div>
              <div className="col-lg-10">

                {/**
                    Horizontal Tab panel
                    
                    * Contains
                    - Analysis Results
                    - Common Issues Listing

                */}
                <div className="tab-content mt-2" id="nav-tabContent">

                  <div className="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">  
                    {/**
                        Analysis Result Panel Component
                    */}
                    <AnalysisResultPanel />
                  </div>

                  {/**
                      Common issues listing.
                  */}
                  <div className="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
                    <div className="font-weight-light">
                     
                      <CommonIssuesList /> <br />
                      
                      <a className="text-success" href="https://superrendersfarm.com/account/support-tickets">Create Support Ticket if you need further help</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
      )}
}
export default JobCollapse ;