import React, { useState } from 'react'

export default function ArnoldOutputTIF({}) {
const [tifImageType, setTifImageType] = useState("2");
    return (
        <div className="card card-body">
        <legend>TIF Image Control:</legend>
        <div className="form-group row mb-0">
            <div className="col-lg-2 mt-3">
                <label>Image type:</label>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="color_8bit_greyscale" name="TIF_image_type" className="custom-control-input" onClick={()=>setTifImageType("1")} checked={tifImageType === "1"} />
                    <label className="custom-control-label" htmlFor="color_8bit_greyscale">8-bit Greyscale</label>
                </div>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="color_8bit" name="TIF_image_type" className="custom-control-input" onClick={()=>setTifImageType("2")} checked={tifImageType === "2"}/>
                    <label className="custom-control-label" htmlFor="color_8bit">8-bit Color</label>
                </div>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="color_16bit" name="TIF_image_type" className="custom-control-input" onClick={()=>setTifImageType("3")} checked={tifImageType === "3"}/>
                    <label className="custom-control-label" htmlFor="color_16bit">16-bit Color</label>
                </div>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="color_16bit_SGI_LogL" name="TIF_image_type" className="custom-control-input" onClick={()=>setTifImageType("4")} checked={tifImageType === "4"}/>
                    <label className="custom-control-label" htmlFor="color_16bit_SGI_LogL">16-bit SGI LogL</label>
                </div>
            </div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-radio custom-checkbox-success">
                    <input type="radio" id="color_16bit_SGI_LogLUV" name="TIF_image_type" className="custom-control-input" onClick={()=>setTifImageType("5")} checked={tifImageType === "5"}/>
                    <label className="custom-control-label" htmlFor="color_16bit_SGI_LogLUV">32-bit SGI LogLUV</label>
                </div>
            </div>
        </div>
        <div className="form-group row">
            <div className="col-lg-2"></div>
            <div className="form-group col-lg-2">
                <div className="custom-control custom-checkbox custom-checkbox-success custom-checkbox-success">
                    <input type="checkbox" className="custom-control-input" id="tif_storeAlphaChannel" name="tif_storeAlphaChannel" disabled={tifImageType==="1"||tifImageType==="5"|| tifImageType==="4" } />
                    <label className="custom-control-label" htmlFor="tif_storeAlphaChannel">Store Alpha Channel</label>
                </div>
            </div>
        </div>
        <div className="form-group row">
            <label className="col-lg-2">Compression type:</label>
            <div className="col-lg-2">
                <div className="form-group">
                    <div className="custom-control custom-checkbox custom-checkbox-success custom-checkbox-success">
                        <input type="checkbox" className="custom-control-input" id="tga_compress" name="tga_compress" disabled={tifImageType==="5"|| tifImageType==="4" } />
                        <label className="custom-control-label" htmlFor="tga_compress">No Compression</label>
                    </div>
                </div>
            </div>
            <div className="col-lg-2">
                <div className="form-group">
                    <div className="custom-control custom-checkbox custom-checkbox-success custom-checkbox-success">
                        <input type="checkbox" className="custom-control-input" id="tga_alphaSplit" name="tga_alphaSplit" disabled={tifImageType==="5"|| tifImageType==="4" } />
                        <label className="custom-control-label" htmlFor="tga_alphaSplit">PackBits</label>
                    </div>
                </div>
            </div> 
        </div>
        <div className="form-group row">
            <label className="col-lg-2 my-auto">Dots per inch:</label>
            <div className="col-lg-2">
                <div className="form-group">
                    <input type="number" className="form-control col-form-label" id="dotsPerInch" placeholder="Dots per inch" value="300.0"/>

                </div>
            </div>
        </div>
    </div>  
    )
}