import React from 'react'

export default function WOAHoudiniSoftwareVersion () {
    return(
        <div className="form-group">
            <label htmlFor="software_version" className="form-control-label semibold">Software Version*</label>
            <select id="software_version" className="form-control srf_require_field">
                    <option selected>Choose software version...</option>
                    <option value="houdini18_5">Houdini 18.5</option>
                    <option value="houdini18">Houdini 18</option>
                    <option value="houdini17_5">Houdini 17.5</option>
            </select>
        </div>
        )
}