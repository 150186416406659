const initialState = {
    to: null
}

export default (state = initialState, action) => {
  switch (action.type) {

  case "REDIRECT":
    return { ...state, to : action.to };

    
  case "REDIRECT_CLEAR":
    return { ...state, to : null };
    
  default:
    return state
  }
};
