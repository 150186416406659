import { decode } from 'jsonwebtoken';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import Stores from '../redux/store';
import Logger from '../utils/debug';

/**
 * Global set io
 */
window.io = io

/**
 * Initial Socket IO connections
 * 
 */
    const socket = io('wss://superrendersfarm.com', {
    reconnection: true,
    path : '/socket.io'
})

/**
 * Socket IO Listener 
 * 
 * 
 */
function SocketIo({fullname}) {
    const [isRegisted, setIsRegisted] = useState(false)

    /**
     * 
     * 
     * 
     */
    if(fullname && !isRegisted ) {
        setIsRegisted(true)
        const userName = decode(localStorage.getItem("token")).user_name 
        socket.emit('subscribe', {
            user_name :  userName
        });
        Logger("SOCKET_REGISTER:SUCCESS", "Registed user ")
    }
        


    /**
     * 
     * Start a connection
     * 
     */

    useEffect(() => {
        /**
         * Socket Listen Handler here !!
         * 
         */


        /**
         * 
         */
         socket.on("scene analysis update", data => {
            /**
             * Reduce data from array Object to Object
             */
            const AnalyzeScenes = data.reduce(  ( o , i ) => ( { ...o, ...i } ) ) 

            /**
             * Update to store
             */
            Stores.dispatch({
                type : 'UPDATE_SCENE_ANALYZE',
                AnalyzeScenes
            })
            /**
             * Logger debg
             */
            Logger("ANALYSIS_UPDATE:SOCKET", 'Analyze Scene Updated for', AnalyzeScenes.user_name   )
        })


    }, [])
    /**
     * Dispatch stores
     * 
     */

    return <div></div>

}

/**
 * Connect redux store to check username 
 * 
 */
export default connect( ({UserStates}) => ({
    fullname : UserStates.fullname
})) (React.memo(SocketIo))