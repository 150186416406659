import React, { useEffect, useState } from 'react'
import {MDBBtn} from "mdbreact";
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import Logger from '../../utils/debug';
import axios from 'axios';
import env from '../../configs/env';

function VerifyAccount(props) {


  const [resendDisabled, setResendDisabled] = useState(false)
  const [isVerified, setIsVerified] = useState(false);
  const [isExpired, setIsExpired] = useState(false)
  const {token} = useParams()
  const history = useHistory()

  useEffect(() => {

    /**
     * If Request has token params, then go send request to api to verify the account
     * 
     */
    if(token) {
      axios.get(`${env.APP_API}/user/activate/${token}`)
      .then(res => {

        Logger('VERIFY_TOKEN:RESPONSE:OK', res.data)
        if(res.data.status === "success") {
          Logger("VERIFIED:OK", "Verified account !", localStorage.getItem("registed_email"))
          setIsVerified(true)
        }
      })
      .catch( err => {
        Logger("VERIFY:RESPONSE:FAIL" , "This token has been expired!")
        setIsExpired(true)
      })
    }



    /**
     * 
     * Registed email do not exist
     * Redirect to Resend Email Views,
     * Use need to type email to resend ! 
     * 
     */
     if(!localStorage.getItem("registed_email") && !token){
      history.push('/resend-email')
    }



    /**
     * Debug
     * 
     */
    Logger("ACCESS TOKEN:SUCCESS", token )
    Logger("RESEND EXPIRE", new Date(parseInt(localStorage.getItem("resent_mail_at"))))

    /**
     * 
     * 
     */
    if(localStorage.getItem("resent_mail_at") && ( localStorage.getItem("resent_mail_at") - Date.now()  > 0 )){
      setResendDisabled(true)
      setTimeout(() => {
        setResendDisabled(false)
      }, localStorage.getItem("resent_mail_at") - Date.now() );
    }
  }, [])

  function resendVerifyEmail(){

    /**
     * 5 Minutes
     * 
     */
    const resend_limit =  5 * 60 * 1000

    /**
     * Disable resend button for {resend_limit} minutes
     * 
     * 
     */
    setResendDisabled(true)

    /**
     * Set limit time to localStorage
     * 
     */
    localStorage.setItem("resent_mail_at", resend_limit + Date.now())
    

    /**
     * Send request to API.
     * 
     * 
     */
    axios.post(`${env.APP_API}/user/activate/resend`,{email : localStorage.getItem("registed_email")})
    .then( response => {
      Logger("RESEND:RESPONSE:OK" , response.data)
    })
    .catch(response => {
      Logger("RESEND:REPONSE:FAIL" , "Oops!! API Return a failure !")
    })


  }
  
  if(isVerified){
    return (
      <div className="h-100">
        <div className="bg-login"></div>
        <div className="logoLogin">
          <a href="https://superrendersfarm.com/" ><img src="https://statics.superrendersfarm.com/app/assets/media/image/logo-001.png" alt="Homepage" /></a>
        </div>
          <div className="h-100 align-items-center d-flex justify-content-center fade-in">
            <div className="login bg-dark rounded p-3 col-xl-3 col-lg-5 col-sm-6 shadow" >
              <div className="card-body">
                <div className="text-center pb-4">
                    Verified Account 
                    <i className="ti-check ms-1"></i>
                </div>
                <div className="text-center h4">
                  Verify successful
                </div>
                <div className="text-center text-muted">
                  Thank you for registering, you can now use your account to login
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <Link to="/login"><MDBBtn color="success" className="btn-block text-dark" >Login</MDBBtn></Link>
                    </div>
                </div>

              </div>
            </div>
        </div>
    </div>
  )
  }

    return (
      <div className="h-100">
        <div className="bg-login"></div>
        <div className="logoLogin">
          <a href="https://superrendersfarm.com/" ><img src="https://statics.superrendersfarm.com/app/assets/media/image/logo-001.png" alt="Homepage" /></a>
        </div>
          <div className="h-100 align-items-center d-flex justify-content-center fade-in">
            <div className="login bg-dark rounded p-3 col-xl-3 col-lg-5 col-sm-6 shadow" >
              <div className="card-body">

                <div className="text-center pb-4">
                    Registed 
                    <i className="ti-check ms-1"></i>
                </div>

                <div className="text-center h4">
                  { isExpired ? "Token Expired !!" : "Verify Your Account" }
                </div>
                
                <div className="text-center text-muted">
                  Your account has been not verified yet. Please check your mailbox to activate account
                </div>
                
                <div className="text-center mt-4 bg-success p-3 rounded" hidden={!resendDisabled} >
                  We have sent back the confirmation email, please wait 5 minutes to send again
                </div>
                
                <div className="row mt-4">
                    <div className="col-6">
                        <Link to="/login"><MDBBtn color="success" className="btn-block text-dark" >Login</MDBBtn></Link>
                    </div>
                      <div className="col-6">
                        <MDBBtn color="success" onClick={ () => { resendVerifyEmail() } } disabled={ resendDisabled } className="btn-block text-dark" >Resend Mail</MDBBtn>
                      </div> 
                    
                </div>

              </div>
            </div>
        </div>
    </div>
  )
}
export default VerifyAccount ;