import React, { useState } from 'react'
import ArnoldCamera from './ParamSettings/Camera';
import VrayGamma from './Vray/VrayGamma';
import ArnoldOutputBMP from './OutputExtension/BMP';
import ArnoldOutputEXR from './OutputExtension/EXR';
import ArnoldOutputJPGE from './OutputExtension/JPGE';
import ArnoldOutputPNG from './OutputExtension/PNG';
import ArnoldOutputTGA from './OutputExtension/TGA';
import ArnoldOutputTIF from './OutputExtension/TIF';

export default function MaxOutputSettings({data}) {


    const [changeOutputSetting,setChangeOutputSetting] = useState("")


    /** Render list cameras */
    function ListCamera(){
        return data.scene_info.camera_param.cameras_list.map( (e,i) => (
            <option selected={ i == 0  } value={e.camera_key}>{ e.camera_name }</option>
        ))
    }


    function getfileTypeLabel(){
      return (
        changeOutputSetting ===".bmp" ?"(BMP Image File)":
        changeOutputSetting ===".jpg" ?"(JPG Image File)":
        changeOutputSetting ===".png" ?"(PNG Image File)":
        changeOutputSetting ===".tga" ?"(TGA Image File)":
        changeOutputSetting ===".tif" ?"(TIF Image File)":
        changeOutputSetting ===".exr" ?"(EXR Image File)":
        changeOutputSetting ===".pic" ?"(PIC Image File)":
        changeOutputSetting ===".cxr" ?"(CXR Image File)":
        changeOutputSetting ===".cjpg" ?"(CJPG Image File)":
        changeOutputSetting ===".cjp" ?"(CJP Image File)":
        changeOutputSetting ===".rla" ?"(RLA Image File)":
        changeOutputSetting ===".rpf" ?"(RPF Image File)":  null
      )
    } 
    return (

        <div>
            
        <div className="form-group row">
                <div className="col-lg-10">
                    <div className="row">
                        <label htmlFor="outputFileName" className="col-lg-2 col-form-label my-auto">Output Setting:</label>
                        <div className="col-lg-8">
                            <div className="form-group row">
                                <div className="col-lg-4">

                                    <label htmlFor="cameraOption" >Camera<span className="text-danger">*</span></label>
                                    <select id="cameraOption" name="camera" className="form-control validate">
                                        <option value="">Choose Camera</option>
                                        <ListCamera />
                                    </select>

                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="outputFileName" >Output file name<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control col-form-label" id="outputFileName" placeholder="Output file name" />
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="outputSetting" className="d-block" >
                                        Output extension<span className="text-danger">*</span>
                                        <small className="float-end mt-1">{getfileTypeLabel()}</small>
                                    </label>
                                    <select className="form-control" value={changeOutputSetting} onChange={e => setChangeOutputSetting(e.target.value)} id="outputSetting">
                                        <option value="">---</option>
                                        <option value=".bmp">.bmp</option>
                                        <option value=".jpg">.jpg</option>
                                        <option value=".png">.png</option>
                                        <option value=".tga">.tga</option>
                                        <option value=".tif">.tif</option>
                                        <option value=".exr">.exr</option>
                                        <option value=".pic">.pic</option>
                                        <option value=".cxr">.cxr</option>
                                        <option value=".cjpg">.cjpg</option>
                                        <option value=".cjp">.cjp</option>
                                        <option value=".rla">.rla</option>
                                        <option value=".rpf">.rpf</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <VrayGamma />

            <div className="output-setting-row mt-2">
                <fieldset className="output-setting d-block mt-4">
                    {(()=>{if ( changeOutputSetting === ""){return (<div className="alert alert-danger alert-with-border" role="alert">You need to fill in all the params before continue !</div>)}
                        if (changeOutputSetting ===".bmp") {
                            return (
                            <ArnoldOutputBMP />
                            )
                        }
                        if (changeOutputSetting ===".jpg" || changeOutputSetting ===".cjpg" || changeOutputSetting ===".cjp") {
                            return(
                            <ArnoldOutputJPGE />
                            )
                        }
                        if (changeOutputSetting ===".png") {
                            return (
                            <ArnoldOutputPNG />
                            )
                        }
                        if (changeOutputSetting ===".tga") {
                            return (
                            <ArnoldOutputTGA />
                            )
                        }
                        if (changeOutputSetting ===".tif") {
                            return (
                            <ArnoldOutputTIF />
                            )
                        }
                        if (changeOutputSetting ===".exr") {
                            return (
                            <ArnoldOutputEXR />
                            )
                        } if (changeOutputSetting ===".pic" || changeOutputSetting ===".cxr" || changeOutputSetting ===".rla"|| changeOutputSetting ===".rpf") {
                            return ""
                        }
                    })()

                    }
                </fieldset>

                
        
            </div>
        
        </div>
    )
}
