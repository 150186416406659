import React from 'react'

export default function ArnoldOutputEXR({}) {

    return (
    <div className="card card-body">
        <legend>OpenEXR Configuration:</legend>
        <div className="form-group row mb-0">
            <div className="col-lg-2"></div>
            <div className="col-lg-4 card mx-3">
                <div className="card-header text-center">
                    <label>Main Render Output</label>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <label for="exr_format">Format</label>
                        <select id="exr_format" className="form-control" name="exr_main_layer_format">
                            <option value="0">Full Float (32 bits/Channel)</option>
                            <option value="1">Half Float (16 bits/Channel)</option>
                            <option value="2">Integer (32 bits/Channel)</option>
                        </select>
                    </div>  
                    <div className="form-group">
                        <div for="exr_type">Type</div>
                        <select id="exr_type" className="form-control" name="exr_main_layer_type">
                            <option value="RGBA">RGBA</option>
                            <option value="RGB">RGB</option>
                            <option value="Mono">Mono</option>
                            <option value="XYZ">XYZ</option>
                            <option value="XY">XY</option>
                            <option value="UV">UV</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="card">
                    <div className="card-header text-center">
                        <label>Global Options</label>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <label htmlFor="exr_compression">Compression</label>
                            <select id="exr_compression" name="exr_compression" className="form-control">
                                <option value="0">No Compression</option>
                                <option value="1">Run-length Encoding</option>
                                <option value="2">ZLIB, per scanline</option>
                                <option value="3">ZLIB, 16 scanlines</option>
                                <option value="4">PIZ</option>
                                <option value="5">Lossy 4x4 Blocks</option>
                                <option value="6">Lossy 4x4 Blocks(fixed rate)</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exr_storge-type">Storage Type</label>
                            <select id="exr_storge-type" className="form-control">
                                <option value="true">Store Image as Scanlines</option>
                                <option value="false" selected="">Store Image as Tiles</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exr_image_region">Image Region</label>
                            <select id="exr_image_region" className="form-control">
                                <option value="true">Save Region</option>
                                <option value="false" selected="">Save Full Image</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div className="form-group d-flex">
            <label className=" my-auto">Render Elements:</label>
            <div className="custom-control custom-switch custom-checkbox-success ms-2">
                <input type="checkbox" className="custom-control-input" id="exr_auto_add_render_element"  />
                <label className="custom-control-label" style={{userSelect: "none" }} htmlFor="exr_auto_add_render_element" >Automatically Add/Remove Render Elements from Render Dialog</label>
            </div>
        </div>
        <div className="form-group">
            <table className="table table-hover">
                <thead>
                    <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Format</th>
                    </tr>
                </thead>
                <tbody>
                    <tr><td colspan="3" ><strong>No Elements</strong></td></tr>
                </tbody>
            </table>
        </div>
    
    </div>  
    )
}