
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'


/**
 * Return resolutions width,height and ratio
 * 
 * @param {*} param0 
 * @returns 
 */

/**
 * If hook is not valid so this fake function will ignore javascript undefinded function errors
 * 
 */
const f = () => {} 


export default function MaxResolution({ width , height , ratio = null, updateWith = f , updateHeight= f , updateRatio = f }) {

    const [locked, setLocked] = useState(true)
    
    useEffect(() => {
        updateRatio(width / height)
    }, [])

    return(
        <div className="form-group row">
            <div className="col-lg-10">
                <div className="row">
                    <label htmlFor="inputPassword" className="col-lg-2 col-form-label my-auto">Resolution:</label>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group m-0">
                                    <label htmlFor="width" >Width <span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" id="width" placeholder="Width"
                                        value={width}
                                        onChange={ e => {
                                            if(locked) {
                                                updateWith(parseInt(e.target.value)) ;
                                                updateHeight( parseInt(e.target.value)  / ratio )
                                                
                                            }
                                            else {
                                                updateWith(parseInt(e.target.value))
                                                updateRatio(width / height)
                                            }
                                            
                                        } }
                                        />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group m-0">
                                    <label htmlFor="height" >Height { locked ? "(Auto)" : "" }<span className="text-danger">*</span></label>

                                    <input type="number"  disabled={ locked }  className={`form-control ${locked ? 'text-light' : '' }`} id="height" placeholder="Height"
                                        value={ height }
                                        onChange={ e =>  {
                                            updateHeight(parseInt(e.target.value))
                                            updateRatio(width / height)
                                        }}
                                        />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group m-0">
                                    <label htmlFor="ratio" >Ratio   <span className="text-danger">*</span></label>
                                    <div className="input-group">
                                        
                                        {/** Resolution ratio */}
                                        <input type="number" disabled={ locked }  className={`form-control ${locked ? 'text-light' : '' }`} id="ratio" placeholder="Ratio"
                                            value={  ratio == 1 ? width / height  : ratio  }
                                            onChange={e => updateRatio(parseFloat(e.target.value))}  />

                                        <button className="btn form-control col-2" onClick={ () => { setLocked(!locked) ;  updateRatio(width / height)} }>
                                            { locked ? <i className="fad fa-lock" /> : <i className="fad fa-lock-open-alt" /> }
                                        </button>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )   
}