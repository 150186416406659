import { MDBBtn, MDBBtnGroup } from 'mdbreact';
import React from 'react'

class TaskSubHeader extends React.Component {
    render() {
      return (
        <MDBBtnGroup>
          <MDBBtn color="dark">Tham lam</MDBBtn>
          <MDBBtn color="dark">Ngu dot</MDBBtn>
        </MDBBtnGroup>
      )}
}
export default TaskSubHeader ;