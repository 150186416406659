import React  from 'react'

export default function VrayImageSamplesAndFilter({}) {

    return (
        <div>
            <div className="card">
                <div className="card-header">Image Samples( Antialiasing )</div>
                <div className="card-body">
        
                    <div className="form-group row">
                        <div className="col-lg-10">
                            <div className="row">
                                <label className="col-lg-2 col-form-label my-auto">Type</label>
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="form-group m-0">
                                                <select id="vray_image_sample_type" className="form-control" name="imageSampler_type">
                                                    <option value="1" selected="">Bucket</option>
                                                    <option value="3">Progressive</option>
                                                </select>
                                            
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="card mt-2">
                <div className="card-header">Image Filter</div>
                <div className="card-body">

                    <div className=" row">
                        <div className="col-lg-10">
                            <div className="row">
                                <div className="col-lg-2 form-group">
                                    <div className="custom-control custom-checkbox custom-checkbox-success">
                                        <input type="checkbox" className="custom-control-input" id="filter"  />
                                        <label className="custom-control-label" htmlFor="filter" style={{userSelect: "none" }}>Image Filter</label>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-2">Filter:</div>
                                        <div className="col-lg-8">
                                            <span></span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    

    )
}