import React from 'react'

export default function WOAHoudiniOutputExtension(){
    return (
        <div className="form-group">
            <label id className="form-label semibold">Output extension</label>
            <div className="form-control-wrapper">
                <input id="output_extension" type="text" className="form-control" placeholder="e.g: .jpg"  />
            </div>
        </div>
    )
}