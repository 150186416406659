const initialState = {
  folder_tree : [],
  zip_found : false,
  special_symbol_link : false, 
  path : ""
}

export default (state = initialState, action) => {
  switch (action.type) {

  case 'LOAD_TREE':
    return {
      ...state,
      folder_tree : action.tree
    };
  
  case "ZIP_FOUND" :
    return {
      ...state,
      zip_found : true 
    }
  case "UPLOAD_DONE" : 
    return {
      ...state ,
      reload : true 
    }

  case "RELOAD" : 
    alert(1)
    return {
      ...state ,
      reload : true 
    }
  case "RELOADED" :
    return {
      ...state ,
      reload : false
    }
  case "PATH" :
    return {...state,  path : action.path }

  default:
    return state
  }
};
