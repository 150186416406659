import React, { useEffect, useState } from 'react'
import Stores from '../../redux/store';
import Translate from '../Translate';
import AWS from "aws-sdk"
import Thread from "../../utils/S3.Queue"
import AppNotification from '../AppNotification/AppNotification';
import { connect } from 'react-redux';
import Logger from '../../utils/debug';


function UploadBar({upload_started, uwf}) {
    
    useEffect(() => {
        Logger('RENDERING UPLOADBAR ' , upload_started)
        return () => {
            
        }
    }, [])

    const [isBackgroundUploading, setIsBackgroundUploading] = useState(false)
    const [uploadingFileName, setUploadingFileName] = useState(null)
    const [isUploadDone, setIsUploadDone] = useState(false)
    const [totalSize, setTotalSize] = useState("Analazing...")
    const [totalUploaded, setTotalUploaded] = useState(0)
    const [percentages, setPercentages] = useState(0);
    const [uploadedCount, setUploadedCount] = useState(0)
    const [currentFileProgress, setCurrentFileProgress] = useState(0)
    const [networkErrors, setNetworkErrors] = useState(false)
    const [uploadSpeed, setUploadSpeed] = useState(`0 MB/s`);
    

    /**
     * 
     * Amazon S3 Config
     * 
     * 
     */
    const S3Config = {
        region: 'ap-southeast-1',
        // useAccelerateEndpoint: true,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'ap-southeast-1:c14793e7-5e11-455d-9d64-886e71975a03'
        }),
        Bucket : 'clr-official-spaces',
        username : uwf
    }
    /**
     * Create new S3 Upload
     * 
     */
    const UploadSession = new Thread(S3Config )

    /**
     * LISTENERS
     * 
     * 
     */
    const handleUpdateProgressBar = (data) =>{
        
        setPercentages(data.percentages) // Total progress uploaded percentage
        setUploadingFileName( data.current_file.name ) // Current uploading file name
        setUploadedCount(data.files_uploaded) // 
        setTotalUploaded(data.total_uploaded_size) 
    }

    function handleCurrentFile(data) {
        // Logger(data)
        setCurrentFileProgress((data.loaded / data.total * 100).toFixed(2))
        // setPercentages((data.loaded / data.total * 100).toFixed(2))
    }

    function handleUploadDone(){
        Logger(`UPLOAD => DONE !!!!!!`)

        Stores.dispatch({
            type : "UPLOAD_DONE"
        })

        setIsUploadDone(true)
        setIsBackgroundUploading(true)

        setTimeout(() => {
            setIsBackgroundUploading(false)
            setIsUploadDone(false)
        }, 5000);
        
    }

    function handleNetworkError(file) {
        setNetworkErrors(true)
    }

    function handleSpeedUpdate(data) {
        setUploadSpeed(`${data} MB/s`)
    }



    const calculateFolderSize = () => {
        if (isBackgroundUploading) 
            setTimeout(() => {
                var folderSize = UploadSession.files.reduce( (preElm , current ) =>  (preElm.size + current.size ) )

                setTotalSize(folderSize)
            }
        ,5000
    )}


    if(upload_started && !isBackgroundUploading) {

        /**
         * Restart upload
         * 
         */
        Logger(`START UPLOADER`)
        setIsBackgroundUploading(true)
        UploadSession.setFiles( Stores.getState().UploadBar.files  )
        UploadSession.UploadQueue()
        UploadSession.on("s3HttpTransfer" , handleUpdateProgressBar )
        UploadSession.on("httpUploadProgress", handleCurrentFile )
        UploadSession.once("networkError", handleNetworkError)
        UploadSession.once("uploadDone" , handleUploadDone)
    }

    return (
        isBackgroundUploading && !isUploadDone ? 
            <div className="uploading-box shadow fade-in p-2 rounded cursor-pointer" data-bs-toggle="modal" data-bs-target="#UploadList">
                { networkErrors ? <AppNotification title={"Network Error !"} description={`Uploading ${currentFileProgress.name} fail!`} /> : <div></div> }

                <span className='d-inline-block text-truncate' style={{maxWidth:"600px",width:600}} ><small>
                <div className="spinner-border spinner-border-sm fast me-2" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                Uploading <b>{uploadedCount}</b> {currentFileProgress} % / <b>{ Stores.getState().UploadBar.files.length }</b>  -  <b>{totalUploaded} Gb</b> / </small></span>
                <span className="float-end">{percentages + "%"} </span>
                <div className="progress">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="5" aria-valuemax="100" style={{width: `${currentFileProgress}%`,minWidth:"5%", background:"yellow"}} ></div>
                </div>
                <div className="mt-1"></div>
                <div className="progress">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="5" aria-valuemax="100" style={{width: `${percentages}%`,minWidth:"5%" }} ></div>
                </div>
            </div> 
        : isUploadDone ? 
            <div className="text-black fade-in bg-success p-3 rounded" >
                <Translate text='Upload done, check your files & folder at SRF Spaces' />
            </div>
        :
        <div></div>
    )
}


export default connect(state => ({
    // State 
    upload_started : state.UploadBar.upload_started,
    uwf : state.UserStates.uwf
}))( UploadBar )