import React from 'react'
import CalculatorModal from './CalculatorModal/CalculatorModal'
import ChangeLanguage from './ChangeLanguage/ChangeLanguage';
import DownloadSyncApp from './DownloadSyncApp/DownloadSyncApp';
import RenderLogModal from './RenderLogModal/RenderLogModal'
import UploadModal from './Upload/Upload';

class Modal extends React.Component {
    render() {
        return (
            <>
                <CalculatorModal/>
                <RenderLogModal />
                <UploadModal />
                <DownloadSyncApp />
                <ChangeLanguage />
            </>
        )
    }
}
export default Modal ;