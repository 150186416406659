import React from 'react'

export default function WOAMaxSoftwareVersion () {
    return(
        <div className="form-group">
                        <label htmlFor="render_engine_version" className="form-control-label semibold">Render Engine Version*</label>
                        <select id="render_engine_version" className="form-control srf_require_field" >

                            <option>Choose engine version...</option>

                            <optgroup label="Redshift">
                                <option value="redshift3_0_gpu">Redshift 3.0 (GPU)</option>
                            </optgroup>

                            <optgroup label="V-Ray">
                                <option value="vray_5_cpu">V-Ray 5 (CPU)</option>
                                <option value="vray_5_gpu">V-Ray 5 (GPU)</option>
                                <option value="vray_next_cpu">V-Ray Next 4.3 (CPU)</option>
                                <option value="vray_next_gpu">V-Ray Next 4.3 (GPU)</option>
                                <option value="vray_4_2_cpu">V-Ray Next 4.2 (CPU)</option>
                                <option value="vray_3">V-Ray 3.x</option>
                                <option value="vray4phoenix4">V-Ray 4 Phoenix 4</option>
                                <option value="vray3phoenix3">V-Ray 3 Phoenix 3</option>
                            </optgroup>

                            <optgroup label="Corona">
                                <option value="corona6">Corona 6</option>
                                <option value="corona5">Corona 5</option>
                                <option value="corona4">Corona 4</option>
                                <option value="corona3">Corona 3</option>
                                <option value="corona2">Corona 2</option>
                                <option value="corona17">Corona 1.7</option>
                                <option value="corona16">Corona 1.6</option>
                            </optgroup>
                            
                            <optgroup label="Arnold">
                                <option value="arnold4_0_1_cpu">MAXtoA 4 (CPU)</option>
                                <option value="arnold4_0_1_gpu">MAXtoA 4 (GPU)</option>
                                <option value="arnold3">MAXtoA 3</option>
                            </optgroup>

                            <optgroup label="3ds Max">
                                <option value="quicksilver_hardware">Quicksilver Hardware</option>
                                <option value="art">ART</option>
                                <option value="scanline">Scanline</option>
                                <option value="veu_file">VEU File</option>
                            </optgroup>
                        
                        </select>
                    </div>
    )
}