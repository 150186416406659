import React from 'react'
import WOACamera from '../../ParamSettings/Camera'
import WOARenderOutputFile from '../../ParamSettings/RenderOutputFile'
import WOAMaxBatchToRender from './BatchToRender'
import WOAEveryNthFrame from '../../ParamSettings/EveryNthFrame'
import WOARangeFrom from '../../ParamSettings/RangeForm'
import WOATo from '../../ParamSettings/To'
import WOAMaxVrayOptions from './VRayOptions'

export default function WOAMaxAnimation() {
    return (
        <>
        <div className="row">
            <div className="col-lg-2" id="start_frame_form_group">
            <WOARangeFrom />
            </div>
            <div className="col-lg-2" id="end_frame_form_group">
            <WOATo />
            </div>
            <div className="col-lg-3" id="frame_steps_form_group">
            <WOAEveryNthFrame />
            </div>
            <div className="col-lg-5" id="renderoutput_file_form_group">
            <WOARenderOutputFile />
            </div>
        </div>
        <div className="row">
            <div className="col-lg-7">
            <WOACamera />
            </div>
            <div className="col-lg-5">
            <WOAMaxBatchToRender />
            </div>
        </div>
        <WOAMaxVrayOptions />
        </>
          
    )
}