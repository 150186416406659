import Stores from "../redux/store";
export function SpaceRedirect({to}) {

    Stores.dispatch({
        type : 'REDIRECT',
        to
    })
    
    setTimeout(() => {
        Stores.dispatch({
            type : 'REDIRECT_CLEAR'
        })
    }, 100);
    return 0
}