import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import DataTable, { createTheme } from 'react-data-table-component';
import FolderEmpty from '../../components/FolderEmpty/FolderEmpty';
import QuickTips from '../../components/QuickTips/QuickTips';
import InvoiceDownload from '../../components/InvoiceDownload/InvoiceDownload';
import "react-datepicker/dist/react-datepicker.css";

// https://github.com/jbetancur/react-data-table-component


export default function PaymentInvoices()  {
  const [datas, setDatas] = useState("")
  
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  console.log(dates)

  };

  const PickDateButton = forwardRef(({ value, onClick }, ref) => (
    <button className="btn btn-light" onClick={onClick} ref={ref}>
       {value||"Choose Date"}
    </button>
  ));
  function handleChange(state){
    setDatas(state.selectedRows)
  };
      return (
    <div >

      <div className="row mb-4">
        <div className="col-lg-4">
          <div className="h3">Payment Invoices</div>
          <small>Your Name</small>
        </div>
        <div className="col-lg-8">
          <div className="d-none d-xl-block float-end">
          <QuickTips />
          </div>
        </div>
      </div>
      <div className="bg-dark rounded card-body mb-4">
        <div className="d-flex justify-content-end">
        <DatePicker
        todayButton="Today"
        customInput={<PickDateButton />}
        renderCustomHeader={({
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
        }) => (
          <div>
            <button
              aria-label="Previous Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--previous"
              }
              style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
              onClick={decreaseMonth}
            >
              <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                }
              >
                {"<"}
              </span>
            </button>
            <span className="react-datepicker__current-month">
              {monthDate.toLocaleString("en-US", {
                month: "long",
                year: "numeric",
              })}
            </span>
            <button
              aria-label="Next Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--next"
              }
              style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
              onClick={increaseMonth}
            >
              <span
                className={
                  "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                }
              >
                {">"}
              </span>
            </button>
          </div>
        )}
          monthsShown={2}
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          selected={startDate}
          selectsRange
        />
        </div>
      </div>
      <DataTable
      columns={columns}
      data={data}
      title={<div>
        You Have {data.length} Invoices
      </div>}
      actions={<div className="float-end">

    {/**
     * Temporary delete {customStyles} from {customStyles} to test
     * TODO: Add {customStyles} to {customStyles} again after test done.
     */}
    </div>}
      theme="solarized" customStyles={{}}
      Clicked
      defaultSortField="date"
      onSelectedRowsChange={handleChange}
      noDataComponent={<FolderEmpty />}
      selectableRows
      contextActions={<InvoiceDownload data={datas} /> }
      /> 
    </div>
      )
}


const data = [
  { orderID: "addasd", renderCredits: '1 Render Credits', date: '5/10/2021',paymentType:'standard',cost:915 , currency:'USD' },
  { orderID: "dasd", renderCredits: '1 Render Credits', date: '5/10/2021',paymentType:'standard',cost:915 , currency:'USD' },
  { orderID: "asdasd", renderCredits: '1 Render Credits', date: '5/10/2021',paymentType:'standard',cost:915 , currency:'USD' },
  { orderID: "asdsada", renderCredits: '1 Render Credits', date: '5/10/2021',paymentType:'standard',cost:915 , currency:'USD' },
  { orderID: "asdasd", renderCredits: '1 Render Credits', date: '5/10/2021',paymentType:'standard',cost:915 , currency:'USD' },
  { orderID: "asdasd", renderCredits: '1 Render Credits', date: '5/10/2021',paymentType:'standard',cost:915 , currency:'USD' },
  { orderID: "8akf6xrf", renderCredits: '1 Render Credits', date: '5/10/2021',paymentType:'standard',cost:915 , currency:'USD' },
  { orderID: "asdasd", renderCredits: '1 Render Credits', date: '5/10/2021',paymentType:'standard',cost:915 , currency:'USD' },
  { orderID: "fgjNy4wV", renderCredits: '200 Render Credits', date: '5/10/2021',paymentType:'standard',cost:915 , currency:'Gift' }
];


const columns = [
{
  name: 'Order ID',
  selector: 'orderID',
  sortable: true,
},
{
  name: 'Render credits',
  selector: 'renderCredits',
  sortable: true,
},
{
  name: 'Date',
  selector: 'date',
  maxWidth: '200px',
  sortable: true,
},
{
  name: 'Payment type',
  selector: 'paymentType',
  maxWidth: '150px',
  sortable: true,
},
{
  name: 'Cost',
  selector: 'cost',
  maxWidth: '150px',
  sortable: true,
  cell: row => (
    row.cost +" $"
)
},
{
  name: 'Currency',
  selector: 'currency',
  maxWidth: '150px',
  sortable: true,
},
{
  name: 'Action',
  right: true,
  minWidth: '200px',
  cell: row => (
    <InvoiceDownload data={""} />
)
},
];
createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#3dd598',
  },
  background: {
    default: '#293134',
  },
  context: {
    background: '#3dd598',
    text: '#FFFFFF',
  },
  divider: {
    default: 'rgba(255,255,255,.54)',
  },
  action: {
    button: 'rgba(255,255,255,.54)',
    hover: 'rgba(255,255,255,.9)',
    disabled: 'rgba(255,255,255,.12)',
  },
});
