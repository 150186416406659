import AWS, { S3 } from "aws-sdk"
import Events from "events"
import { resolve } from "path"


export default class S3Browser extends Events {

    // Config 
    config = {} 



    /**
     * Username 
     */
    username  = "" 


    /**
     * S3 
     * 
     * 
     */
    s3 = {}


    /**
     * 
     * 
     */
    bucket = ""
    
    /**
     * 
     * @param {AWS.Config} config Configuration of AWS SDk
     */
    constructor( config ) {        

        super()

        /**
         * Update config AWS S3
         * 
         */
        AWS.config.update( config )

        this.config = config


        /**
         * Config username 
         * 
         */
        this.initialS3()

    }


    initialS3() {
        /**
         * Initial S3 Configuration
         * 
         * 
         */
        this.s3 =  new AWS.S3()
    }




    /**
     * List object 
     * 
     */
    listObjects(params = {}) {

        return new Promise( (resolve , reject) => {

            this.s3.listObjectsV2({

                /**
                 * Bucket ID
                 */
                Bucket : this.config.Bucket,
                ...params

            }, function(err, data) {
    
                // Error
                if (err) reject(err, err.stack);
    
                // Ok
                else resolve(data)
              });

        } ) ;

    }
    

}