import { combineReducers } from 'redux'
import UploadBar from "./UploadBar"
import UserStates from "./user"
import SpaceStates from "./spaceStates"
import LinkStates from "./links"
import JobStores from "./jobs"
import sceneAnalyze from "./sceneAnalyze"
export default combineReducers({
  UploadBar,
  UserStates,
  SpaceStates,
  LinkStates,
  JobStores,
  sceneAnalyze
})