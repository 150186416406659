import React from 'react'

export default function WOAMaxGamma(){
    return (
        <>
            <div className="form-group">
                <label htmlFor="input_gamma" className="control-label">Input Gamma*</label>
                <input id="input_gamma" type="number" className="form-control srf_require_field" placeholder="Input Gamma" defaultValue="2.2" />
            </div>
            <div className="form-group m-0">
                <label htmlFor="output_gamma" className="control-label">Output Gamma*</label>
                <input id="output_gamma" type="number" className="form-control srf_require_field" placeholder="Output Gamma" defaultValue={1.0} />
            </div>
        </>
    )
}
