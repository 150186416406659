import React from 'react'

export default function WOAMaxEngineVersion () {
    return(
    <div className="form-group">
        <label htmlFor="software_version" className="form-control-label semibold">Software Version*</label>
        <select id="software_version" className="form-control srf_require_field" >
                <option selected>Choose software version...</option>
                <option value="3dsmax_2022">Autodesk 3ds Max 2022</option>
                <option value="3dsmax_2021">Autodesk 3ds Max 2021</option>
                <option value="3dsmax_2020">Autodesk 3ds Max 2020</option>
                <option value="3dsmax_2019">Autodesk 3ds Max 2019</option>
                <option value="3dsmax_2018">Autodesk 3ds Max 2018</option>
                <option value="3dsmax_2017">Autodesk 3ds Max 2017</option>
                <option value="3dsmax_2016">Autodesk 3ds Max 2016</option>
                <option value="3dsmax_2015">Autodesk 3ds Max 2015</option>
        </select>
    </div>
    )
}