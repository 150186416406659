import { MDBCard, MDBCardBody, MDBTooltip } from 'mdbreact';
import React from 'react'

class StatusJobDetail extends React.Component {
    constructor() {
        super();
        this.state = {isHovered: false};
        this.toggleHover = this.toggleHover.bind(this);
      }
      
      toggleHover() {
        this.setState(prevState => ({isHovered: !prevState.isHovered}));
      }
    render() {
      return (
        <div onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} >
            <div className="clearfix text-white">
                <span className="float-start">
                    <div className="spinner-border spinner-border-sm fast me-2" role="status">
                    <span className="sr-only">Loading...</span>
                    </div>
                    {this.state.isHovered ? "est. remaining time 4h 31 min left" : "Rendering" }
                </span>
                <span className="float-end">50%</span> 
            </div>
            <MDBTooltip placement="top">
                <div className="loader w-100 my-2">
                    <div className="loaderBar" />
                </div>
                <div>Your scene file is being analyzed, please wait! It usually takes 5-20 minutes depends on your project size.</div>
            </MDBTooltip>    
            
            <div className="clearfix">
                <span className="float-start">{this.state.isHovered ? "est. total: $600 (remaining $400)" : "Cost: $200"}</span>
                <span className="float-end">{new Date().toLocaleTimeString()}</span> 
            </div> 
           
        </div>
      )}
}
export default StatusJobDetail ;