import React from 'react'
import Logger from '../../utils/debug'

export default function AnalysisResults({data}) {
    

    /** Logger  */ 
    Logger("SCENE", data)

        /**
     * Render log from sceneResult
     * 
     * @param {Object} sceneResult 
     */
    function RenderSceneLog( {sceneResult} ){

        /**
         * 
         */
        let SceneLogs = sceneResult

        // Logger("SCENE_LOG:WARNING", SceneLogs )
        /**
         * List log infomation
         * 
         */
        function InfoList(){
            let list  = SceneLogs.info.map(e => Object.values(e) )
            
            // Check list is empty
            if(list.length == 0) return <div></div>

            return (
                <div>
                    <p className="text-primary font-weight-bold font-size-19"><i className="fal fa-exclamation-triangle me-2"></i>Info</p>
                    <br/>
                    {list.map( warn =>
                        (
                            warn[0].items.length != 0 ?
                            <div  >
                                <div>- {warn[0].message} <button type="button" className="btn btn-sm btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#detail" aria-expanded="false" aria-controls="detail">View details</button></div>
                                <div className="collapse mt-2 bg-light p-3 rounded" id="detail">{warn[0].items.map( file => (<li className='px-2 mb-2' >{file}</li>))}</div>
                            </div> : 
                            <div>- {warn[0].message}</div>
                        )
                    )}
                <hr />

            </div>)
        }

        /**
         * Render warning list
         * 
         */
        function WarningList(){
            let list  = SceneLogs.warning.map(e => Object.values(e) )
            
            // Check list is empty
            if(list.length == 0) return <div></div>

            return (
                <div>
                    <p className="text-warning font-weight-bold font-size-19"><i className="fal fa-exclamation-triangle me-2"></i>Warning</p>
                    <br/>
                    {list.map( warn =>
                        (
                            warn[0].items.length != 0 ?
                            <div  >
                                <div>- {warn[0].message} <button type="button" className="btn btn-sm btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#detail" aria-expanded="false" aria-controls="detail">View details</button></div>
                                <div className="collapse mt-2 bg-light p-3 rounded" id="detail">{warn[0].items.map( file => (<li className='px-2 mb-2' >{file}</li>))}</div>
                            </div> : 
                            <div>- {warn[0].message}</div>
                        )
                    )}
                <hr />

            </div>)
        }

        /**
         * Error List
         * 
         */
        function ErrorList(){
            let list  = SceneLogs.error.map(e => Object.values(e) )
            
            // Check list is empty
            if(list.length == 0) return <div></div>

            return (
                <div>
                    <p className="text-danger font-weight-bold font-size-19"><i className="fal fa-exclamation-triangle me-2"></i>Error</p>
                    <br/>
                    {list.map( warn =>
                        (
                            warn[0].items.length != 0 ?
                            <div  >
                                <div>- {warn[0].message} <button type="button" className="btn btn-sm btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#detail" aria-expanded="false" aria-controls="detail">View details</button></div>
                                <div className="collapse mt-2 bg-light p-3 rounded" id="detail">{warn[0].items.map( file => (<li className='px-2 mb-2' >{file}</li>))}</div>
                            </div> : 
                            <div>- {warn[0].message}</div>
                        )
                    )}
                <hr />

            </div>)
        }


        /**
         * Return with error or warning or info 
         * 
         * 
         */
        if(sceneResult) return (
            <div className="row">
                <ErrorList />
                <WarningList />
                <InfoList />
            </div>
        )


        /**
         * No Error or warning
         * 
         */
        return <b>Your scene looks good! Please continue.</b>
        
    }
    

    /** Return */
    return (
        <div className="text-light card-body bg-dark px-5">
            <RenderSceneLog sceneResult={data} />
        </div>
    )
}