import env from "../configs/env"

function Logger( Tag ,  log_String , ...log_Values ) {


    /**
     * Anonymous call back function
     */
    window.f = function () {}

    if( 
        env && 
        env.DEBUG &&
        log_String ||
        Tag.indexOf("PRODUCT") != -1
    ){

        Tag = log_String ? Tag : "INFO"
        log_String = log_String ? log_String : Tag
        console.log(
            `%c SRF DEBUG %c|>%c${Tag} => %c${log_String} %c${log_Values}`,
            Tag.indexOf("OK") != -1 || Tag.indexOf("SUCCESS") != -1 ?
                'color:black;background:#98BD33;padding:3px;font-family:"Fira Code"':
                Tag.indexOf("WARN")  != -1 ? 
                'color:black;background:#EBB52D;padding:3px;font-family:"Fira Code"' :
                'color:white;background:#940A2F;padding:3px;font-family:"Fira Code"',
            'color:black;background:#F76E4F;padding:3px;margin-right:5px;font-family:"Fira Code"' ,
            'font-weight:bold;font-family:"Fira Code"',
            'font-weight:regular;color:green;font-family:"Fira Code"',
            'font-weight:bold;font-family:"Fira Code"',
        )

        if(typeof log_String == "object") console.table(log_String)
    }


}

export default Logger  