import React from 'react'
import { MDBBadge } from "mdbreact";
import DataTable from 'react-data-table-component'
import JobsHistoryDetail from '../../components/JobsHistoryDetail/JobsHistoryDetail'
import TaskSubHeader from '../TaskSubHeader/TaskSubHeader';
// https://github.com/jbetancur/react-data-table-component


  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
        borderBottomWidth: '0px !important',
        borderTop: 'rgba(81,81,81,1) 1px solid' 
      }
    },
    headCells: {
      style: {
        paddingLeft: '20px', // override the cell padding for head cells
        paddingRight: '20px',
      },
    },
    cells: {
      style: {
        paddingLeft: '20px', // override the cell padding for data cells
        paddingRight: '20px',
      },
    },
  };

const data = [{ id: 1, scene: 'Conan the Barbarian.c4d', createdAt: '5/10/2021',frames:'0-500',priority:'diamon',cost:'$'+'915' , status:'Done' },
{ id: 2, scene: 'Conan the Barbarian.c4d', createdAt: '5/10/2021',frames:'0-500',priority:'diamon',cost:'$'+'915' , status:'Done' }];
const columns = [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
  },
  {
    name: 'Scene',
    selector: 'scene',
    sortable: true,
  },
  {
    name: 'Created at',
    selector: 'createdAt',
    sortable: true,
    right: true,
  },
  {
    name: 'Frames',
    selector: 'frames',
    sortable: true,
    right: true,
  },
  {
    name: 'Priority',
    selector: 'priority',
    sortable: true,
    right: true,
  },
  {
    name: 'Cost',
    selector: 'cost',
    sortable: true,
    right: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    right: true,
  },
];


class Tasks extends React.Component {
    render() {
      return (
    <div>
        <DataTable
        columns={columns}
        data={data}
        pagination
        theme="dark" customStyles={customStyles}
        highlightOnHover
        defaultSortField="id"
        noDataComponent={<JobsHistoryDetail />}
        title={<div className="d-flex">
          <span className="mt-1">Tasks</span>
        <span className="ms-2 d-none d-lg-block">
          <MDBBadge pill color="dark" className="me-2">Total Frames: 1</MDBBadge>
          <MDBBadge pill color="dark" className="me-2">Completed: 1</MDBBadge>
          <MDBBadge pill color="dark" className="me-2">Suspended: 0</MDBBadge>
          <MDBBadge pill color="dark" className="me-2">Rendering: 0</MDBBadge>
          <MDBBadge pill color="dark" className="me-2">Waiting: 0</MDBBadge>
          <MDBBadge pill color="dark" className="me-2">Failed: 0</MDBBadge>
        </span></div>}
        fixedHeader
        fixedHeaderScrollHeight="500vh"
        overflowY
        actions={
          <TaskSubHeader/>
        
        }
        
        />
    </div>
      )
    }
  };
export default Tasks ;