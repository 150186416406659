import React, { useState } from 'react'
import Tasks from '../../components/Tasks/Tasks'
import StatusJobDetail from '../../components/StatusJobDetail/StatusJobDetail'
import Translate from '../../components/Translate'
import Tippy from '@tippyjs/react'
import QuickTips from '../../components/QuickTips/QuickTips'
import Stores from '../../redux/store'



function JobDetail(props)  {
    const { id } = props.match.params
    const [value, setValue] = useState()
    const refresh = ()=>{
        // re-renders the component
        setValue({})
        
    }
    return (
    
        <div > 
            <div className="row">
                <div className="col-lg-4">
                    <div className="h3 text-light mb-0">Job Detail 
                        <Tippy content={<Translate text="rebuild your working folder"/>} placement="right" >
                            <button className="btn btn-floating p-2 ms-2 text-light"  onClick={refresh}>
                            <i className="fad fa-sync" />
                            </button> 
                        </Tippy>
                        
                    </div>
                    <small>{ Stores.getState().UserStates.email  }</small>
                </div>
                <div className="col-lg-8">
                    <QuickTips />
                </div>
            </div>
            <div className="row my-3">
                <div className="col-lg-8">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-4">Job ID:</div>
                                <div className="col-8">#{id}</div>

                                <div className="col-4">Job name:</div>
                                <div className="col-8">candies</div>

                                <div className="col-4">File path:	</div>
                                <div className="col-8">candies/candies/H_0401.blend</div>

                                <div className="col-4">Frame:	</div>
                                <div className="col-8">0-200</div>

                                <div className="col-4">Submitted at:</div>
                                <div className="col-8">2021-05-14 17:26:58</div>

                                <div className="col-4">Start Render at:</div>
                                <div className="col-8">2021-05-14 17:27:55</div>
                                
                                <div className="col-4">Resolution (W-H):</div>
                                <div className="col-8">1080-1080</div>
                            </div>
                        </div>
                        <div className="col-lg-6" >
                                <div className="row">
                                    <div className="col-4">Selected scene:</div>
                                    <div className="col-8">Scene</div>

                                    <div className="col-4">Camera:</div>
                                    <div className="col-8">Camera</div>

                                    <div className="col-4">Output format:</div>
                                    <div className="col-8">PNG</div>

                                    <div className="col-4">Percentage:</div>
                                    <div className="col-8">100</div>

                                    <div className="col-4">Samples:</div>
                                    <div className="col-8">400</div>

                                    <div className="col-4">Render by:</div>
                                    <div className="col-8">CPU</div>

                                    <div className="col-4">Blender version:</div>
                                    <div className="col-8">Blender 2.82</div>
                                </div>
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-4">
                    <StatusJobDetail />
                    <div className="row">
                        <div className="d-grid gap-2 col-6">
                            <div className="btn btn-dark"><i className="fad fa-pause mx-2"></i>Pause</div>
                            <div className="btn btn-dark"><i className="fad fa-play mx-2"></i>Start</div>
                        </div>
                        <div className="d-grid gap-2 col-6">
                            <div className="btn btn-dark"><i className="fad fa-sync-alt mx-2"></i>Sync output</div>
                            <div className="btn btn-dark"><i className="fad fa-trash mx-2"></i>Delete</div>
                        </div>
                    </div>
                </div>
            

            </div>
            <Tasks />
        </div>
    )
}
export default JobDetail ;