import React from 'react'

export default function WOAC4DSoftwareVersion () {
    return(
        <div className="form-group">
            <label htmlFor="software_version" className="form-control-label semibold">Software Version*</label>
            <select id="software_version" className="form-control srf_require_field">
            <option selected>Choose software version...</option>
            <option value="maya_2022">Cinema 4D 24</option>
            <option value="maya_2022">Cinema 4D 23</option>
            <option value="maya_2022">Cinema 4D 22</option>
            <option value="maya_2022">Cinema 4D 21</option>
            <option value="maya_2022">Cinema 4D 20</option>
            <option value="maya_2022">Cinema 4D 19</option>
            <option value="maya_2022">Cinema 4D 18</option>
            <option value="maya_2022">Cinema 4D 17</option>
            <option value="maya_2022">Cinema 4D 16</option>
            </select>
        </div>
        )
}