import React from 'react'

export default function WOAAESoftwareVersion () {
    return(
   
            <div className="form-group">
              <label htmlFor="software_version" className="form-control-label semibold">Software Version*</label>
              <select id="software_version" className="form-control srf_require_field">
                <option selected>Choose software version...</option>
                <option value="ae_2020">Adobe After Effect CC 2020</option>
                <option value="ae_2019">Adobe After Effect CC 2019</option>
                <option value="ae_2018">Adobe After Effect CC 2018</option>
              </select>
            </div>

        )
}