import React from 'react';
import { Link } from 'react-router-dom'
import Translate from '../../Translate';


/**
 * Navbar left 
 * 
 * @param {String} fullname 
 * @returns {React.Component}
 * 
 */
function NavBarLeft({fullname,credits}) {
    return(
        <div className="header-body-left">
          <ul className="navbar-nav">

            <li className="nav-item dropdown">
              <div className="nav-link profile-nav-link cursor-pointer text-light" title="Profile" data-bs-toggle="dropdown">
                <i className="fad fa-user-circle mx-2" />{fullname}
              </div>
              <div className="dropdown-menu dropdown-menu-left dropdown-menu-big">
                <div className="text-light pt-3 pb-2 ps-4" style={{backgroundColor:"#424242"}}>
                  <h5><Translate text="Hi"/> {fullname} !</h5>
                  <span className="nav-user-cost">
                    <span className="text-light me-2">Your Cost:</span>${credits}
                  </span> 
                </div>
                <div className="list-group list-group-flush">
                  <Link to="/settings" className="list-group-item">Settings</Link>
                  <Link to="/logout" className="list-group-item text-danger">Sign Out!</Link>
                </div>
              </div>
            </li>

            
            
            <li className="d-sm-inline d-none ms-5">
              <span className="nav-user-cost">
                <span className="text-light me-2">Your Cost:</span>
                ${credits}
              </span>
            </li>
          </ul>
        </div>)
}

export default NavBarLeft