import React, { useState } from 'react'

export default function ArnoldOutputJPGE({}) {

    const [changeQualityImage,setChangeQualityImage] = useState("100")
    const [changeSmoothingImage,setChangeSmoothingImage] = useState("100")
    return (
    <div className="card card-body">
        <legend>JPGE Image Control:</legend>
        <div className="form-group row">
            <div className="col-lg-10">
                <div className="row">
                    <label className="col-lg-2"></label>

                    <div className="form-group col-lg-5">
                        <div className="form-group">
                            <label htmlFor="qualityImage" className="d-block">
                                Quality<span className="text-danger">*</span>
                                <div className="float-end">{changeQualityImage}</div>    
                            </label>
                            <input type="range" value={changeQualityImage} onChange={e => setChangeQualityImage(e.target.value)}  className="custom-range custom-range-success" id="qualityImage" />
                        </div>
                    </div>
                    <div className="form-group col-lg-5">
                        <div className="form-group">
                            <label htmlFor="smoothingImage" className="d-block">
                                Smoothing<span className="text-danger">*</span>
                                <div className="float-end">{changeSmoothingImage}</div>    
                            </label>
                            <input type="range" value={changeSmoothingImage} onChange={e => setChangeSmoothingImage(e.target.value)} className="custom-range custom-range-success" id="smoothingImage" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
    )
}