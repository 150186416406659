

import React from 'react'
import FrameRange from '../../FrameRange'


export default function MaxRenderTypeAndFrame({
    renderType,
    startFrame ,
    endFrame ,
    incrementalStep,
    updateRenderType = window.f ,
    updateStartFrame = window.f ,
    updateEndFrame = window.f , 
    updateIncrementalStep = window.f 
}) {
    return(
        <div>
            <div className="form-group row">
                <div className="col-lg-10">
                    <div className="row">
                        <label htmlFor="inputPassword" className="col-lg-2 col-form-label my-auto">Render Type:</label>
                        <div className="col-lg-10 nav nav-pills p-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                            <a className=" nav-item nav-link active" id="v-multi-frames-tab" data-bs-toggle="pill" href="#v-multi-frames" role="tab" aria-controls="v-multi-frames" aria-selected="true">
                                <p>Animation</p>
                                <small htmlFor="Animation">Multi-Frame</small>
                            </a>
                        
                            <a className=" nav-item nav-link" id="v-pills-single-frame" data-bs-toggle="pill" href="#v-single-frame" role="tab" aria-controls="v-single-frame" aria-selected="false">
                                <p>Single</p>
                                <small htmlFor="Single">Single-Frame</small>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab-content my-auto" id="v-pills-tabContent">
                <div className="tab-pane fade show active" id="v-multi-frames" role="tabpanel" aria-labelledby="v-multi-frames-tab">
                    
                {/** Start frame range */}
                    <div className="form-group row">
                    <div className="col-lg-10">
                        <div className="row">
                            <label className="col-lg-2 col-form-label my-auto">Frame Range:</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-group m-0">
                                            <label htmlFor="start_frame" >Start Frame <span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" id="start_frame" placeholder="Start Frame"  value={startFrame} onChange={e => updateStartFrame(parseInt(e.target.value))}  />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group m-0">
                                            <label htmlFor="end_frame" >End Frame <span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" id="end_frame" placeholder="End Frame" value={endFrame} onChange={ e => updateEndFrame(parseInt(e.target.value)) } />
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-4">
                                        <div className="form-group m-0">
                                            <label htmlFor="incremental_step" >Incremental Step <span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" id="incremental_step" placeholder="Incremental Step" value={incrementalStep} onChange={e => updateIncrementalStep(parseInt(e.target.value))} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
                </div>       

                <div className="tab-pane fade" id="v-single-frame" role="tabpanel" aria-labelledby="v-single-frame-tab">
                    <div className="form-group row">
                        <div className="col-lg-10">
                            <div className="row">
                                <label htmlFor="start_frame" className="col-lg-2 col-form-label">Frame :</label>
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="form-group col-lg-4">
                                            <input type="number" className="form-control" id="start_frame" placeholder="Start Frame" value={startFrame} onChange={e => updateStartFrame(parseInt(e.target.value))} />
                                        </div>
                                        <div className="form-group col-lg-8">
                                            <div className="custom-control custom-checkbox custom-checkbox-success custom-checkbox-success">
                                                <input type="checkbox" className="custom-control-input" id="renderTiles"  />
                                                <label className="custom-control-label" htmlFor="renderTiles" style={{userSelect: "none" }}>
                                                    Render Tiles (Supply 30 render machines to render at the same time). Note: Render Elements is not support on Render Tiles mode.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )   
}