import React from 'react'

export default function WOABlenderEngineVersion () {
    return(
        <div className="form-group">
            <label htmlFor="render_engine_version" className="form-control-label semibold">Render Engine Version*</label>
            <select id="render_engine_version" className="form-control srf_require_field">
              <option selected>Choose engine version...</option>
              <optgroup label="Standard">
                  <option value="standard">Standard</option>
              </optgroup>
              <optgroup label="Cycles">
                  <option value="cycles_cpu">Cycles (CPU)</option>
              </optgroup>
              <optgroup label="EEVEE">
                  <option value="eevee_cpu">EEVEE (CPU)</option>
              </optgroup>
            </select>
        </div>
        )
}