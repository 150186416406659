import React from 'react'
import Account from '../../components/Settings/Account/Account';
import Invoice from '../../components/Settings/Invoice/Invoice';
import Profile from '../../components/Settings/Profile/Profile';

function Settings(props) {
    
      return (
        <div >
          <div className="h3 text-light">
            Settings
          </div>
          <div className="page-body">
            <div className="nav nav-pills my-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <a className="nav-item nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="true">Profile</a>
              <a className="nav-item nav-link" id="v-pills-invoice-tab" data-bs-toggle="pill" href="#v-pills-invoice" role="tab" aria-controls="v-pills-invoice" aria-selected="false">Invoice</a>
              <a className="nav-item nav-link" id="v-pills-account-tab" data-bs-toggle="pill" href="#v-pills-account" role="tab" aria-controls="v-pills-account" aria-selected="false">Account</a>
            </div>
            <div className="tab-content" id="v-pills-tabContent">
              <Profile />
              <Invoice />
              <Account />
            </div>
          </div>
        </div>
      )
}
export default Settings ;