import React, { useEffect } from 'react'
import { BrowserRouter as Router, Link, Redirect, Route, Switch, useHistory,useParams } from 'react-router-dom'
import DropboxFileManager from "../../components/Dropbox/FileManager"
import FileManagerLayout from '../../layouts/FileManager';
import Layout from '../../components/Spaces/Layout'
import { Dropbox } from "dropbox"
import { useState} from 'react';
import Logger from '../../utils/debug';
import env from '../../configs/env';
import FolderManager from '../../components/FolderManager/FolderManager';
import axios from 'axios';
import GoogleApis from '../../utils/GoogleApis';


function DropboxFileManagerView() {

  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [folderData, setFolderData] = useState([])


  /**
   * initial components
   * 
   */
  useEffect(() => {
    
    /**
     * 
     * 
     */
    fetch()

  }, [])


  /**
   * Pre fetch data.
   * 
   */
  function preFetch() {

  }

  /**
   * use SDK or axios to get get data
   * 
   * 
   */
  function fetch(path = "" ) {


    /**
     * Set loading states
     * Remove folder data to empty,
     * if folder data not empty,
     * when user go to sub folder,
     * loading skeleton component and current folder data component will show together
     */
     setIsLoading(true)
     setFolderData([])

    /**
    * Start call to google drive api
    * 
    */

    let gapi = new GoogleApis ;
    
    gapi
    .listObjectById( '1ihC0qAuitN16jLBPDyaTT8vvPsqns8Zb' )
    .then(res => {
      
      /** Get files success */
      if(res.data.files){

        let data = res.data.files
        Logger("GOOGLE_DRIVE_API:SUCCESS", data)
        reduce(data)

      }
    })
    .catch( err => {

      /**
       * Check is token expire
       */
      if( err.response&&  err.response.data.error.code == 401 ) {
        
        /** Token expire warning */
        Logger("GOOGLE:FAIL", "Access Token Expired !! ")

        /**  */
        return gapi.refreshAccessToken()
        .then( res => {

          /** Set access token  */
          gapi.accessToken = res.data.access_token

          gapi.listObjectById('1ihC0qAuitN16jLBPDyaTT8vvPsqns8Zb')
          .then( res => {

            Logger("GOOGLE_DRIVE_API:SUCCESS", res.data.files)
            reduce(res.data.files)
    
          })

        })

      }

      return reduce([])

      

    })

  }

  /**
   * Reduce data from fetch to FolderManager readable
   * 
   */
  function reduce(GoogleDriveContents) {

      
      /**
       * Map data from Dropbox content type to Srf FileList Type
       * 
       */
      const fileManagerReadable = GoogleDriveContents.map((item, index) => ({
        id: index,
        name: item.name,
        Key: item.name,
        path: history.location.pathname.replace('google-drive', '') + item.name ,
        size: item.size ? item.size : "Unknown",
        fileType: item.mimeType.split(".").pop() == "folder" || item.mimeType.split(".").pop() == "file" ? item.mimeType.split(".").pop() : "file",
        modified: item.modifiedTime,
        fileExtension: item.name.split(".").pop(),
        sceneHistory: ''
      }))

      /**
       * Debug
       */
      Logger("DROPBOX_LIST:SUCCESS", fileManagerReadable)

      /**
       * Set folder data 
       */
      setFolderData(fileManagerReadable)

      /**
       * Set loading states
       */
      setIsLoading(false)
  }


  /**
   * Return components
   * 
   */
  return (
    <FileManagerLayout>
      <Router basename="google-drive" >
        <Switch>
          
          <Route path="/:path(.*)" exact strict sensitive >
            <FolderManager onUrlChanged={ path => { fetch(path)} } { ...{ data : folderData , isLoading, gridStyle : "gridView"  } } />
          </Route>

        </Switch>
      </Router>
    </FileManagerLayout>
  )
}
export default DropboxFileManagerView;