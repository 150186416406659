import React from 'react'

export default function WOAMaxVrayOptions() {
    return (
        <div className="mod-animation-prepass-vray d-block d-lg-flex mt-2" >
            <label id="render_type_label" className="form-label me-2  my-auto">V-Ray Options:</label>
            <div className="form-check">
                <input type="checkbox" id="check-prepass-vray" className="form-check-input" />
                <label htmlFor="check-prepass-vray" className="form-check-label">GI: Mode Animation Prepass/Rendering
                (<a className="text-success" href="https://support.superrendersfarm.com/hc/en-us/articles/360040794194-Animation-prepass-rendering-with-V-Ray-Next" target="_blank">see more</a>)
                </label>
            </div>
        </div>
    )
}