import React  from 'react'

export default function WOAStartFrame({}) {

    return (
        <div className="form-group">
            <label id="start_frame_label" className="form-label semibold">Start Frame*</label>
            <div className="form-control-wrapper">
            <input id="start_frame" type="number" className="form-control srf_require_field" placeholder="Start From" />
            <small className="text-muted" />
            </div>
        </div>
    )
}