import { MDBBtn } from 'mdbreact'
import React from 'react'


function NavBarRight({}) {
    return (
        <div className="header-body-right">
          <ul className="navbar-nav">
            <li className="nav-item">
              <MDBBtn color="success" size="sm" className="mx-2 d-none d-lg-block text-dark" data-bs-toggle="modal" data-bs-target="#credit-calculatorModal">
                <i className="fal fa-calculator me-2" />Cost Calculator
              </MDBBtn>
            </li>
            <li className="nav-item">
              <MDBBtn color="dark" size="sm" className="d-none d-lg-inline" data-bs-toggle="modal" data-bs-target="#sync-app">
                <i className="fad fa-download"></i> Download Sync App
              </MDBBtn>
            </li>
            <li className="nav-item dropdown">
              <a href="#" className="nav-link nav-link-notify" title="Notifications" data-bs-toggle="dropdown">
                <i className="ti-bell" />
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-big">
                <div className="dropdown-scroll">
                  <ul className="list-group list-group-flush">
                    <li>
                      <a className="list-group-item d-flex hide-show-toggler">
                        <div>
                          <figure className="avatar me-3">
                            <span className="avatar-title bg-secondary-bright text-secondary rounded-circle">
                              <i className="ti-server" />
                            </span>
                          </figure>
                        </div>
                        <div className="flex-grow-1">
                          <p className="mb-0">
                            Your storage space is running low!
                            <i title="Mark as unread" data-bs-toggle="tooltip" className="hide-show-toggler-item fa fa-check font-size-11 position-absolute right-0 top-0 me-3 mt-3" />
                          </p>
                          <span className="text-muted small">4 sec ago</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a className="list-group-item d-flex hide-show-toggler">
                        <div>
                          <figure className="avatar me-3">
                            <img src="../../assets/media/image/user/man_avatar4.jpg" className="rounded-circle" alt="avatar" />
                          </figure>
                        </div>
                        <div>
                          <p className="mb-0">
                            <span>Jonny Richie</span> uploaded new
                            files
                            <i title="Mark as read" data-bs-toggle="tooltip" className="hide-show-toggler-item fa fa-circle-o font-size-11 position-absolute right-0 top-0 me-3 mt-3" />
                          </p>
                          <span className="text-muted small">20 min ago</span>
                        </div>
                      </a>
                    </li>
                  
                    <li>
                      <a className="list-group-item d-flex hide-show-toggler">
                        <div>
                          <figure className="avatar me-3">
                            <span className="avatar-title bg-info-bright text-info rounded-circle">
                              <i className="fa fa-cloud-upload" />
                            </span>
                          </figure>
                        </div>
                        <div className="flex-grow-1">
                          <p className="mb-0">
                            Upgrade plan
                            <i title="Mark as unread" data-bs-toggle="tooltip" className="hide-show-toggler-item fa fa-check font-size-11 position-absolute right-0 top-0 me-3 mt-3" />
                          </p>
                          <span className="text-muted small">45 sec ago</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a className="list-group-item d-flex hide-show-toggler">
                        <div>
                          <figure className="avatar me-3">
                            <span className="avatar-title bg-success-bright text-success rounded-circle">
                              <i className="ti-share" />
                            </span>
                          </figure>
                        </div>
                        <div className="flex-grow-1">
                          <p className="mb-0">
                            A file has been shared
                            <i title="Mark as unread" data-bs-toggle="tooltip" className="hide-show-toggler-item fa fa-check font-size-11 position-absolute right-0 top-0 me-3 mt-3" />
                          </p>
                          <span className="text-muted small">58 sec ago</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            
          </ul>
        </div>)
}

export default NavBarRight