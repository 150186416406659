import React from 'react'
import DropzoneToUpload from '../DropzoneToUpload/DropzoneToUpload';

class NoData extends React.Component {
    render() {
      return (
        <div className="card-body d-flex justify-content-center">
          <div className="mt-5 text-center"
            style={{ cursor: "default", userSelect: "none" }}
          >
            <h1 className="text-light">Your spaces is empty !</h1>
            <p className="mb-4">Please package your project and upload to your SRF Spaces.<br/>
            </p>
            <DropzoneToUpload />
          </div>
        </div>
      )}
}
export default NoData ;