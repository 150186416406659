import React from 'react'
import { useParams } from 'react-router-dom'


export default function ScenePath({path}) {
    
    return(
        <div className="form-group row">
            <div className="col-lg-10">
                <div className="row">
                    <label htmlFor="scene-path" className="col-lg-2 col-form-label">Scene Path:</label>
                    <div className="col-lg-10 my-auto">
                        <span className="text-break col-form-label">{path}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}