import React, { useState } from 'react'
import jwt from 'jsonwebtoken'
import axios from 'axios'
import env from '../../../configs/env'
import Logger from '../../../utils/debug'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import swal from 'sweetalert'

function Profile({fullname}) {
  /**
   * Get user's full name from jwt
   * 
   */
  const UserName = jwt.decode(localStorage.getItem('token')) ? jwt.decode(localStorage.getItem('token')).full_name : ""

  /**
   * 
   */
  const [profileFullName, SetprofileFullName] = useState(fullname)

  const history = useHistory()

  function updateFullName(e) {
    /**
     * Stop default event handle
     * 
     */
    e.preventDefault()

    /**
     * Debug
     * 
     */
    Logger("CHANGE_USERNAME:CLICK:OK", "Update username to", profileFullName)


    /**
     * If nothing was changed 
     */
    if(profileFullName == UserName ) {

      /**
       * TODO : Handle alert notification here!
       * 
       */
      return alert("Nothing was changed ! ")
    }


    /**
     * 
     * 
     */
    axios.put(`${env.APP_API}/user/me/update-profile`,
      { full_name : profileFullName},
      {
        headers : {
          Authorization : `Bearer ${localStorage.getItem("token")}`
        }
      })
    .then( res => {

      /**
       * Debug
       * 
       */
      Logger("CHANGE_USERNAME:RESPONSE:OK",res.data )

      if(res.data.status == "success") {
        /**
         * Debug
         * 
         */
        Logger("CHANGED_USERNAME:RESPONSE:SUCCESS", "Changed username to", profileFullName)
        Logger("DONE:SUCCESS" , "You will be logout in 5 seconds")

        return swal({
          title : "Done"
        })

        /**
         * Dispatch store to change username
         * 
         */
        // setTimeout(() => {
          
        //   /**
        //    * Debug
        //    */

        //   localStorage.clear()

        //   /**
        //    * Go to home page
        //    * 
        //    */
        //   history.push("/")

        // }, 5000);

      }
    })
    .catch(err => {
      /**
       * Debug
       * 
       */
      Logger("CHANGE_USERNAME:RESPONSE:FAIL", "API Return an error!!")

      /**
       * TODO : Handle failure notification here!
       */
      alert("API Error! Nothing was changed! ")

    })

  }


  return (
    <div className="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
      <div className="mb-4">
        <h4>Account Information</h4>
      </div>
      <div className="border-bottom mb-4"></div>
      <div className="row">
        <div className="col-md-6">
          <form onSubmit={ e => {updateFullName(e)}} >
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Your full Name</label>
                  <input type="text" className="form-control" onChange={e => SetprofileFullName(e.target.value)} value={profileFullName} />
                </div>
                
              </div>
            </div>
            <button type='submit' className="btn btn-primary">Save Changes</button>
          </form>
        </div>
      </div>
    </div>
  )
}
/**
 * Add Redux state to props
 * 
 * 
 */
export default connect( ({UserStates}) => ({
  fullname : UserStates.fullname
}))(Profile) ;