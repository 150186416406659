import axios from 'axios';
import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import {join} from "path"
import env from "../../configs/env.js"
import Logger from '../../utils/debug.js';
import JsonWebToken from 'jsonwebtoken'
import AppNotification from '../../components/AppNotification/AppNotification.js';
import "./Login.scss"
class Login extends React.Component {

  /**
   * Intiail states
   * 
   */
  state = {
    email  : "",
    password : "",
    ApiHttpResponse : {},
    onError : {}
  }


  /**
   * Import props
   * 
   * @param {React.Props} props 
   * 
   * 
   */
  constructor(props) {
    super(props);
    Logger( 'LOGIN' ,"Login Page")
  }
  

  /**
   * 
   * 
   */


  /**
   * Store logged in user data
   * 
   * @param {JsonWebToken} 
   * 
   */
  storeLoginData(){

    /**
     * store data to localStorage
     * 
     * 
     */
    localStorage.setItem( "token", this.state.ApiHttpResponse.token )
    localStorage.setItem( "expired_at", Date.now() + this.state.ApiHttpResponse.expires_in )
    localStorage.setItem( "token_type" , this.state.ApiHttpResponse.token_type )


  }

  /**
   * Check is form data 
   * 
   */
  verifyLogin() {

    // return console.log()
    axios.post( `${env.APP_API}/user/login`,{ email : this.state.email, password : this.state.password })
      .then( response => {
      
        /**
         * get jwt from api
         * Store to State first
         * 
         */
        if( response.data.token ){
          // Logger('LOGIN', response.data)
          Logger('JWT', JsonWebToken.decode(response.data.token) )
          this.setState({ ApiHttpResponse : response.data })
        }

        /**
         * Store login data session
         * 
         */
        this.storeLoginData()


        /**
         * If login invalid then go to dashboard
         * 
         */
        this.props.history.push("/")

      })
      .catch(res => {
        this.setState({
          onError : {
            description : "Cannot call to api ! ",
            title : "Login Error !"
          }
        })
        Logger('LOGIN RESPONSE', "Login handle")
      })

  }

  validateInput() {
    
  }

  render() {
    return (
      <div className="h-100">
      
      <div className="bg-login"></div>
      <div className="logoLogin">
      { (this.state.onError.description) ? <AppNotification onDone={ () => this.setState({ onError : {} }) } title={this.state.onError.title} description={this.state.onError.description} /> : <div></div>}

        <a href="https://superrendersfarm.com/" ><img src="https://statics.superrendersfarm.com/app/assets/media/image/logo-001.png" alt="Homepage" /></a>
      </div>

        <div className="h-100 align-items-center d-flex justify-content-center fade-in">
        
          <div className="login bg-dark rounded p-3 col-xl-3 col-lg-6 col-md-6 shadow" >
            <div className="card-body">
              <div className="text-center text-muted">WELCOME BACK</div>
              <div className="text-center h4">
                  Login into your account
              </div>

              <div className="form-5 clearfix shadow my-4">
                <div className="email-input">
                <label className="label-email">Email</label>
                <input className="form-control" type="email" value={this.state.email} onChange={  event => this.setState({ email : event.target.value }) } id="email" name="email" placeholder="example@superrendersfarm.com" />
                
                </div>
                <div className="password-input">
                <label className="label-password">Password</label>
                <input className="form-control" type="password" value={this.state.password} onChange={  event => this.setState({ password : event.target.value }) }  name="password" id="password" placeholder="•••••••••" /> 
            
                </div>
                      
              </div>  
                
              <div className="row">
                <div className="col-6">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="defaultChecked2" />
                    <label className="custom-control-label text-muted cursor-pointer" htmlFor="defaultChecked2">Remember me</label>
                  </div>
                </div>
                <div className="col-6 text-right">
                  <Link to="/forgot-password" className="text-muted d-block d-lg-flex justify-content-end">Forgot Password</Link>
                </div>
              </div>
              <button onClick={ () => { this.verifyLogin() } } className="btn btn-success btn-block text-dark login-button shadow my-4">
                <span>Login</span>
              </button>   
                <Link  to="/sign-up">
                    Sign up
                </Link> 
                <Link className="float-end" to="/resend-email" >Resend verification email</Link>
            </div>
          </div>
      </div>
  </div>
  )}
}
export default withRouter(Login) ;
