import React from 'react'

export default function WOAMayaSoftwareVersion () {
    return(
        <div className="form-group">
            <label htmlFor="render_engine_version" className="form-control-label semibold">Render Engine Version*</label>
            <select id="render_engine_version" className="form-control srf_require_field">
            <option selected>Choose engine version...</option>
            <optgroup label="Arnold For Maya" className="text-success"></optgroup>
            <optgroup label="CPU">
                <option value="arnold4_0_1_cpu">MAXtoA 4</option>
                <option value="arnold3x_cpu">MAXtoA 3.x</option>
                <option value="arnold2x_cpu">MAXtoA 2.x</option>
            </optgroup>
            <optgroup label="GPU">
                <option value="arnold4_0_1_gpu">MAXtoA 4</option>
            </optgroup>
            <optgroup></optgroup>
            <optgroup label="V-Ray"  className="text-success"></optgroup>
            <optgroup label="CPU">
                <option value="vray_5_cpu">V-Ray 5</option>
                <option value="vray_4_1_cpu">V-Ray 4.1</option>
                <option value="vray_3_cpu">V-Ray 3.x</option>
                <option value="vray4phoenix4_cpu">V-Ray 4 Phoenix 4</option>
                <option value="vray3phoenix3_cpu">V-Ray 3 Phoenix 3</option>
            </optgroup>
            <optgroup label="GPU" >
                <option value="vray_5_gpu">V-Ray 5</option>
                <option value="vray_4_1_gpu">V-Ray 4.1</option>
            </optgroup>
            <optgroup></optgroup>
            <optgroup label="Maya"  className="text-success"></optgroup>
            <optgroup label="CPU">
                <option value="maya_soft_cpu">Maya Software</option>
                <option value="maya_soft2_0_cpu">Maya Software 2.0</option>
                <option value="maya_vector_cpu">Maya Vector</option>
            </optgroup>
            <optgroup></optgroup>
            <optgroup label="MentalRay"  className="text-success"></optgroup>
            <optgroup label="CPU">
                <option value="mentalray_cpu">MentalRay</option>
            </optgroup>
            <optgroup></optgroup>
            <optgroup label="Redshift"  className="text-success"></optgroup>
            
            <optgroup label="GPU">
            <option value="redshift2_0_gpu">Redshift 2.0</option>
            </optgroup>
            </select>
        </div>
        )
}