/**
 * Listen change from socket.io to hot update status.
 * 
 */
const initialState = {

    /**
     * List analyzing scenes,
     * 
     */
    AnalyzeScenes : null ,

    /**
     * Rendering job list
     * 
     */
    RenderingJobs : [
        {
            id : 1 , // Rendering job id
            sceneId : 22 , // Analyze scene id 
            priority : 'bronze' , // Priority level of current job
            frames : { // job frame data
                startFrame : 1 ,
                endFrame : 1 , 
                stepFrame : 1 ,
            },
            camera : { // Camera infomation
                name : "Leftview",
                layer : 'leftview'
            },
            status : "rendering" , // Quick status of render job
            processorEngine : 'cpu', // Render by CPU or GPU
            usedCredits : 15020 , // Credits used by render job
            creditEstimate : 122.22, // Estimate credits needed
            timeEstimate : 2444, // Estimate tim in seconds
            renderNodes : 100 , // Number of slave are rendering
            jobPercentage : 12.54 , // Complete job percentage
            output : { // Output of job configuration
                cloudType : 'gDrive', // Serve cloud type : gDrive or dbx or awss3
                path : 'srf_webhaidang_output/outputs' , // output path
                folderId : "asdasdaosidmaoisdmaoisdm" , // Cloud folder Id
                folderName : "SrfJobOutput_01" , // Output folder name 
            }, 
            createdAt : Date.now(), // Submitted job timestamp
            updatedAt : Date.now(), // Socket emit updated time 
        }
    ],

    /**
     * 
     * 
     */
}

export default (state = initialState, action) => {
  switch (action.type) {
    /**
     * 
     */
    case 'UPDATE_JOB':
      return { ...state };
      
    /**
     * 
     */
    case "UPDATE_SCENE_ANALYZE" : 
      return {...state, AnalyzeScenes : action.AnalyzeScenes }
      
    /**
     * 
     */
    case 'RELOADED_ANALYZE_SCENE' :
      return {...state, AnalyzeScenes : null }
    /**
     * 
     */
    default:
      return state

  }
};
