import React from 'react'

export default function QuickTips({}) {
    return (        
        <div className="d-none d-xl-block float-end ms-auto">
            <span>Quick tips:</span>
            <a href="#" className="btn btn-dark btn-sm mb-2 ms-2 ">
                <img className="me-2" src="../../assets/media/image/max.png" width="15px" height="15px" /> 3DS Max
            </a>
            <a href="#" className="btn btn-dark btn-sm mb-2 ms-2 ">
                <img className="me-2" src="../../assets/media/image/c4d.png" width="15px" height="15px" /> Cinema 4D
            </a>
            <a href="#" className="btn btn-dark btn-sm mb-2 ms-2 ">
                <img className="me-2" src="../../assets/media/image/blender.png" width="15px" height="15px" /> Blender
            </a>
            <a href="#" className="btn btn-dark btn-sm mb-2 ms-2 ">
                <img className="me-2" src="../../assets/media/image/maya.png" width="15px" height="15px" /> Maya
            </a>
            <a href="#" className="btn btn-dark btn-sm mb-2 ms-2 ">
                <i className="fab fa-youtube me-2 text-danger" /> Videos
            </a>
        </div>
        )
}