import React from 'react';
import QuickTips from '../../components/QuickTips/QuickTips';
import {   MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBLink} from 'mdbreact'


class Plugins extends React.Component {
    state = {
      activeItemPills: '1'
    };
  
    togglePills = tab => () => {
      const { activePills } = this.state;
      if (activePills !== tab) {
        this.setState({
          activeItemPills: tab
        });
      }
    };
  
    render() {
    const { activeItemPills } = this.state;
      return (
        <div >
          <div className="d-lg-flex d-block">
            <span className="my-auto h3 mb-0">Download SuperRenders Plugin</span>
            <div className="ms-auto">
              <QuickTips />
            </div>
          </div>
          <div className="page-body"> 
            <div className="card-body bg-dark text-white text-break rounded mb-2">
              <p className="my-2">- SuperRenders Plugin helps to detect 200+ common issues in your 3ds Max and C4D projects.</p>
              <p className="my-2">- After verified, our plugin will upload the project to your SRF Spaces.</p>
              <p className="my-2">- SuperRenders Plugin using s3.exe (included in the plugin) to upload files. If your Windows Security blocks the software, please allow it.</p>
            </div>
            <div>
                <MDBNav className='nav-pills'>
                  <MDBNavItem>
                    <MDBLink to='#' active={activeItemPills === '1'} onClick={this.togglePills('1')} link >
                      Autodesk 3ds Max 
                    </MDBLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBLink to='#' active={activeItemPills === '2'} onClick={this.togglePills('2')} link>
                      Cinema 4D 
                    </MDBLink>
                  </MDBNavItem>
                </MDBNav>
                <MDBTabContent activeItem={activeItemPills} className="mt-2">
                  <MDBTabPane tabId='1'>
                  <div className="text-light">
                  <p className="my-2" >After open Super Renders account, you can download and install 3ds Max plug-in, which helps to package and upload your project to Super Renders cloud.</p>
                  <p className="my-2" >To install, please following 3 easy steps as below:</p>
                  <h5 className="mt-2"><b>Step 1. Download mzp file (<a href="https://superrendersfarm.com/account/download/3dsmax-plugin" >download superrender_3dsmax_v10.mzp</a>)</b></h5>
                  <p className="my-2" >Please download mzp file (Max Zip Package file) then install Super Renders plug-in for your 3ds Max. The SuperRenders plug-in is compatible with 3ds Max 2015 to latest version</p>

                  <h5 className="mt-2"><b>Step 2. Open Scripting &gt; Run Script ... to install</b></h5>
                  <p className="my-2" >From your 3ds Max, open Scripting &gt; Run Script ... then select superrender_3dsmax_v10.mzp file, which downloaded above (The plug-in will require to restart 3ds Max).</p>
                  <p className="my-2" ><img src="https://statics.superrendersfarm.com/superrenders/images/super-renders/app/download-superrenders-3ds-max.png" className="img-fluid" alt="Download SuperRenders software"/></p>
                  <p className="my-2" >&nbsp;</p>
                  <h5 className="mt-2"><b>Step 3. Go to Rendering &gt; Render with Super Renders ... to Package and Upload</b></h5>
                  <p className="my-2" >Open your project/scene in the 3ds Max program you use, you can find our SuperRenders plugin in your 3ds Max software: Rendering &gt; Render with Super Renders</p>
                  <p className="my-2" ><i>SuperRenders Plugin using s3.exe (included in the plugin) to upload files. If your Windows Security blocks the software, please allow it.</i></p>
                  <p className="my-2" ><img src="https://statics.superrendersfarm.com/superrenders/images/super-renders/app/3ds-plugin.png" className="img-fluid" alt="3ds Max plugin" /></p>
                  <p className="my-2" >&nbsp;</p>
              </div>
                  </MDBTabPane>
                  <MDBTabPane tabId='2'>
                    <div className="text-light">
                        <p className="my-2" >After open Super Renders account, you can download and install Cinema 4D plug-in, which helps to package and upload your project to Super Renders cloud.</p>
                        <p className="my-2" >To install, please following 3 easy steps as below:</p>
                        <h5 className="mt-2"><b>Step 1. Download superrenders.zip file (<a href="https://superrendersfarm.com/account/download/c4d-plugin">download superrenders.zip</a>)</b></h5>
                        <p className="my-2" >Please download superrenders.zip file then install Super Renders plug-in for your Cinema 4D. The SuperRenders plug-in is compatible with R17 to latest version</p>
                        <h5 className="mt-2"><b>Step 2. Extract zip file to AppData\Roaming\MAXON\[Cinema 4D version]\plugins\ ... to install</b></h5>
                        <p className="my-2" >- To find your preference folder, from your Cinema 4D, select Edit &gt; Preferences .. then select Open Preferences Folder...
                        <br />
                        - Make sure you extract zip file to AppData\Roaming\MAXON\[Cinema 4D version]\plugins\ .. see picture below
                        <br />
                        - Restart your Cinema 4D application.
                        </p>
                        <p className="my-2" ><img src="https://statics.superrendersfarm.com/superrenders/images/super-renders/app/c4d-plugin-location.png" className="img-fluid" alt="Download SuperRenders software"/></p>
                        <p className="my-2" ><i>P/s: to find your preferences folder:</i></p>
                        <p className="my-2" ><img src="https://statics.superrendersfarm.com/superrenders/images/super-renders/app/c4d-preferences-folder.png" className="img-fluid" alt="Download SuperRenders software"/></p>
                        <p className="my-2" >&nbsp;</p>
                        <h5 className="mt-2"><b>Step 3. Go to Plugins &gt;Super Renders Farm... to Package and Upload</b></h5>
                        <p className="my-2" >Open your project/scene in the Cinema 4D program you use, you can find our SuperRenders plugin in your Cinema 4D software: Plugins &gt; Super Renders Farm</p>
                        <p className="my-2" ><i>SuperRenders Plugin using superrendersfarm.exe (included in the plugin) to upload files. If your Windows Security blocks the software, please allow it.</i></p>
                        <p className="my-2" ><img src="https://statics.superrendersfarm.com/superrenders/images/super-renders/app/c4d-inside-plugin.png" className="img-fluid" alt="3ds Max plugin"/></p>
                        <p className="my-2" >&nbsp;</p>
                    </div>
                  </MDBTabPane>
                </MDBTabContent>
            </div>
          </div>
        </div>
      )
    }
}
export default Plugins ;