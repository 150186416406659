import React, { useState } from 'react'


export default function DashboardStatus({Status}) {
    const [toggleHover, setToggleHover] = useState(0)
  
    return (
        <div className="row" onMouseEnter={() => setToggleHover(1)} onMouseLeave={() => setToggleHover(0)}>
            <div className="col-lg-2"></div>
            {Status==="AnalyzeFailed"||Status==="AnalyzeCompleted" ?
            <div className="col-lg-10 my-auto">
                <div className="row">
                    <div className="col-6">
                        {Status==="AnalyzeFailed"? <span className="badge badge-danger font-size-14">{Status}</span>
                        :
                        <span className="badge badge-success text-dark font-size-14">{Status}</span>}
                    </div>
                    <div className="col-6">
                        Time spent: 2 min
                    </div>
                </div>
            </div>
            :
            <div className="col-lg-10 my-auto" style={{cursor:"default"}} >
                <span>{Status}<span className="float-end">{toggleHover=== 1 ? "Total cost: $9999":"Used cost: $2500.2"}</span></span>
                <div className="progress">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" 
                aria-valuemin="5" aria-valuemax="100" style={{width: "50%",minWidth:"1%" }} ></div>
                </div>
                <small className="mt-1 text-muted">{toggleHover===1 ? "Time left: 4h 31 min":"201 nodes rendering"} <span className="float-end mt-1">16%</span></small>
                    
            </div>
            }
            
        </div>
    )
}