export default {

    /*** App Domain */
    APP_DOMAIN: "",

    /*** Config debug mode */
    DEBUG:true, 
    
    /*** Configure home base url */
    APP_HOME_URL: "" ,

    /*** Rest api configuration */
    APP_API:"https://olapi.superrendersfarm.com/api" ,
    
    /*** AWS SDK Configuration **/
    AWS_POOL:'us-east-1:d8962b7f-a7cb-40be-a288-29bb0a037531' ,
    AWS_REGION:'us-east-1' ,
    AWS_S3_BUCKET:'srf-official-input' ,
    AWS_S3_ACCELERATE:false ,

    /** Dropbox access token */
    DROPBOX_ACCESS_TOKEN : "RmhuIZXPeowAAAAAAAAAAfzINAQIj_MQQ1kR3msd48siT52k8dRzrKw7S0n07_oI", 

    /*** Authenticated for :* Scope : "drive.readonly drive.metadata drive.file drive.metadata.readonly" */
    GOOGLE_AUTHORIZATION_CODE :  "4/0AY0e-g41Y7qZ6NGP276aJ43kYQXjI-43dNXSgmLbLgcySWI_ibkvUu62pub-A3Phz34pAQ",

    /*** Google drive will automatically revoke refresh tokens after 24h. */
    GOOGLE_REFRESH_TOKEN : '1//044XVQaThIb1CCgYIARAAGAQSNwF-L9IryJIadxCz9tl9T1v8Y47q68epmT1XjvkdS1oiqgio9ggJARnodh_bNgP0Re3shrLExP0',
    
    /** Google Clientid */
    GOOGLE_CLIENT_ID : '509418164235-ngmbi5pr1h225rli07k9b54thu43jtv4.apps.googleusercontent.com',

    /** Google Client secret */
    GOOGLE_CLIENT_SECRET : "e98uXI6foOe9X-ULXGUKaoyP"
    
}