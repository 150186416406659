import React from 'react'

export default function WOABlenderSoftwareVersion () {
    return(
        <div className="form-group">
            <label htmlFor="software_version" className="form-control-label semibold">Software Version*</label>
            <select id="software_version" className="form-control srf_require_field">
            <option selected>Choose software version...</option>
            <option value="blender2_90_1">Blender 2.90.1</option>
            <option value="blender2_82a">Blender 2.82a</option>
            <option value="blender2_82">Blender 2.82</option>
            <option value="blender2_80">Blender 2.80</option>
            <option value="blender2_79b">Blender 2.79b</option>
            </select>
        </div>

        )
}