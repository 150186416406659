export const SpacesCss = {
    rows: {
      style: {
        minHeight: '60px',
        borderBottomWidth: '0px !important',
        borderTop: 'rgba(81,81,81,1) .1px solid' 
      }
    },
    headCells: {
      style: {
        paddingLeft: '20px', // override the cell padding for head cells
        paddingRight: '20px',
      },
    },
    cells: {
      style: {
        paddingLeft: '20px', // override the cell padding for data cells
        paddingRight: '20px',
      },
    },
};

