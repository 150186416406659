import React, { Component } from 'react'
import Logger from '../../utils/debug'
import Translate from '../Translate'
class SuperUpload extends Component {
  state = {
    drag: false
  }
  
  dropRef = React.createRef()
  
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }


  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    Logger("DROP_UPLOAD", e.FileSystemDirectoryReader)
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({drag: true})
    }
  }


  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({drag: false})
    }
  }


  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({drag: false})
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
      this.dragCounter = 0    
    }
  }


  componentDidMount() {
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }


  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }


  render() {
    return (
      <div
        ref={this.dropRef}
      >
        {this.state.drag &&
          <div className="drop-to-upload">
            <div className="d-flex justify-content-center text-center h-100">
              <span className="align-self-center"><Translate text="Drop here to upload"/> !</span>
            </div>
          </div>
        }
        {this.props.children}
      </div>
    )
  }
}
export default SuperUpload