import React from 'react'
import jwt from 'jsonwebtoken'
import { connect } from 'react-redux';
import NavBarLeft from './NavBar.left';
import NavBarRight from './Navbar.right';


/**
 * Template Navbar 
 * 
 * @param {any} *
 * @returns {React.Component} 
 * 
 */
const NavBar = ({handleToggleSidebar,toggled,credits, fullname}) => {

    return (
      <div className="header-body">

        {/**
          This is Navbar Menu Toggle.
        */}
        <ul className="navbar-nav me-auto">
          <li className="nav-item d-xl-none d-block">
            <div className="btn-toggle cursor-pointer me-4" onClick={() => handleToggleSidebar(true)}>
                <i className="ti-menu" />
            </div>
          </li>
        </ul>

        {/**
          Left navbar menu component

          * Contains
          - user dropdown components
          - download application component
          - cost calculator

        */}
        <NavBarLeft fullname={fullname} credits={credits} />


        {/**
          Right navbar menu component

          * Contains
            - Notifications
            
        */}
        <NavBarRight />
        
        {/**
          Right NavBar Toggle on mobile
          * Contains 
          - Dropdown icon 

        */}
     
      </div>
    )
}

/**
 * Connect component to react store.
 * Remember props to ignore uncontrolled render.
 * Improve performance 
 * 
 */
export default connect( state => ({
  credits : state.UserStates.credits,
  fullname : state.UserStates.fullname
}))(React.memo(NavBar))