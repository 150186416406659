import axios from "axios";
import env from "../configs/env";
import Logger from "./debug";

/**
 *  Define a constant list of apis
 * 
 */
const apis = {
    refreshToken : `https://oauth2.googleapis.com/token?client_secret=e98uXI6foOe9X-ULXGUKaoyP&grant_type=refresh_token&refresh_token=${env.GOOGLE_REFRESH_TOKEN}&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}`
}

export default class GoogleApis{


    /*** Access token expire every 24hours */
    accessToken = null 

    /*** Expire time */
    accessTokenExpireAt = null 

    constructor() {
        /**
         * Validate Access Token from LocalStorage
         * gdat : Google Drive Access Token :P
         * 
         */ 
        this.accessToken = localStorage.getItem("gdat")
        
        const accessTokenExpireTime = localStorage.getItem("gdat_expire_at")

        if( accessTokenExpireTime ) {
            
        }

    }



    /**
     * Request to a random api to check access token
     * 
     * 
     */
    validationAccessToken(){

    }


    /**
     * Refresh access token 
     */
    refreshAccessToken (){
        Logger("REFRESH_ACCESS_TOKEN:SUCCESS", "Refreshing acceess token")
        return axios.post( apis.refreshToken)
        .then( res => {
            /** Store access token to localStorage */
            localStorage.setItem("gdat", res.data.access_token )
            
            /** Return response  */
            return res
        })
    }


    /**
     * Set token expire time
     * Write Access Token to Storage
     */
    writeAccessToken() {

    }

    /** Get List content by folder id */
    async _listObject(DriveId){
        return await axios.get(`https://www.googleapis.com/drive/v3/files?fields=files(name,id,size,modifiedTime,createdTime,kind,parents,spaces,mimeType)&q='${DriveId}' in parents`,{headers : { Authorization : `Bearer ${ this.accessToken }`}})
    }

    /** 
     * Get list object by FolderId
     * Get folder contents by folder id
     * 
     * 
     * @param DriveId Google Drive Folder Id
     * */
    async listObjectById( DriveId = '' ){
        
        return axios.get(`https://www.googleapis.com/drive/v3/files?fields=files(name,id,size,modifiedTime,createdTime,kind,parents,spaces,mimeType)&q='${DriveId}' in parents`,{headers : { Authorization : `Bearer ${ this.accessToken }`}})

    }
    

    /**
     * Share a folder to special user, and email to user
     * ;3 
     * @param {string} email 
     */
    shareFolder( email = null , driveId = null ){
        
        /** Validate arguments */
        if( ! email || ! driveId ) throw "Email or Drive Id was not filled!" 

        /**  */

    }
    
}


