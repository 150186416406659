import React from 'react'
import { useTranslation } from 'react-i18next'
import AppNotification from '../AppNotification/AppNotification'

export default function ChangeWebsiteLanguage() {
    
    const {i18n} = useTranslation()

    function switchLang( lang  ){
        i18n
        .changeLanguage(lang)
        .catch( err => {
            alert("Err")
        })
    }

    // English button
    function English (){
        switchLang('en-US') 
     
    }

    // Portuguese button
    function Portuguese(){
         switchLang('pt-br')
         AppNotification('hello','qsasđfád')
       
    }


    // Vietnamese button
    function Vietnamese(){
   
    switchLang('vi-VN')
    }

    // Return Section
    return(
        <div className="row">
            <div className="col-6">
                <button type="button" className="btn btn-light btn-block mb-2" onClick={()=>English()}>English</button>
                <button type="button" className="btn btn-light btn-block mb-2" onClick={()=>Vietnamese()}>Vietnamese</button>
                <button type="button" className="btn btn-light btn-block mb-2" onClick={()=>Portuguese()}>Portuguese</button>
            </div>
            
            <div className="col-6">
                <button type="button" className="btn btn-light btn-block mb-2" onClick={()=>English()}>Russia</button>
                <button type="button" className="btn btn-light btn-block mb-2" onClick={()=>Vietnamese()}>China</button>
                <button type="button" className="btn btn-light btn-block mb-2" onClick={()=>Portuguese()}>France</button>
            </div>
        </div>
        
    )
}