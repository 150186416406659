const initialState = {
    /**
     * User Render Credits
     * @type Float
     */
    credits : 0 ,


    /**
     * Return user_name
     * 
     */
    userName : null,

    /**
     * Email
     * 
     */
    email : null,

    /**
     * user register time
     * 
     */
    registedAt : null ,

    /**
     * is current user admin
     * 
     */
    isAdmin : false ,

    /**
     * User Working Folder is an folder in S3 Buckets
     * 
     * 
     * Eg. clr-official-spaces/taducphuong83
     *      taducphuong83           :   User working folder
     *      clr-official-spaces     :   Amazon S3 Bucket
     * 
     */
    uwf : "",

    /**
     * 
     * 
     */
    session : [],

    /**
     * User's fullname
     * @type Object
     * 
     */
    fullname : "",

    /**
     * Tracking option is tell we know should be track this user or not
     * @default true
     * 
     */
    trackingOption : true ,

    /**
     * User transfer method declare
     * 
     * @param transferMethodType      "GoogleDrive" || "Dropbox"
     * @returns {String} transferMethodType
     * 
     */
    transferMethodType : null,

    /**
     * Transfer Method Email.
     * 
     * @param transferMethodEmail   Email of client
     * @returns {String} transferMethodEmail
     * 
     */
    transferMethodEmail : null,

    /**
     * 
     * @returns {URL} transferMethodUrl
     * 
     */
    transferMethodUrl : null,

    /**
     * 
     * @returns {Boolean} isDefinedTransferMethod
     * 
     */
    isDefinedTransferMethod : false,

    /**
     * User's Socket id.
     * Define to current user a custom id 
     * 
     */
    socketId : null,

    /**
     * Rendering jobs status.
     * List of rendering or analyzing jobs.
     * 
     */
    queued_jobs : [],

    /**
     * Save current folder listing style
     * 
     * "Grid" || "List"
     * 
     */
    folderListingStyle : "grid",

    /**
     * Cloud Folder Type
     * 
     * GoogleDrive || Dropbox
     * 
     */
    cloudFolderType : null,

    /**
     * Id of cloud folder
     * 
     */
    cloudFolderId : null ,

    /**
     * Cloud folder name
     * 
     * 
     */
    cloudFolderName : null ,

    /**
     * Dropbox cloud folder prefix
     * 
     * Super Render/dropbox_folder_id
     * 
     */
    dropboxFolderPrefix : null ,

    /**
     * 
     * 
     */


}


/**
 * Dispatch handles
 * 
 */
export default (state = initialState, action) => {
    switch (action.type) {

    /**
     * Initial 
     * 
     */
    case "INITIAL_USER_DATA" :
        return { ...state, ...action }

    /**
     * Set Credits of User
     * Credits must be Float type !
     * 
     */
    case 'SET_CREDITS':
        return { ...state, credits : action.credits }

    /**
     * Set User Working folder name
     */
    case "SET_UWF" : 
        return { ...state,  uwf : action.uwf } 
    
    /**
     * If JWT still invalid, We need to check JWT to see is it has changed or not !
     * 
     * 
     */
    case "SESSION" :
        return { ...state, session : action.session , invalid : action.invalid }

    default:
        return state
    }
};
