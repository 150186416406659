import React from 'react'

export default function WOARenderOutputFile() {
    return (
        <div className="form-group m-0">
            <label className="form-label semibold">Render Output File*</label>
            <div className="form-control-wrapper">
                <input id="renderoutput_file" type="text" className="form-control" placeholder="e.g: filename"  />
                <small className="text-muted">Output File allowed: png/tif/tga/bmp/jpg/exr and hdr</small>
            </div>
        </div>
    )
}