import React from 'react'
import Stores from '../../redux/store';
import SuperUpload from "../DropUpload/index"
import Translate from '../Translate';

// https://github.com/react-dropzone/react-dropzone
class DropzoneToUpload extends React.Component {

    state = {
        files : [] 
    }

    constructor(props) {
        super(props);
        
    }


    /**
     * 
     * @param {FileSystem} filesArray 
     * 
     */

    
    uploadHandle(){
        
        /**
         * Use Redux Store to Save state of uploading progress
         * If user change page around the app.srf.com
         * A component "UploadingProgress" will popup and show progress for client.
         * 
         */
    }

    // If user Change
    handleChangeFolder = (event) => {

        // Start Download
        Stores.dispatch( {
            type : "UPLOAD_STARTED",
            files : event.target.files
        })
        // Upload Handle
        this.uploadHandle(event.target.files)
    }

    // If user Change
    handleDrop = (files) => {

        Stores.dispatch( {
            type : "UPLOAD_STARTED",
            files : files
        })

        // Upload Handle
        this.uploadHandle(files)
    }

    render() {
      return (
          <div>
            <SuperUpload handleDrop={ this.handleDrop } >
                <div className="text-center rounded bg-dark py-5" style={{border: 'dashed grey 1px',}}>
                    <div className="p-4">
                        <h3>
                            <Translate text="Drag and drop files and folders you want to upload here, or choose" />
                            <label className="cursor-pointer">
                                <span className="btn btn-success m-2 text-dark fw-bolder">
                                    <h3><Translate text="Add files" /></h3>
                                </span>
                                <input type="file" onChange={this.handleChangeFolder} className="d-none" multiple={true} /> 
                            </label>
                            <Translate text="or" />
                            <label className="cursor-pointer">
                                <span className="btn btn-success m-2 text-dark fw-bolder">
                                    <h3><Translate text="Add folders" /></h3>
                                </span>
                                <input type="file" onChange={this.handleChangeFolder} className="d-none" webkitdirectory="true" directory="true" mozdirectory="true" odirectory="true" msdirectory="true" /> 
                            </label>
                            
                        </h3>
                        (<Translate text="support folder/files size up to 1 Terabyte per upload. To upload an extremely large folder use Sync Tool" />)
                    </div>
                </div>
            </SuperUpload>

          </div>
      )
    }
}
export default DropzoneToUpload ;