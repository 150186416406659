import React from 'react'
import './calculator-modal.scss'

class CalculatorModal extends React.Component {
    render() {
      return (
        <div className="modal fade" id="credit-calculatorModal"  aria-labelledby="credit-calculator" aria-hidden="true" >
        <div className="modal-dialog modal-xl modal-dialog-centered" >
            <div className="modal-content bg-dark">
              <div className="modal-body">
              <h4>1. Your Project
              <button type="button" className="btn-close float-end text-light" data-bs-dismiss="modal" aria-label="btn-close"></button>
              </h4>
              <div className="row render-engines">
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label id="project_type_label" className="form-label">Project type*</label>
                    <select name="project_type" id="project_type" className="form-control">
                      <option value="animation" defaultValue="selected">Animation</option>
                      <option value="single-frame">Single Frame</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label id="number_of_frames_label" className="form-label">Number of frame*</label>
                    <input type="number" className="form-control" name="number_of_frames" id="number_of_frames" placeholder="number of frame" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label id="rendertime_mins_label" className="form-label">Rendertime (Min)*</label>
                    <input type="number" className="form-control" name="rendertime_mins" id="rendertime_mins" placeholder="time in minutes" />
                  </div>
                </div>
              </div>
              <h4>2. Your Workstation</h4>

              <div className="nav nav-pills my-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <a className="nav-item nav-link active" id="v-pills-CPU-tab" data-bs-toggle="pill" href="#v-pills-CPU" role="tab" aria-controls="v-pills-CPU" aria-selected="true">CPU</a>
                <a className="nav-item nav-link" id="v-pills-GPU-tab" data-bs-toggle="pill" href="#v-pills-GPU" role="tab" aria-controls="v-pills-GPU" aria-selected="false">GPU</a>
              </div>
              <div className="tab-content" id="v-pills-tabContent">

              <div className="tab-content">
                <div className="tab-pane fade show active" id="v-pills-CPU" role="tabpanel" aria-labelledby="v-pills-CPU-tab">
                  <div className="row render-engines">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <div className="form-group">
                        <label id="processor_brand_label" className="form-label">Brand*</label>
                        <select name="processor_brand" id="processor_brand" className="form-control">
                          <option value="AMD">AMD</option><option value="Intel">Intel</option><option value="Intel XEON">Intel XEON</option></select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <div className="form-group">
                        <label id="processor_model_label" className="form-label">Model*</label>
                        <select name="processor_model" id="processor_model" className="form-control"><option value /><option value="Bronze">Bronze</option><option value="D-15xx">D-15xx</option><option value="E-2xxx">E-2xxx</option><option value="E3-1xxx">E3-1xxx</option><option value="E3-1xxx v2">E3-1xxx v2</option><option value="E3-1xxx v3">E3-1xxx v3</option><option value="E3-1xxx v5">E3-1xxx v5</option><option value="E3-1xxx v6">E3-1xxx v6</option><option value="E5-1xxx">E5-1xxx</option><option value="E5-1xxx v2">E5-1xxx v2</option><option value="E5-1xxx v3">E5-1xxx v3</option><option value="E5-1xxx v4">E5-1xxx v4</option><option value="E5-2xxx">E5-2xxx</option><option value="E5-2xxx v2">E5-2xxx v2</option><option value="E5-2xxx v3">E5-2xxx v3</option><option value="E5-2xxx v4">E5-2xxx v4</option><option value="E5xxx">E5xxx</option><option value="E7-8870">E7-8870</option><option value="Gold">Gold</option><option value="i7-3xxx">i7-3xxx</option><option value="L5xxx">L5xxx</option><option value="Platinum">Platinum</option><option value="Silver">Silver</option><option value="W-2xxx">W-2xxx</option><option value="W3xxx">W3xxx</option><option value="W5xxx">W5xxx</option><option value="X3440">X3440</option><option value="X5xxx">X5xxx</option><option value="other">Other</option></select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <div className="form-group">
                        <label id="processor_typemodel_label" className="form-label">Type*</label>
                        <select name="processor_type" id="processor_type" className="form-control"><option value="37.73437500" defaultValue="selected">E5-1620</option><option value="44.65032857">E5-1650</option><option value="40.50505000">E5-1660</option></select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <div className="form-group">
                        <label id="processor_ghz_label" className="form-label">GHz*</label>
                        <input type="number" className="form-control" name="processor_ghz" id="processor_ghz" placeholder="GHz" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <div className="form-group">
                        <label id="processor_cores_label" className="form-label">Cores*</label>
                        <input type="number" className="form-control" name="processor_cores" id="processor_cores" placeholder="Cores" disabled="disabled" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                      <div className="form-group">
                        <label id="processor_cpus_label" className="form-label">Number of CPU(s)*</label>
                        <input type="number" className="form-control" name="processor_cpus" id="processor_cpus" placeholder="Number of CPU(s)" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="v-pills-GPU" role="tabpanel" aria-labelledby="v-pills-GPU-tab">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">
                        <label id="octanebench_score_label" className="form-label">Enter your Computers OctaneBench Score
                        <a target="_blank" href="http://render.otoy.com/octanebench/" rel="noopener noreferrer"><i className="fa fa-info-circle" /></a></label>
                        <input type="number" className="form-control" name="octanebench_score" id="octanebench_score" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                </div>
                  
              <h4>3. Render Estimation</h4>
              <div className="row render-engines">
                <div id="est_cost_loader" className="srfloader" style={{display: 'none'}} />
                <div className="col-sm-12"><h5 style={{color: '#a5b819'}}>RENDER TIME</h5></div>
              </div>
              <div className="row">
                <div className="col-sm-3">Bronze: <span id="est_rendertime_low">1.4 hours</span></div>
                <div className="col-sm-3">Silver: <span id="est_rendertime_medium">21.1 minutes</span></div>
                <div className="col-sm-3">Gold: <span id="est_rendertime_high">8.5 minutes</span></div>
                <div className="col-sm-3" style={{color: '#a5b819'}}>On Your Workstation: <span id="est_rendertime_your_workstaion">3.5 days</span></div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-12"><h5 style={{color: '#a5b819'}}>COST</h5></div>
              </div>
              <div className="row">
                <div className="col-sm-3">Bronze: $<span id="est_cost_low">34.85</span> <span className="hidden-xs" style={{fontWeight: 'normal'}}><i>(20 nodes)</i></span></div>
                <div className="col-sm-3">Silver: $<span id="est_cost_medium">69.7</span> <span className="hidden-xs" style={{fontWeight: 'normal'}}><i>(80 nodes)</i></span></div>
                <div className="col-sm-3">Gold: $<span id="est_cost_high">139.39</span> <span className="hidden-xs" style={{fontWeight: 'normal'}}><i>(200 nodes)</i></span></div>
                <div className="col-sm-3">&nbsp;</div>
              </div>
              <br />

              </div>
            </div>
        </div>
    </div>
      )}
}
export default CalculatorModal ;