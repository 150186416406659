import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import swal from 'sweetalert';


/**
 * Handle disconnect and reconnect to internet
 * 
 * @returns {}
 */
export default function InternetDisconnect(){ 

    const [online, setOnline] = useState(true)
    const [isOnlineBack, setIsOnlineBack] = useState(false)

    useEffect(() => {

        /**
         * Handle when disconnect
         * 
         */
        window.addEventListener("offline", () => {
            setIsOnlineBack(false)
            setOnline(false)
        })


        /**
         * Handle when reconnect to internet
         * 
         */
        window.addEventListener("online", () => {
            setOnline(true)
            setIsOnlineBack(true)
        })

    }, [])

    /**
     * 
     */
    if(!online) {
        swal({
            title : "Are you still online? ",
            text : "If you are uploading, please keep this page alive - your upload progress might stop if you changed page "
        })
    }

    /**
     * 
     */
    if(isOnlineBack) {
        swal({
            title : "Welcome to internet",
            text : "Your download will auto retry"
        })
    }

    /**
     * 
     */
    return <div></div>
}