import React, { useEffect } from 'react'
import { BrowserRouter as Router, Link, Redirect, Route, Switch, useHistory,useParams } from 'react-router-dom'
import DropboxFileManager from "../../components/Dropbox/FileManager"
import FileManagerLayout from '../../layouts/FileManager';
import Layout from '../../components/Spaces/Layout'
import { Dropbox } from "dropbox"
import { useState} from 'react';
import Logger from '../../utils/debug';
import env from '../../configs/env';
import FolderManager from '../../components/FolderManager/FolderManager';


function DropboxFileManagerView() {

  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [folderData, setFolderData] = useState([])

  /**
   * initial components
   * 
   */
  useEffect(() => {
    
    /**
     * 
     * 
     */
    fetch()

  }, [])

  /**
   * use SDK or axios to get get data
   * 
   * 
   */
  function fetch(path = "" ) {
    /**
     * Initial Dropbox Class
     */
    const dbx = new Dropbox({
      accessToken: env.DROPBOX_ACCESS_TOKEN
    })

    /**
     * Set loading states
     * Remove folder data to empty,
     * if folder data not empty,
     * when user go to sub folder,
     * loading skeleton component and current folder data component will show together
     */
    setIsLoading(true)
    setFolderData([])

    /**
     * Get by path
     * 
     */
    const folderContents = dbx.filesListFolder({
      path
    })

    /**
     * Promise get
     * 
     */
    folderContents.then(data => {
      /**
       * Get list entries from result
       * 
       */
      const DropboxFolderEntries = data.result.entries

      reduce(DropboxFolderEntries) 

    }).catch(err => {

      /**
       * Logger debug
       * 
       */
      Logger("DROPBOX_LIST:SUCCESS", err)
    })
  }


  /**
   * Reduce data from fetch to FolderManager readable
   * 
   */
  function reduce(DropboxFolderEntries) {

      /**
       * Map data from Dropbox content type to Srf FileList Type
       * 
       */
      const fileManagerReadable = DropboxFolderEntries.map((item, index) => ({
        id: index,
        name: item.name,
        Key: item.name,
        path: item.path_display,
        size: item.size ? item.size : "Unknown",
        fileType: item['.tag'],
        modified: item.client_modified,
        fileExtension: item.name.split(".").pop(),
        sceneHistory: ''
      }))

      /**
       * Debug
       */
      Logger("DROPBOX_LIST:SUCCESS", DropboxFolderEntries)

      /**
       * Set folder data 
       */
      setFolderData(fileManagerReadable)

      /**
       * Set loading states
       */
      setIsLoading(false)
  }


  /**
   * Return components
   * 
   */
  return (
    <FileManagerLayout>
      <Router basename="dropbox" >
        <Switch>
          
          <Route path="/:path(.*)" exact strict sensitive >
            <FolderManager onUrlChanged={ path => { fetch(path)} } { ...{ data : folderData , isLoading, gridStyle : "gridView"  } } />
          </Route>

        </Switch>
      </Router>
    </FileManagerLayout>
  )
}
export default DropboxFileManagerView;