import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { BrowserRouter as Router, Redirect, Route, Switch , useHistory, useParams } from 'react-router-dom'
import SpaceBreadcrumb from '../Breadcrumb/SpaceBreadcrumb'
import TableMaping from "./defineColumns"
import FolderSkeleton from './FolderSkeleton/FolderSkeleton'
import FileManager from '../../FileManager/FileManager'
import Layout from '../Layout'
import { MDBBtn, MDBTooltip } from 'mdbreact'
import S3Browser from '../../../utils/S3.Browse'
import AWS from "aws-sdk"
import NoData from '../../FolderEmpty/FolderEmpty'
import RelativeTime from '../../RelativeTime/RelativeTime'
import Logger from '../../../utils/debug'
import Stores from '../../../redux/store'
import {SpacesCss} from "./styles.js"
import { connect } from 'react-redux'

const AWS_SDK_CONFIG = {
  region: 'ap-southeast-1',
  Bucket : 'clr-official-spaces',
  accessKeyId : "AKIA3K6CKTAP4VARKJ7K",
  secretAccessKey : "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"
}


/**
 * define sence extension list
 * 
 */
const senceList = [
  "max",
  "ma",
  "mb",
  'blend',
  'c4d' ,
  'hip',
  'hipnc'  ,
  'hiplc', 
  'nk',
  'aep'
]

/**
* 
* Fetch from server
* 
*/
const FolderTree = new S3Browser(AWS_SDK_CONFIG)


/**
 * Mapping Bucket Json data to defineColumns Readable 
 * 
 * 
 * @param {Bucket} bucket_data 
 * @param {Path} current_path 
 * @returns 
 * 
 */
function BucketMap(bucket_data , current_path)  {

  // return [] 
  function getName(Key) {
    var splits = Key.split("/")
    return splits.length >= 1 ? splits[ splits.length - 1  ] : Key
  }

  function getFolderName(path){
    var splits = path.split("/")
    return splits[ splits.length - 2 ]
  }

  /**
   * Get real path of object
   * 
   * @param {Folder.Name} Key 
   * @returns realpath
   */
  function getFolderPath(Key) {
    let path =  `${current_path}/${Key}`
    return path
  }

  return bucket_data
    .filter( (val, index) =>{

      // if( val.Prefix  ) return false 
      return true 
    }).map((item,index)=>{

      let file_name = getName( item.Key ? item.Key : item.Prefix)
      let fileExtension = file_name.split('.').pop() 
      
      /**
       * If Found a zip extension, dispatch store to change zip alert state
       * 
       */
      if( fileExtension == "zip" ) Stores.dispatch({
        type : "ZIP_FOUND"
      })

      return {
        id : index ,
        Key : item.Key, // Delete need a Key
        path : (item.Key ? item.Key : getFolderPath(getFolderName(item.Prefix))) ,
        name : item.Key ? file_name : getFolderName(item.Prefix) , 
        size : item.Size ? `${Math.floor(item.Size/1024)} Mb` : "" ,
        fileType : item.Key ? "file" : "folder" ,
        fileExtension ,
        modified : <RelativeTime time={item.LastModified}/> ,
      }
    }
  )
}


/**
 * SubRouter Component
 * @returns {React.Component}
 */
const SpacesFileManager = () => {
  const [folderData, setFolderData] = useState([])
  const [grideView, setGrideView] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFolderEmpty, setIsFolderEmpty] = useState(false);
  const {path} = useParams() ;
  const history = useHistory() 
  const lazyitems = 75 
  var lazypages = 0 

  /**
   * Check in localStorage uwf. User Working Folder
   * if doesnt have uwf then redirect user to logout
   * 
   */
  if(!localStorage.getItem("uwf")) return window.location = "/login"
  const username = localStorage.getItem("uwf")
  
  /**
   * If folder name is Not A Number (isNaN) and had one letter,
   * 
   * @param {String} prefix 
   * @returns Bool
   */
  function isDiskDrive(prefix) {
    return  prefix.Prefix.split("/")[ prefix.Prefix.split("/").length - 2 ].length == 1 &&
            isNaN(prefix.Prefix.split("/")[ prefix.Prefix.split("/").length - 2 ]) &&
            path == ""
  }


  function getS3Prefix(real_path ){
    /**
     * 
     * This is an example real_path
     * /taducphuong83/SRF
     * taducphuong83 = username.length 
     * 
     * username + 1 = taducphuong83/
     * 
     */

    let path =  `${username}${real_path}/`

    return path 
    // return real_path.substring( username.length  + 2, real_path.length  ) 
  }


  function getFolderTree(path){
    setIsLoading(true)

    /**
     * Empty Folder 
     */
    if(path !== "")
      setFolderData([])
    
    if(path === "/")
      path = ''

    
    Logger('REAL PATH ',path)


    FolderTree.listObjects({
        Bucket : 'clr-official-spaces' ,
        Delimiter : "/",
        Prefix : getS3Prefix(path),
      })
      .then(folder => {
        setIsLoading(false)

        if(folder.Contents && folder.Contents.length == 1 && folder.Contents[0].Key == getS3Prefix(path))
          return setIsFolderEmpty(true)
        
        // setIsFolderEmpty(false)
        // taducphuong83/1-5/
        // Logger(folder)

        let BucketMapData = BucketMap(
          [

            /**
             * Detect disk drive from Prefixes
             * 
             */
            ...folder.CommonPrefixes
              .filter(
                prefix => ( isDiskDrive(prefix) ? true : false   ) ) ,
            
            /**
             * Return normal folder
             * 
             */
            ...folder.CommonPrefixes
            .filter(
                prefix => ( isDiskDrive(prefix) ? false : true   ) ),

            /**
             * Return Sence files
             * 
             */
            ...folder.Contents
              .filter( 
                ({Key}) => ( senceList.indexOf(Key.split(".").pop()) != -1 ? true : false    )
              ),
            /**
             * Return another files are not sence file
             * 
             */
            ...folder.Contents
              .filter( 
                ({Key}) => ( senceList.indexOf(Key.split(".").pop()) != -1  ? false : true    )
              )
          ]
          ,
          path
        )
        // Logger("ORDER_FOLDER:OK", folder.Contents )
        // Logger(path)
        if( BucketMapData == [] )  setIsFolderEmpty(true) ; else setIsFolderEmpty(false)
        // Logger("MAP_DATA:WARN",BucketMapData)
        setFolderData(
          BucketMapData
        )

      })

  }


  /**
   * Listen URL Change handle
   * 
   * if url change, re-render components !
   * 
   */
  useEffect(() => {
      return history.listen((location) => { 
        getFolderTree(location.pathname)
      }) 
  },[history]) 
  
  
  /**
   * Initial get folder data
   * 
   */
  useEffect(() => {

    /**
     * 
     * 
     */
    getFolderTree("/"+path)

  }, [])

  


  /**
   * Datatable search input typing handle
   * 
   * 
   * @param {JavascriptEvent} event 
   * @returns 
   * 
   */
   const searchTypingHandle = ( event ) => {

    // // Logger(event.target.value)
    // if( event.target.value == "" ) return this.setState({ file_data : this.state.data })

    // const searchValue = event.target.value

    // // Return match file name
    // const searchResult = this.state.data.map(
    //     item => ( ( item.name != undefined &&  item.name.indexOf( searchValue ) != -1 ) ? item : {}  )
    // )

    // return this.setState({ file_data : searchResult })
    
  }




  /**
   * Search components 
   * 
   * @returns {React.Component} Return Search components 
   * 
   */
  function SearchInput(){
    return <input onChange={searchTypingHandle } className="form-control" type="text" placeholder="Search" aria-label="Search" />
  }




  /**
   * 
   * @returns {React.Component}
   * 
   */
  function tableActions() {
   
    return(
        <div className="d-flex pt-2 m-0" >
          <div className="pe-4">
              <MDBTooltip domElement
                tag="span"
                placement="left">
                <span>
                  <MDBBtn className="me-2" onClick={ () => { setGrideView( !grideView ) } } color="dark"><i className={`${ grideView ? "ti-list" : "ti-layout-grid2" }`}></i></MDBBtn>
                </span>
                <span>{grideView ? "List view" : "Grid view"}</span>
              </MDBTooltip>
          </div>
          <div className="w-100" >        
            <SearchInput />
          </div>

        </div>
    )
  }


  /**
   * If is grid views
   * 
   */
   if(grideView) 
    return (
      <div className="fade-in rounded overflow-hidden mt-2" style={{backgroundColor:"#424242",minHeight:600}}>         
        <div className="sc-fnVZcZ jZBGyK rdt_TableHeader" role="heading" aria-level="{1}">
          <div className="sc-fFSPTT fgINsU">
            <SpaceBreadcrumb /> 
          </div>
          <div className="sc-bkbkJK ffLupP">
              {tableActions()}
          </div>
        </div>
        <div className="rdt_TableBody">
          <div className="row p-0 m-0">
          { ( ( path == "" ) && isFolderEmpty ) ? <NoData/> : <div></div> } { (() => {Logger(`alo`,isFolderEmpty)})()}
          {(isLoading) ? <FolderSkeleton /> : <div></div>}
          {(isFolderEmpty && (path != "")) ? (
            <div className="col-12">
              <div className="loading-space d-flex align-items-center justify-content-center" style={{ cursor: "default", userSelect: "none" }} >
                <img src="https://statics.superrendersfarm.com/app/assets/media/image/folder-empty.png" alt="Folder empty" />
                <h3 className="text-center mt-2">This folder is empty !</h3>
              </div>
            </div>
            ) : <div></div>
          }
          <FileManager  files={folderData}  columns={FolderTree}/>
          </div>
        </div>
      </div>
    )

  return (
    <div className="fade-in rounded overflow-hidden" style={{backgroundColor:"#424242",minHeight:600}}>
    <DataTable
      columns={TableMaping(FolderTree)}
      data={folderData}
      theme="dark"
      customStyles={SpacesCss}
      highlightOnHover
      defaultSortField="id"
      noDataComponent={
        <div className="d-flex justify-content-center">
       <div
         className="align-items-center"
         style={{ cursor: "default", userSelect: "none" }}
       >
         <img
           src="https://statics.superrendersfarm.com/app/assets/media/image/folder-empty.png"
           alt="Folder empty"
         />
         <h3 className="text-center mt-2">This folder is empty !</h3>
       </div>
     </div>
      }
      title={<SpaceBreadcrumb />}
      onSelectedRowsChange={ item => { Logger("FILES:OK",item) } }
      selectableRows
      selectableRowsHighlight
      actions={tableActions()}
      contextActions={<MDBBtn color="dark">Delete</MDBBtn>}
      progressPending={ isLoading }
      progressComponent={<FolderSkeleton />}
    />
    </div>
    )
  }
   
      
/**
 * 
 * 
 * @param {React.Redirect} ({RedirectTo})
 * @returns {React.Component}
 */
function SpaceFilemanager({RedirectTo}){
  
  /**
   * File Managers
   * 
   * Return HTML
   * 
   * @returns {React.Component}
   * 
   */

  if(RedirectTo) return ( <Redirect to={RedirectTo} /> )

  
  return (

    <Router basename="spaces">
        <Switch>
       
          <Route path="/:path(.*)" exact strict sensitive >
            <Layout view={<SpacesFileManager />} />
          </Route>

        </Switch>
      </Router>
    )
}


/**
 * When Link state changed, this component will return a <Redirect/> and redirect to {Link}
 * Connect Space File Manager to Redux Store
 * Get Link To State.
 * 
 * Memo props. improve performance 
 * 
 */
export default  connect( state => ({
  RedirectTo : state.LinkStates.to
}))( React.memo(SpaceFilemanager) ) ;