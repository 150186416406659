import React from 'react'

export default function ArnoldOutputBMP({}) {

    return (
    <div className="card card-body">
        <legend>BMP Configuration:</legend>
        <div className="form-group row">
            <div className="col-lg-10">
                <div className="row">
                    <label className="col-lg-2">Color<span className="text-danger">*</span></label>

                    <div className="form-group col-lg-5">
                        <div className="custom-control custom-radio custom-checkbox-success">
                            <input type="radio" id="BMP_8bit" name="BMP_color" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="BMP_8bit">8 Bit Optimized palette (256 colors)</label>
                        </div>
                    </div>
                    <div className="form-group col-lg-5">
                        <div className="custom-control custom-radio custom-checkbox-success">
                            <input type="radio" id="BMP_RGB_24bit" name="BMP_color" className="custom-control-input" />
                            <label className="custom-control-label" htmlFor="BMP_RGB_24bit">RGB 24 bit (16.7 Million Colors)</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}