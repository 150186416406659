import React from 'react'
import ChangeWebsiteLanguage from '../../components/ChangeWebsiteLanguage/ChangeWebsiteLanguage'
import Translate from '../../components/Translate'


export default function ChangeLanguage({}) {
    return(
        <div className="modal fade" id="ChangeLanguage" tabIndex="-1" aria-labelledby="ChangeLanguageLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark">
                    <div className="modal-header">
                        <h5 className="modal-title" id="ChangeLanguageLabel"><i className="fad fa-language me-2"></i> <Translate text="Choose Your Language" /></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button>
                    </div>
                    <div className="modal-body">
                        <ChangeWebsiteLanguage />
                    </div>
                </div>
            </div>
        </div>
    )
}