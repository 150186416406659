const translate = {
    translation: {
        "Choose Your Language" : "Escolha seu idioma",
        "Language" : "Língua" ,
        "Make sure you package your projects properly before uploading & rendering your scenes" : "Certifique-se de empacotar seus projetos corretamente antes de enviar e renderizar suas cenas",
        "rebuild your working folder" : "reconstruir sua pasta de trabalho",
        "Use refresh button to rebuild your working folder if you think it is not up to date" : "Use o botão de atualização para reconstruir sua pasta de trabalho se você acha que não está atualizada",
        "GENERAL QUICK START": "INÍCIO RÁPIDO GERAL",
        "How to render on our render farm": "Como renderizar em nosso farm de renderização",
        "STEP 1. UPLOAD" : "ETAPA 1. CARREGAR",
        "Remember to Package Your Project before uploading to Super Renders Farm space." : "Lembre-se de empacotar seu projeto antes de carregá-lo no espaço Super Renders Farm.",
        "Upload now" : "Carregue agora",
        "STEP 2. RENDERING" : "ETAPA 2. REPRESENTAÇÃO",
        "Click on your scene file - make a render job - start rendering." : "Clique no arquivo de cena - faça um trabalho de renderização - comece a renderizar.",
        "Quick tips" : "Dicas rápidas",
        "STEP 3. DOWNLOAD" : "ETAPA 3. BAIXAR",
        "Connect to your GoogleDrive or Dropbox to get your render output files." : "Conecte-se ao seu GoogleDrive ou Dropbox para obter seus arquivos de saída de renderização.",
        "Method" : "método",
        "Reload this page" : "Recarregar esta página",
        "SRF Spaces" : "SRF Espaços",
        "Zip file not supported! it's recommended to extract it and reupload to your shared folder"  :"Arquivo zip não suportado! é recomendado extraí-lo e recarregar para sua pasta compartilhada",
        "TIPS" : "PONTAS",
        "Make sure you" : "Assegure-se de que você",
        "package your projects" : "empacote seus projetos",
        "properly before uploading & rendering your scenes" : "corretamente antes de enviar & renderizando suas cenas",
        "We strongly recommend uploading all the project files/scenes before start render jobs. The system then will temporarily pause all the uploads during rendering progress" : "Recomendamos fortemente o upload de todos os arquivos/cenas do projeto antes de iniciar os trabalhos de renderização. O sistema irá pausar temporariamente todos os uploads durante o andamento da renderização",
        "It's quite common if you want to simulate your local directory on our render farm, use feature <Create Folder> OR <Auto Keep Local Path (Sync Tool Only)>" : "É bastante comum se você quiser simular seu diretório local em nosso farm de renderização, use o recurso <Criar pasta> OU <Manter caminho local automaticamente (ferramenta de sincronização apenas)>",
        "Learn more" : "Saber mais",
        "Please select your scene files to add new render job" : "Selecione seus arquivos de cena para adicionar um novo trabalho de renderização",
        "Upload file" : "Subir arquivo",
        "Upload" : "Carregar",
        "Create Folder" : "Criar pasta",
        "Drag and drop files and folders you want to upload here, or choose" : "Arraste e solte os arquivos e pastas que deseja enviar aqui ou escolha",
        "or" : "ou",
        "Add files" : "Adicionar arquivos",
        "Add folders" : "Adicionar pastas",
        "support folder/files size up to 1 Terabyte per upload. To upload an extremely large folder use Sync Tool" : "pasta de suporte / tamanho de arquivos de até 1 Terabyte por upload. Para fazer upload de uma pasta extremamente grande, use a Ferramenta de sincronização",
        "Drop here to upload" : "Solte aqui para fazer upload",
        "Simulate your local directory on our farm. For example, your scene is located at" : "",
        "and its textures are located at" : "",
        "Create these two directories, and upload your scene to" : "",
        "and textures to" : "",
        "Examples" : "",
        "" : "",
        "" : "",
        "" : "",
        "" : "",
        "" : "",
        "" : "",
        "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
      
      }
  }
  export default translate