import React from 'react'

export default function WOAMayaEngineVersion () {
    return(
      <div className="form-group">
        <label htmlFor="render_engine_version" className="form-control-label semibold">Render Engine Version*</label>
        <select id="render_engine_version" className="form-control srf_require_field">
            <option selected>Choose engine version...</option>
            <optgroup label="Mantra">
                <option value="mantra">Mantra</option>
            </optgroup>
            <optgroup label="Renderman">
                <option value="renderman">Renderman</option>
            </optgroup>
            <optgroup label="Redshift">
                <option value="redshift3_0_gpu">Redshift</option>
            </optgroup>
            <optgroup label="Arnold">
                <option value="arnold_h_to_a">HtoA</option>
            </optgroup>
        </select>
    </div>
        )
}