import React from 'react'
import { useParams ,useHistory } from "react-router-dom"
import WOAScenePath from '../../forms/AppNewRenderJob/WithOutAnalyze/ParamSettings/ScenePath'
import WOAAfterEffect from '../../forms/AppNewRenderJob/WithOutAnalyze/AfterEffect/AfterEffect'

import WOABlender from '../../forms/AppNewRenderJob/WithOutAnalyze/Blender/Blender';

import WOAC4D from '../../forms/AppNewRenderJob/WithOutAnalyze/C4D/C4D';
import WOAHoudini from '../../forms/AppNewRenderJob/WithOutAnalyze/Houdini/Houdini';
import WOAMax from "../../forms/AppNewRenderJob/WithOutAnalyze/Max/Max"
import WOAMaya from '../../forms/AppNewRenderJob/WithOutAnalyze/Maya/Maya';
import QuickTips from '../../components/QuickTips/QuickTips';

function NewRenderJob () {
  var {path} = useParams()
  let fileExtension = path.split('.').pop()
  var history = useHistory()
    return(
        <div >
          <div className="d-lg-flex d-block">
            <button onClick={()=>history.goBack()} className="btn btn-dark shadow me-2">
              <i className="fad fa-chevron-left me-2"></i>Back
            </button>
            <h4 className="mt-2 text-light">Add New Render Job: {path}</h4>
            
            <div className="ms-auto">
              <QuickTips />
            </div>
          </div>
          <div className="bg-dark rounded mt-2">
            <div className="card-body">
              <WOAScenePath/>
        
              
              {fileExtension==="max"?<WOAMax />:
              fileExtension==="ae"?<WOAAfterEffect />:
              fileExtension==="blend"?<WOABlender />:
              fileExtension==="c4d"?<WOAC4D />:
              fileExtension==="hip"?<WOAHoudini />:
              fileExtension==="ma"?<WOAMaya />:
              "cc"}
            </div>
          </div>
        </div>
        )
}
export default NewRenderJob