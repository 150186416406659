import React from 'react';
import InternetDisconnect from './InternetDisconnect';
import SocketIo from './Socket.io';

/**
 * render parallels components like socket.io etc,...
 * 
 * @returns {React.Component}
 */
export default function Parallels() {

    /**
     * Parallel components
     * 
     */
    return <div>
        <InternetDisconnect />
        <SocketIo />
    </div>
}