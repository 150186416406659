import React, { useEffect } from 'react';
import { useState } from 'react';
import Logger from '../utils/debug';
import MaxSceneAnalyze from './AppSceneAnalyze/MaxSceneAnalyze';


/**
 * Return Scene Analyze Form
 * 
 * 
 * @returns {React.Component}
 */

function SceneAnalyzeForm({ scene , onSubmit }){
    /**
     * There is too many type of render engine like 3dsmax , maya ,... 
     * We need to detect engine type to call form
     * 
     */

    useEffect(() => {
        console.log(scene.fileExtension)
    }, [])
    /**
     * For 3ds Max Engine
     * 
     */
    if(scene.fileExtension === "max")
        return <MaxSceneAnalyze scene={scene} onSubmit={onSubmit} />

    /**
     * ...
     * 
     * 
     */

    return <div></div>

}

export default SceneAnalyzeForm 