import AWS, { S3 } from "aws-sdk"
import Events from "events"
import Logger from "./debug"

/**
 * Super Renders Farm AWS S3 Upload Library
 * 
 * 
    // Example config

    const sdk_config = {
        region: 'ap-southeast-1',
        // useAccelerateEndpoint: true,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'ap-southeast-1:c14793e7-5e11-455d-9d64-886e71975a03'
        }),
        Bucket : 'clr-official-spaces',
        username : 'taducphuong83'
    }

    // Initial new Thread 
    const UploadSession = new Thread(sdk_config)

    // Set emit delay for uploading file 
    UploadSession.current_file_emit_delay 

    // Append files to queue
    UploadSession.setFiles(Stores.getState().upload.files )


    // Start upload

    UploadSession.UploadQueue() ;

 Enjoy ~! 

 * 
 */
class Thread extends Events {

    /**
     * Uploading progress
     */
    uploading_progress = []


    /**
     * Config bucket/usrname
     * 
     */
    username = ""

    // Config max thread upload
    max_threads = 3
    queue_size =  2 
    part_size = 5 * 1024 * 1024
    /**
     * Current Threads counting
     * 
     * 
     */
    current_threads = 0

    /**
     * 
     * Current file uploading index
     */
    current_file_index = 0 

    /** 
     * 
     * File Store,
     * All file need to upload is here
     * 
     * 
     */
    files = []

    /**
     * 
     * File Queue
     * 
     */
    file_queued = []

    /**
     * 
     * Amazon SDK Configuration
     * 
     * 
     */
    config = {} 

    /**
     * When multi files was crash, this var store theire data!!
     * 
     */
    files_crashed = []

    /**
     * 
     * Get file count from files store
     * 
     * Use this var to optimize and speed up the application
     * 
     */
    files_count = this.files.length


    /**
     * When client network error, this var will store index of file that was failed to upload
     * 
     * 
     */
    network_error_index = 0 
    past_error_index = null 

    /**
     * 
     * Start timestamp
     * 
     * To get Speed of Browser,
     * 
     * Mbps = FileSize / UploadTime
     * 
     */
    start_speedtest = Date.now()


    networkFailCount = 0 
    networkError = false 
    /**
     * 
     * Total uploaded size
     * 
     */
    total_upload_size = 0 
    
    /**
     * 
     * Current file handles
     */

    // get percentage
    current_file_percent = 0 

    // Config Emit loop delay 

    current_file_emit_delay = 250


    /**
     * 
     * 
     */
    addmore_file = false 


    in_queue_files = []

    /**
     * Super Renders Farm AWS S3 Upload Library
     * 
     * @param {AWS.Config} aws_config Configuration of AWS SDK
     * @param {Number} max_threads  Max threads of HTTP query per upload
     * 
     * @default max_threads : 6
     * @default current_file_emit_delay : 1000 (ms)
     * 
     */
    constructor(aws_config ) {

        super()
        // Config Default AWS SDK
        this.config = aws_config

        /**
         * Config Username 
         */
        this.username = aws_config.username

        /**
         * 
         */
        // this.max_threads = max_threads 

        /**
         * Update config to AWS SDK 
         * 
         */
        AWS.config.update(aws_config)



        /**
         * 
         * Loop emit Http Progress
         */
        setInterval(() => {

            if(this.current_file_percent)
                this.emit("httpUploadProgress", this.current_file_percent)

        }, this.current_file_emit_delay);


        setInterval(() => {
            if(this.networkFailCount > 0 && window.navigator.onLine ) {
                Logger( 'UPLOAD', `Oh! Your internet is comeback !! Trying to resume`)
                this.retryUpload();
                this.networkFailCount = 0
            }

            if(!window.navigator.onLine){
                Logger( 'UPLOAD', `Offline !!! `)
                this.emit("networkError", this.files[this.current_file_index])
                this.networkFailCount++ 
                return window.navigator.onLine
            }
        }, 1000);

    }


    /**
     * 
     * 
     * Speed test 
     * 
     * 
     */

    speedtest_index = 0 
    append_size = 0 
    current_part = 0 
    async beginSpeedtest() {

        if( this.current_file_percent =  0 ) return 0 ;  

        if(this.speedtest_index != 0 && this.speedtest_index != this.current_file_index && this.current_part == this.current_file_percent.part)
            return 0 ;

        // just test file more than 10MB
        if( this.files[this.current_file_index].size >= 10000000 ){
            
            this.speedtest_index = this.current_file_index

            // Logger( 'UPLOAD', `Begin Speed test`)

            setInterval(() => {
                this.emit('speed',(( this.current_file_percent.loaded / 1024  / 1024) - this.append_size).toFixed(2)  )
                this.append_size = this.current_file_percent.loaded / 1024 / 1024 
            }, 1000);

        }


    }

    


    /**
     * Object List of all files need to upload here
     * 
     * @param {Files} files All file need to upload 
     * 
     */
    setFiles(files, isAddMoreFile = false ) {
        
        if(isAddMoreFile) {
            this.files = [ ...this.files , files ]
            this.files_count += files.length
            this.addmore_file= true 
        }
        else{
            this.files = files 
            this.files_count = files.length
        }

        // Limit 
        if(this.files_count < this.max_threads )
        {
            this.max_threads = this.files_count 
            this.queue_size = 6 
        }
    }


    /**
     * Create AWS S3 SDK Upload Manager
     * 
     * @param {Object} params ManagedUpload Initial Params
     * @returns {AWS.S3.ManagedUpload}
     * 
     */
    createUploadManager = (params , queueSizeControl = this.queue_size) => {
        const S3_upload = new AWS.S3.ManagedUpload({
            params: params,
            queueSize : queueSizeControl,
            partSize : this.part_size
        });

        this.file_queued.push(this.files[this.current_file_index] )
        return S3_upload
    }


    clearQueue = (file_response) => {

        function removeElement(array, elem) {
            var index = array.indexOf(elem);
            if (index > -1) {
                array.splice(index, 1);
            }
        }
        var file_name = file_response.Key  
        
        var fileIndex = this.in_queue_files.find( file => file.name == file_name )
        removeElement(this.in_queue_files, fileIndex ) 
        Logger( 'UPLOAD', fileIndex)
    }

    /**
     * 
     * Create a new thread to upload file
     * 
     * 
     * 
     * @param {Number} index file index 
     * @returns {AWS.S3.ManagedUpload}
     * 
     */
    UploadWorker = (index) => {

//        this.beginSpeedtest()

        /**
         * Check browser online or not
         * 
         */
        if( ! window.navigator.onLine ) {

            /**
             * Store current file index to Error Index Store
             * 
             */
            this.network_error_index = index
            
            /**
             * Skip current function 
             * 
             */
            return false
        }

        const file = this.files[index]

        if(!file) return 0 
        
        /**
         * 
         * 
         */
        this.total_upload_size += file.size

        /**
         * File Params
         * 
         */
        const params = {
            // 
            Bucket: this.config.Bucket,
            // 
            Key: `${this.username}/${file.webkitRelativePath ? file.webkitRelativePath : file.name}` ,
            // 
            Body:file
        }


        var file_queue_size = this.queue_size
        /**
         * Queue size control
         * 
         * More queue size for large file
         * 
         */
        if(file.size && file.size >= (1024 * 1024 * 1024))
        {
            file_queue_size = 4
        }

        /**
         * Create Uploader Manager
         * 
         */
        const _uploader = this.createUploadManager(params , file_queue_size )

        /**
         * Return uploading status
         * 
         */
        const current_uploading = {
            // Current uploading file data
            current_file : file,
            // Total Percentage not only current file
            percentages : Math.floor(( this.current_file_index / this.files_count ) * 100),
            // Return count of uploaded files
            files_uploaded : this.current_file_index + 1 ,
            
            // Total uploaded size
            total_uploaded_size : (this.total_upload_size / 1024 / 1024 / 1024 ).toFixed(3),

            // Threads
            thread_count : this.current_threads
        }


        /**
         * HOOK
         * Update Queue infomation
         * 
         *     .on("s3HttpTransfer", data => { //  })
         * 
         * 
         */
        this.emit("s3HttpTransfer", current_uploading)

        

        return _uploader
    }

    getListObjects() {
        var s3 = new AWS.S3()
        s3.listObjectsV2({
            Bucket : this.config.Bucket,
        }, function(err, data) {
            if (err) Logger( 'UPLOAD', err, err.stack); // an error occurred
            else     Logger( 'UPLOAD', data);           // successful response
          });
    }


    /**
     * Create event retry upload file 
     * 
     * @param {Number} index Index of file 
     */
    retryUpload(index) {

        Logger( 'UPLOAD', `Retrying to upload`)
        return this.UploadQueue()
    }   

    /**
     * Every loop, queue will get new file from Files store and push to [UploadWorker]
     * 
     * - Recursive-Looping.
     * 
     * 
     * If once == true
     *      Is meaning this loop just run just one time !
     *      No Recursive !!
     * 
     * 
     * @param {Boolean} once 
     * @returns Any
     */
    UploadQueue( once = false   ){

        if(this.addmore_file) return 0 ;
        
        var setup_thread =  this.max_threads
        
        Logger( 'UPLOAD', `FILE COUNT : `,this.files_count)
        Logger( 'UPLOAD', `FILE INDEX : `,this.current_file_index)
        Logger( 'UPLOAD', `MAX THREAD : `,this.max_threads)

        if( this.current_file_index == this.files_count && this.current_threads == 0   ) {


            /**
             * Reset properties
             * 
             */
            this.current_file_index = 0 
            this.files = [] 
            this.current_file_percent = {}
            this.current_threads = 0 
            this.files_count = 0 
            this.total_upload_size = 0 


            /**
             * 
             * Emit an event upload was done
             * 
             */
             this.emit("uploadDone", this.files_crashed)
             
            /** 
             * Emit status done
             */
            return 0 

        }
        
        // Upload Done
        if(this.current_file_index >= this.files_count   ) {
            Logger( 'UPLOAD', `exit : `,this.files_count)

            return 0
        } ;

        if( once == true ) setup_thread = 1 

        for( var index = 0 ; index < setup_thread ; index++ ) {
            Logger( 'UPLOAD', `Thread Count : ` , this.current_threads + 1)

            /**
             * Check UploadWorker count
             * 
             */
            if( this.current_threads >= this.max_threads  )
                /**
                 * Skip this loop and wait more 
                 * 
                 */
                return 0

            /**
             * 
             * Queue is has 1 or more of position to upload
             * 
             */
            else{
                this.current_threads++
                // Create a upload thread
                const upload_thread = this.UploadWorker(this.current_file_index)

                let __file_index = this.current_file_index
                let __file_name = this.files[__file_index].name

                /**
                 *
                 * 
                 *
                */  
                this.in_queue_files.push({
                    index: this.current_file_index ,
                    name : this.files[this.current_file_index].name, 
                    ...this.files[this.current_file_index]
                })

                /**
                 * Stop upload session and wait for internet online
                 * 
                 * 
                 */
                if( ! upload_thread ) return 0 

                /**
                 * Get Promise of Uploading worker
                 * 
                 */
                const UploadWorkerPromise =  upload_thread.promise()



                upload_thread.on("httpUploadProgress", data => {
                    /**
                     * Emit like this
                     * 
                     * [
                     *  {
                     *     file_name : "scene.max",
                     *     progress : 23
                     *  },
                     * 
                     *  {
                     *     file_name : "scene.max",
                     *     progress : 23
                     *  }
                     * 
                     * ]
                     */
                    // this.current_file_percent = {...data, file_index : __file_index ,file_name : __file_name }
                    this.current_file_percent = data

                    // Logger( 'UPLOAD', data)
                })
                
                // Upload promise
                UploadWorkerPromise
                
                /**
                 * When the file uploaded handle
                 */
                .then(
                    data =>{
                        this.current_threads--
                        Logger( 'UPLOAD', `KILL THREAD : ` , this.current_threads)
                        this.clearQueue(data)
                        // this.in_queue_files.find( file => file.name)
                        // Logger( 'UPLOAD', this.in_queue_files)
                        this.UploadQueue( true )
                        // Increase uploading count
                        return 
                    },
                    
                    error => {
                        return setTimeout(() => {
                            Logger( 'UPLOAD', `Retrying to upload`)
                            this.current_threads--
                            Logger( 'UPLOAD', `KILL THREAD : ` , this.current_threads)
                            this.UploadQueue( true )
                        }, 1000);
                    }
                )

                /**
                 * When file cannot upload to S3
                 * 
                 */
                // .catch( error => {
                    

                //     /**
                //      * Retry and popup notification to user here
                //      * 
                //      */
                     
                //     setTimeout(() => {
                //         Logger( 'UPLOAD', `Retrying to upload`)
                //         // this.current_threads--
                //         Logger( 'UPLOAD', `KILL THREAD : ` , this.current_threads)

                //         this.UploadQueue( true )
                //     }, 1000);
                
                // })
                
            }
            this.current_file_index++
        }
    }
}
export default Thread