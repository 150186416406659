import React, { useState } from 'react'
import {  useHistory } from "react-router-dom";
import {   MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBLink} from 'mdbreact'
import Stores from "../redux/store"

import QuickTips from '../components/QuickTips/QuickTips';
import AnalysisResults from '../forms/AppNewRenderJob/AnalysisResults';
import MaxParamSettings from '../forms/AppNewRenderJob/Analyzed/Max/ParamSettings';
import MaxRenderFlags from '../forms/AppNewRenderJob/Analyzed/Max/RenderFlags';
import LightManagement from '../forms/AppNewRenderJob/Analyzed/Max/LightsManagement';
import VrayOptions from '../forms/AppNewRenderJob/Analyzed/Max/Vray/Options';



export default function SceneAnalyze3DMax({scene}){

    /** Update to Store */
    
    Stores.dispatch({
        type : 'update_scene_json',
        ...scene 
    })

    console.log(Stores.getState().sceneAnalyze)
    
    // TODO : CHANGE FROM 1 to 0 WHEN CODE HAS DONE PLEASE
    const [step, setStep] = useState(1)
    const [analyzedScene, setAnalyzedScene] = useState(scene)
    
    var scene_data = scene 
    
    var history = useHistory()
    // TODO : REMOVE
    let path = scene.scene_full_path.split("\/").pop()

    // demo pluginsVersion
    const [pluginsVersion, setPluginsVersion] = useState("corona");
    /** Scene params next step */
    function nextStep() {
        if( step >= 0 && step < 3 ) return setStep(step+1)
    }

    /** Scene params back step */
    function backStep() {
        if( step > 0 && step <= 3 ) return setStep(step - 1)

        return history.goBack()
    }

    /** Pill Scene Params
     * 
     */
    function SceneParamsTabPane(){
        return (
        <div >
            <div className="text-light card-body bg-dark">
                <MDBNav className='nav-pills d-flex'>
                    <MDBNavItem>
                        <MDBLink to='#' active={step === 0} onClick={()=>setStep(0 )} link >
                            <span>0 - Analysis Results</span>
                        </MDBLink>
                    </MDBNavItem>
                    <i className="fad fa-chevron-right mx-2 my-auto"></i>

                    <MDBNavItem>
                        <MDBLink to='#' active={step === 1} onClick={()=>setStep(1)} link >
                            <span>1 - Scene Params</span>
                        </MDBLink>
                    </MDBNavItem>
                    <i className="fad fa-chevron-right mx-2 my-auto"></i>
                    <MDBNavItem>
                        <MDBLink to='#' active={step === 2} onClick={()=>setStep(2)} link>
                            <span>2 - Vray Options</span>
                        </MDBLink>
                    </MDBNavItem>
                    <i className="fad fa-chevron-right mx-2 my-auto"></i>
                    <MDBNavItem>
                        <MDBLink to='#' active={step === 3 } onClick={()=>setStep(3)} link>
                            <span>3 - Render Flags</span>
                        </MDBLink>
                    </MDBNavItem>
                </MDBNav>
                <hr />
                
                <MDBTabContent activeItem={step}>

                    {/** Scene Analysis Results  */}
                    <MDBTabPane tabId={0}>
                        <AnalysisResults data={scene.analysis_result}  />
                    </MDBTabPane>

                    <MDBTabPane tabId={1}>
                        {/** Pra */}
                        <MaxParamSettings data={scene_data}  onSceneParamsChange={ e => {
                            
                            /** update scene_data */
                            scene_data = { ...scene_data, scene_info : { ...scene.scene_info, ...e} };

                            console.log(scene_data) 
                        }} />
                        <LightManagement />
                    </MDBTabPane>
                    
                    <MDBTabPane tabId={2} >
                        <VrayOptions/>
                    </MDBTabPane> 

                    <MDBTabPane tabId={3} >
                        <MaxRenderFlags />
                    </MDBTabPane>
                </MDBTabContent>

            </div>
            <div className="py-2">
                
                <button onClick={()=>backStep()} className="btn btn-light shadow">
                    {/** Back button */}
                    <i className="fad fa-chevron-left me-2"></i><span className="mt-1">Back</span>
                </button>

                <button onClick={()=>nextStep()} className="btn btn-success shadow text-dark float-end">
                    {/** Next button */}
                    <span className="mt-1">{ step == 3 ? "Start render job" : "Next" }</span><i className="fad fa-chevron-right ms-2"></i>
                </button>
            </div>
        </div>)
    }

    return (
        <div >
            <div className="row">
                <div className="col-xl-6 col-12">
                    {/** Page Title  */}
                    <div className="h5 my-auto text-break">Add New Render Job: {path}</div>
                </div>
                <div className="col-xl-6 col-12">
                    <QuickTips />
                </div>
            </div>
            <br/>
            {/** Body Container */}
            {/**<TabbarNavigation /> */}
            {/** Analyze scene step setup pane */}
            <SceneParamsTabPane />
        </div>
    )
}