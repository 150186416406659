import React from 'react'
import SpaceBreadcrumb from '../Spaces/Breadcrumb/SpaceBreadcrumb'
import FileManager from "../FileManager/index"
import FolderSkeleton from '../Spaces/Filemanager/FolderSkeleton'


export default function  GridView(props) {


    const {isFolderEmpty, path , isLoading, data } = props 

    /**
     * Search components
     * 
     * @returns {React.Component}
     */
    function tableActions() {
        return <div></div>
    }

    /**
     * When function return empty data.
     * 
     * @returns {React.Component}
     */
    function NoData() {
        return <div></div>
    }

    return (
        <div className="fade-in rounded overflow-hidden" style={{backgroundColor:"#424242",minHeight:600}}>
            
            {/**  Table headers   */}
            <div className="sc-fnVZcZ jZBGyK rdt_TableHeader" role="heading" aria-level="{1}">
                <div className="sc-fFSPTT fgINsU">
                    {/** Breadcrumbs -- Table Breadcrumb  */}
                    <SpaceBreadcrumb /> 
                </div>
                <div className="sc-bkbkJK ffLupP">
                    {/** Table actions  */}
                    {tableActions()}
                </div>
            </div>
    
            <div className="rdt_TableBody py-2">
                <div className="row p-0 m-0">
    
                    {/**  If current folder is empty , then show NoData section   */}
                    { ( ( path == "" ) && isFolderEmpty ) ? <NoData/> : <div></div> }
    
                    {/** Loading skeleton, before got data from api  */}
                    {(isLoading) ? <FolderSkeleton /> : <div></div>}
    
                    {/** If folder empty and is root path, show upload dropdown */}
                    {(isFolderEmpty && (path != "")) ? (
                        <div className="w-100 d-flex justify-content-center py-5 mt-5">
                            <div className="py-5 my-5 align-items-center" style={{ cursor: "default", userSelect: "none" }}>
                                <img src="https://statics.superrendersfarm.com/app/assets/media/image/folder-empty.png" alt="Folder empty" />
                                <h3 className="text-center mt-2">This folder is empty !</h3>
                            </div>
                        </div>
                    ) : (
                        <div>{/** Empty section */}</div>
                    )}
    
                    {/**  List content of a folders   */}
                    <FileManager  files={data} />
    
                </div>
            </div>
        </div>
    )
}