import React  from 'react'

export default function WOAFrameStep({}) {

    return (
        <div className="form-group">
            <label id="frame_steps_label" className="form-label semibold">Frame Step*</label>
            <div className="form-control-wrapper">
                <input id="frame_steps" type="number" min={1} className="form-control srf_require_field" placeholder="Frame Steps"  />
                <small className="text-muted" />
            </div>
        </div>
    )
}