import React from 'react'

export default function WOAMaxBatchToRender() {
    return (
        <div id="batch_form_group" className="form-group mb-0">
            <label id="batch_label" className="form-label semibold">Batch To Render</label>
            <div className="form-control-wrapper">
            <input id="batch" type="text" className="form-control" placeholder="Leave blank if not use"  />
            </div>
        </div>
    )
}