import React from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { MDBBtn } from "mdbreact";
function SpaceBreadcrumb (){ 

  var {path} = useParams()


  function listBreadcrum(paths){
    
    const path_array = paths.split("/")

    return path_array.map( (link, index ) => {
      if( index == path_array.length -1 ) 
        return <li className="breadcrumb-item " aria-current="page" key={index}><MDBBtn className="text-muted" style={{cursor: "default"}} disabled>{link}</MDBBtn></li>
      return <li className="breadcrumb-item " aria-current="page" key={index}><Link to={`/${paths.substring(paths.lastIndexOf(link) , -1 )}${link}`} ><MDBBtn  className="brc-spaces" >{ link }</MDBBtn></Link></li>
    })
  }


  if(!path) path = "" 

  return (

    <nav aria-label="breadcrumb" className="pt-2">
      <ol className="breadcrumb m-0 h6">
        <li className="breadcrumb-item">
          <Link to="/" ><MDBBtn color="dark" className="btn-floating" ><i className="ti-home"></i></MDBBtn></Link>
        </li>
        {listBreadcrum(path)}
      </ol>
    </nav>
  )
}
export default SpaceBreadcrumb ;