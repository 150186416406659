import React from 'react'
import WOACamera from '../../ParamSettings/Camera'
import WOARenderOutputFile from '../../ParamSettings/RenderOutputFile'


export default function WOAMaxStillImage({}) {
    return (
        <div className="row">
            <div className="col-lg-3" id="image_width_form_group">
                <div className="form-group">
                    <label id="image_width_label" className="form-label semibold">Width*</label>
                    <div className="form-control-wrapper">
                        <input id="image_width" type="number" className="form-control" placeholder="Image Width"  />
                        <small className="text-muted" />
                    </div>
                </div>
            </div>
            <div className="col-lg-3" id="image_height_form_group">
                <div className="form-group">
                    <label id="image_height_label" className="form-label semibold">Height*</label>
                    <div className="form-control-wrapper">
                        <input id="image_height" type="number" className="form-control" placeholder="Image Height"  />
                        <small className="text-muted" />
                    </div>
                </div>
            </div>
            <div className="col-lg-6" id="renderoutput_file_form_group">
                <WOARenderOutputFile />
            </div>
            <div className="col-lg-12">
                <WOACamera />
            </div>
        </div>
    )
}