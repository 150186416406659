import React, { useState } from 'react'
import jwt from 'jsonwebtoken';
import AWS from "aws-sdk"
import Translate from '../../../Translate';
const _token = localStorage.getItem("jwt")


export default function CreactFolder({}) {
  const [simulateDirectory, setSimulateDirectory] = useState()

  const handleCreateDirectory = event => {

    /**
     * Detect all case of Disk Drive Letter style
     * 
     * Supported :
        * D:\ 
        * (D:)
        * D/
        * d/
        * D:
        * (d)
        * (      D    )
        * (   D   :/  )
        * D    :/
     * 
     * @returns String[A-Z]
     */
     const getDiskDriveName = () => {
      return /(^[a-zA-Z]|\(([a-zA-Z])\:\)|\(([a-zA-Z])\)|\(.+?([A-Z]).+?\)|(([a-zA-Z]).+?\:\)))|([\s][a-zA-Z])$|(\s([a-zA-Z])\/)|(\s([a-zA-Z])\s)/gmi
              .exec(this.state.simulate_directory)
              .filter( text =>  text ? true : false  )
              .pop()
              .toUpperCase()
    }

    const parseDirectoryPath = (  ) => {
      return `${jwt.decode(_token).username}/${getDiskDriveName()}/`
    }

    const AWS_SDK_CONFIG = {
      region: 'ap-southeast-1',
      // useAccelerateEndpoint: true,
      credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'ap-southeast-1:c14793e7-5e11-455d-9d64-886e71975a03'
      }),
      Bucket : 'clr-official-spaces',
    }

    AWS.config.update(AWS_SDK_CONFIG)

    const S3 = new AWS.S3

    // return console.log(parseDirectoryPath())

    S3.putObject({
      Key : parseDirectoryPath(),
      Bucket : AWS_SDK_CONFIG.Bucket
    }, (err , data ) => {
      if(data.ETag) window.location.reload()
    })
  }
    return(
        <div className="card-body rounded bg-dark">
          <div className="fst-italic">
            <p>* <Translate text="Simulate your local directory on our farm. For example, your scene is located at"/> "D:\projects\mybuilding\"; <Translate text="and its textures are located at"/> "Z:\textures\texture_collection\".<br/>
             <Translate text="Create these two directories, and upload your scene to"/> "D:\projects\mybuilding\"<Translate text="and textures to"/> "Z:\textures\texture_collection\".</p>
            <p>* <Translate text="Examples"/>: "D:/projects/mybuilding", or "D/projects/mybuilding" or "D:\projects\mybuilding"</p>
          </div>
          <div className="form-group d-lg-flex d-block my-4">
            <div className="input-group">
              <span className="input-group-text" id="input_create_folder" style={{backgroundColor: "#202124"}}><i className="fas fa-folder"  /></span>
              <input type="text" className="form-control" placeholder="e.g: D:\projects\mybuilding" aria-label="Folder path" aria-describedby="input_create_folder" onChange={ e => setSimulateDirectory(e.target.value) } value={simulateDirectory}/>
            </div>
            
            <button id="btn_add_new_folder" className="btn btn-inline btn-success text-dark" data-style="expand-right" onClick={ ()=>handleCreateDirectory() }>
              <Translate text="Save" />
            </button>
          </div>
        </div>
    )
}