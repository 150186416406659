import React, { Component, useEffect } from "react";
import { Redirect, useHistory } from "react-router";
import axios from "axios";
import env from "../configs/env";
import Logger from "../utils/debug";
import Stores from "../redux/store";

/**
 * Register your middlewares here
 * 
 * @param {React.Component} RouteComponent 
 * @returns {Bool}
 * 
 */

function gateKeeper ( RouteComponent , middlewares   ){

  const history = useHistory()

  var middlewares = []

  if( middlewares ) {
    if( Array.isArray(middlewares) ) {

      /**
       * Validate all middleware
       * 
       */
      for ( var middleware_count = 0 ; middlewares.length ; middleware_count++){
        
        /**
         * If current middleware return a false state.
         * kick() will be kick current user to login page
         * 
         */
        if( ! middlewares[middleware_count]) return kick() ;

      }

    }else{
      
      const _middleware = middlewares

      _middleware()

    }
    
  }

  /**
   * 
   * Redirect to Login Views if user not login or jwt expired 
   */
  if(!localStorage.getItem("token"))
  {
    return (<Redirect to="/login" />)
  }



  const Jwt = localStorage.getItem("token") ? localStorage.getItem("token") : null 


  /**
   * If jwt validate fail, go to login
   * 
   */
  if( ! Jwt) return <Redirect to="/login" />


  /**
   * 
   * Get User Working Folder
   * 
   * 
   */
  function getUwf(){

    axios.get(
      `${env.APP_API}/user/me`,
      {
        headers : {
          Authorization : `Bearer ${Jwt}`
        }
      }
    ).then( apiRes => {

      if( apiRes.data.user) {
        /**
         * Check if user has been banned !!
         * 
         * 
         */
        if(apiRes.data.user.flag_enabled == 0){
          /**
           * Debug
           * 
           */
          Logger("MIDDLEWARE:ACCOUNT:DISABLED", "Your account has been disabled by admin")

          /**
           * 
           */
          history.push("/login")
        }

        // Logger('LOGIN',apiRes.data.user)
        Stores.dispatch({
          type : "INITIAL_USER_DATA",
          credits : apiRes.data.user.render_credits,
          uwf : apiRes.data.user.srfspaces,
          fullname : apiRes.data.user.full_name,
          cloudFolderType : apiRes.data.user.cloud_type,  // GoogleDrive || Dropbox
            cloudFolderId : apiRes.data.user.cloud_folder_id, // if user define transfer method, this is user's folder id.
            cloudFolderName : apiRes.data.user.working_folder_name, // this is cloud storage folder's name 
        })
        
        localStorage.setItem("uwf", apiRes.data.user.srfspaces)
        
      }
    })
    .catch(res => {
      /**
       * 
       * Token error or expired !
       * 
       */
      localStorage.clear()

      /**
       * Ban account handle
       * 
       */
      alert("Your session has been expired! please login again to continue")

      /**
       * 
       * 
       */
      return history.push("/login")

    }) 
  }

  useEffect(() => {
    
    Logger("MIDDLEWARE:OK", "Updating user states" )
    getUwf()
    // if( Stores.getState().UserStates.uwf == "" )
    // {
    //   Logger("WORKING", Stores.getState().UserStates.uwf)
    //   getUwf()
    // } 

  }, []);

  

  /**
   * this is a spring ! passed all gatekeeper.
   * Go render destination 
   * 
   */
  return RouteComponent

  /**
   * Redirect to Login
   * 
   * @returns {React.Redirect}
   */
  function kick(){
    return (<Redirect to="/login" />)
  }
  
};

export default gateKeeper;
