import React from 'react'
import WOAEndFrame from '../ParamSettings/EndFrame'
import WOAFrameStep from '../ParamSettings/FrameStep'
import WOARenderOutputFile from '../ParamSettings/RenderOutputFile'
import WOAStartFrame from '../ParamSettings/StartFrame'
import WOARenderPriority from '../ParamSettings/RenderPriority'
import WOABlenderSoftwareVersion from './SoftwareVersion'
import WOABlenderEngineVersion from './EngineVersion'
import WOABWorkingFolderPath from '../ParamSettings/WorkingFolderPath'

export default function WOABlender () {
  return(
    <div>
      <div className="row" id="renderer_environment_container" >
        <div className="col-lg-6">
        <WOABlenderSoftwareVersion /> 
        </div>
        <div className="col-lg-6">
          <WOABlenderEngineVersion />
        </div>
      </div>
      <WOABWorkingFolderPath />
      <div className="row">
        <div className="col-lg-2" id="start_frame_form_group">
          <WOAStartFrame />
        </div>
        <div className="col-lg-2" id="end_frame_form_group">
          <WOAEndFrame />
        </div>
        <div className="col-lg-3" id="frame_steps_form_group">
          <WOAFrameStep />
        </div>
        <div className="col-lg-5" id="renderoutput_file_form_group">
          <WOARenderOutputFile />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10">
          <WOARenderPriority />
        </div>
        <div className="col-lg-2 text-right mt-auto">
          <button className="btn btn-success btn-block text-dark form-group">
          <i className="fad fa-star-of-david me-2"></i>Start Render Job
          </button>
        </div>
      </div>
    </div>
  )
}
