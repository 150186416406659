import React  from 'react'
import VrayFrameBuffer from './FrameBuffer';
import VrayGlobalIlluminationMode from './GlobalIlluminationMode';
import VrayImageSamplesAndFilter from './ImageSamplesAndFilter';

export default function VrayOptions({}) {

    return (

        <div className="accordion custom-accordion">
  <div className="accordion-row open">
    <a href="#" className="accordion-header">
      <span>Global Illumination Mode animation prepass/rendering</span>
      <i className="accordion-status-icon close fa fa-chevron-up"></i>
      <i className="accordion-status-icon open fa fa-chevron-down"></i>
    </a>
    <div className="accordion-body"><VrayGlobalIlluminationMode /></div>
  </div>
  <div className="accordion-row">
    <a href="#" className="accordion-header">
      <span>Image Samples and Image Filter</span>
      <i className="accordion-status-icon close fa fa-chevron-up"></i>
      <i className="accordion-status-icon open fa fa-chevron-down"></i>
    </a>
    <div className="accordion-body"><VrayImageSamplesAndFilter /></div>
  </div>
  <div className="accordion-row">
    <a href="#" className="accordion-header">
      <span>VRay Frame Buffer</span>
      <i className="accordion-status-icon close fa fa-chevron-up"></i>
      <i className="accordion-status-icon open fa fa-chevron-down"></i>
    </a>
    <div className="accordion-body"><VrayFrameBuffer/></div>
  </div>
</div>
            
    )
}