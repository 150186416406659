import React from 'react'
import WOARenderPriority from '../ParamSettings/RenderPriority'
import WOAAESoftwareVersion from './SoftwareVersion'
import WOAAEEngineVersion from './EngineVersion'
import WOARangeFrom from '../ParamSettings/RangeForm'
import WOATo from '../ParamSettings/To'
import WOAEveryNthFrame from '../ParamSettings/EveryNthFrame'
import WOARenderOutputFileNoExtension from '../ParamSettings/RenderOutputFileNoExtension'
import WOABWorkingFolderPath from '../ParamSettings/WorkingFolderPath'


export default function WOAAfterEffect () {
    return(
      <div>
        <div className="row" id="renderer_environment_container" >
          <div className="col-lg-6">
            <WOAAESoftwareVersion />
          </div>
          <div className="col-lg-6">
            <WOAAEEngineVersion />
          </div>
        </div>
      
        <WOABWorkingFolderPath />
        <div className="row">
          <div className="col-lg-2" id="start_frame_form_group">
            <WOARangeFrom />
          </div>
          <div className="col-lg-2" id="end_frame_form_group">
            <WOATo />
          </div>
          <div className="col-lg-3" id="frame_steps_form_group">
            <WOAEveryNthFrame />
          </div>
          <div className="col-lg-5" id="renderoutput_file_form_group">
            <WOARenderOutputFileNoExtension />
          </div>
      </div>
      
        
      <div className="row">
        <div className="col-lg-10">
          <WOARenderPriority />
        </div>
        <div className="col-lg-2 text-right mt-auto">
          <button className="btn btn-success btn-block text-dark form-group">
          <i className="fad fa-star-of-david me-2"></i>Start Render Job
          </button>
        </div>
      </div>
      
    </div>
        )
}