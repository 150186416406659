import axios from 'axios'
import React from 'react'
import { useState ,useEffect  } from 'react'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import env from '../../configs/env'
import Logger from '../../utils/debug'
import Md5 from 'crypto-js/md5';
import RelativeTime from '../../components/RelativeTime/RelativeTime'
import { connect } from 'react-redux'
import Stores from '../../redux/store'
import DashboardJobButton from '../../components/RenderDashboard/JobButton'
import DashboardJobName from '../../components/RenderDashboard/JobName'
import DashboardStatus from '../../components/RenderDashboard/Status/Status'

function ListSceneAnalysis({AnalyzeScenes}) {

    const [listAnalysis, SetlistAnalysis] = useState([])

    /**
     * Run once time 
     * 
     */
    useEffect(() => {
        getListAnalysis()
        
    }, [])



    /**
     * Listen changed from store 
     * 
     */
    if(AnalyzeScenes){
        /**
         * Stop update
         */
        Stores.dispatch({
            type : 'RELOADED_ANALYZE_SCENE'
        })       

        getListAnalysis()
        Logger("RE_RENDER_SCENE_ANALYZE_LIST:WARN", "true")

    }


    function getListAnalysis(){
        axios.get(`${env.APP_API}/analyze-job/list/scene-analyze`, {
            headers : {
                Authorization : `Bearer ${localStorage.getItem("token")}`
            }
        }).then(res => {
            /**
             * 
             * 
             */
            Logger("LIST_SCENE:RESPONSE:SUCCESS", "Loaded scene list")

            SetlistAnalysis(res.data.data)
        })
    }


    /**
     * Render log from sceneResult
     * 
     * @param {Object} sceneResult 
     */
    function RenderSceneLog( {sceneResult} ){

        /**
         * 
         */
        let SceneLogs = JSON.parse(sceneResult) 

        // Logger("SCENE_LOG:WARNING", SceneLogs )
        /**
         * List log infomation
         * 
         */
        function InfoList(){
            let list  = SceneLogs.info.map(e => Object.values(e) )

            // Check list is empty
            if(list.length == 0) return <div></div>

            return (
            <div  className="col-lg-12">
                <h6 className="font-weight-bold ms-3 text-info mt-2"><i className="fad fa-info"></i> Info</h6>
                <div className="ms-3">
                    {list.map( warn => <p  className="border mt-1 rounded p-2" > <i className="fad fa-angle-double-right font-size-13 p-2"></i> {warn[0].message}</p> )}
                </div> 
            </div>)
        }

        /**
         * Render warning list
         * 
         */
        function WarningList(){
            let list  = SceneLogs.warning.map(e => Object.values(e) )
            
            // Check list is empty
            if(list.length == 0) return <div></div>

            return (
            <div  className="col-lg-12">
                <h6 className="font-weight-bold ms-3 text-warning mt-2"> <i className="fad fa-comment-alt-exclamation"></i> Warning</h6>
                <div className="ms-3">
                    {list.map( warn =>
                        <div className="border mt-1 rounded p-2">
                            <p className="">
                                <i className="fad fa-angle-double-right font-size-13 p-2"></i>{warn[0].message}
                            </p>

                            { warn[0].items.length != 0 ? <div className="border-dark ms-4 mt-1 rounded">
                                {warn[0].items.map( file => (
                                
                                    <p className="my-2"><i className="fad fa-file-exclamation text-warning me-2" />{file}</p>
    
                                ))}    
                            </div> : <div></div> }
                        </div>
                    )}
                </div> 
            </div>)
        }

        /**
         * Error List
         * 
         */
        function ErrorList(){
            let list  = SceneLogs.error.map(e => Object.values(e) )
            
            
            // Check list is empty
            if(list.length == 0) return <div></div>

            return (
            <div className="col-lg-12" >
                <h6 className="font-weight-bold ms-3 text-danger mt-2"><i className="fad fa-sensor-alert"></i> Error</h6>
                <div className="ms-3">
                    {list.map( warn => <p  className="border mt-1 rounded p-2" > <i className="fad fa-angle-double-right font-size-13 p-2"></i> {warn[0].message}</p> )}
                </div> 
            </div>)
        }


        /**
         * Return with error or warning or info 
         * 
         * 
         */
        if(sceneResult) return (
            <div className="row">
                <ErrorList />
                <WarningList />
                <InfoList />
            </div>
        )


        /**
         * No Error or warning
         * 
         */
        return <b>Your scene looks good! Please continue.</b>
        
    }


    /**
     * Re-analyze scene 
     * 
     */
    function reAnalyzeScene(sceneId){
        return swal({
            title: "Re-Analyze your scene?",
            text: "It is recommended that you should analyze the scene before starting to render your project!",
            icon: "warning",
            buttons: {
              cancel: true,
              analyze: {
                text: "Re-Analyze now",
                value: "analyze",
              },
            }
          }).then((value) => {
              
            if (value === "analyze") {
                axios.post(`${env.APP_API}/analyze-job/restart/scene-analyze/${sceneId}`,{},
                {
                    headers: {
                        Authorization : `Bearer ${localStorage.getItem("token")}`
                    }
        
                }).then(res => {
                    Logger("REANALYZE_SCENE:SUCCESS", "Your scene is analyzing")
                    if(res.data.status === "success") {
                        swal({
                            title: "Analyzing your scene",
                            text: "",
                            icon: "info"
                        })
                        setTimeout(() => {
                            getListAnalysis()
                        }, 1000);
                          
                    }
                })
            }
            
          })
        
    }

    /**
     *  
     * @param {Number} sceneId Analyze scene id
     */
    function deleteSceneAnalyze(scenePath){

        const CryptPathId = Md5(scenePath).toString() 

        return swal({
            title: "Delete analyze your scene?",
            text: "It is recommended that you should analyze the scene before starting to render your project!",
            icon: "warning",
            buttons: {
              cancel: true,
              delete: {
                text: "Delete now",
                value: "delete",
                className:"bg-danger"
              },
            }
          }).then((value) => {

            if (value === "delete") {
                axios.delete(`${env.APP_API}/analyze-job/delete/scene-analyze/${CryptPathId}`,
                {
                    headers: {
                        Authorization : `Bearer ${localStorage.getItem("token")}`
                    }
        
                }).then(res => {
                    
                    Logger("REANALYZE_SCENE:SUCCESS", "Delete successfully !")

                    if(res.data.status === "success") {
                        getListAnalysis()
                        swal({
                            title: "Delete successfully !",
                            text: "",
                            icon: "success"
                        })
                    }
                })
            }
            
          })
    }


    /***
     * 
     * 
     */
    function AnalyzeRow(job){
        const [downIcon, setDownIcon] = useState("");
        function toggleDownIcon(){
            downIcon !== "" ?setDownIcon(""):setDownIcon("down")
        }
        return (
            <div className="rounded bg-dark my-2 p-2">
                <div className="row">
                    <div className="col-lg-7 my-auto">
                        <div className="d-flex">
                        <button className="btn text-light my-auto me-2 d-none d-lg-block" onClick={()=>toggleDownIcon()}>
                            <i className={downIcon+" fad fa-chevron-right animate-icon"} ></i>
                        </button>
                        <DashboardJobName jobID={job.id}  Name={job.scene_path.split("/").pop()} Frame="0 - 899" Camera="VRayCam001" Priority="Bronze" RenderBy="Cpu" Submitted={<RelativeTime time={(new Date( job.created_at)).getTime()} />}
                        Output={job.scene_path.replace( `${localStorage.getItem("uwf")}`, "" ).replace("//", "/") } />
                        </div>
                    
                    </div>
                    <div className="col-lg-3 my-auto">
                        <DashboardStatus Status={job.scene_analysis_history_status }  />
                    </div>
                    <div className="col-lg-2 my-auto">
                        <button className="btn bg-info-bright btn-floating text-light d-inline-flex d-lg-none" onClick={()=>toggleDownIcon()}>
                            <i className={downIcon+" fad fa-chevron-right animate-icon"} ></i>
                        </button>
                        <div className="float-end">
                            <div className="btn-group">
                            <Link to={"/scene-analyze/"+job.id} type="button" className="btn bg-success-bright btn-block" >
                                <i className="fad fa-play me-2"></i><span className="mt-1">RENDER NOW</span>
                            </Link>
                            <button type="button" className="btn bg-success-bright text-white dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="sr-only">Toggle Dropdown</span>
                            </button>
                            <div className="dropdown-menu  dropdown-menu-right">
                                <button onClick={ () => reAnalyzeScene(job.id) } type="button" className="dropdown-item bg-warning-bright my-1"><i className="fad fa-repeat me-2"></i>REANALYZE</button>
                                <button onClick={ () => deleteSceneAnalyze(job.scene_path) }  type="button" className="dropdown-item bg-danger-bright my-1"><i className="fad fa-trash me-2"></i>DELETE</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    {downIcon!==""?<div className="col-lg-12 fade-in">
                        <div className="card card-body m-0">
                            <RenderSceneLog sceneResult={job.analyze_result} />
                        </div>
                    </div>: ""}
                </div>
            </div>
        )
    }
    function ParseListAnalysis(  ){
        
        return listAnalysis.map( job => (
            AnalyzeRow(job)
        ))
    }
    return(
        <ParseListAnalysis />
    )
}


export default connect(  ({JobStores}) =>({
    
    AnalyzeScenes : JobStores.AnalyzeScenes

}))( React.memo(ListSceneAnalysis) )