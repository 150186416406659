import React ,{useState} from 'react'

export default function VrayGlobalIlluminationMode({}) {
    const [giMode , setGIMode] = useState(false);
    const [vray_primary_engine , setVray_primary_engine] = useState("2");
    const [vray_secondary_engine , setVray_secondary_engine] = useState("3");
    const [adv_irradmap_mode , setAdv_irradmap_mode] = useState("0");
    const [lightcache_mode, setLightcache_mode] = useState("0")
    const [enable_GI , setEnable_GI] = useState(true);
    function Enable_GI() {
        enable_GI!== true ? setEnable_GI(true) : setEnable_GI(false)
   }
    function GIMode() {
         giMode!== true ? setGIMode(true) : setGIMode(false)
    } 

    return (

        <div>
            
            <div className="card card-body">
                <div className="h5 font-italic">System will render your scene with 2 steps:</div>
                <p>- Step 1: render presspass mode</p>
                <p>- Step 2: render final step</p>
                <a href="https://superrendersfarm.com/account/scene-analyze/2044333#" className="text-success">see more how it work</a>
            </div>
            <div className="custom-control custom-switch custom-checkbox-success mt-2">
                <input type="checkbox" className="custom-control-input" id="giMode" checked={giMode} />
                <label className="custom-control-label" style={{userSelect: "none" }} htmlFor="giMode" onClick={()=>GIMode()}>GI Mode animation Prepass/Rendering</label>
            </div>                       
            <div className={giMode===false?'d-block fade-in form-group row mt-2':'d-none fade-in'}>
                <div className="col-lg-10">
                    <div className="h5">Global Illumination( from scene )</div>
                    <div className="form-group">
                        <div className="custom-control custom-checkbox custom-checkbox-success">
                            <input type="checkbox" className="custom-control-input" id="Enable_GI" checked={enable_GI === true } onClick={()=>Enable_GI()} />
                            <label className="custom-control-label" for="Enable_GI" style={{userSelect: "none" }}>Enable GI</label>
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-lg-2 my-auto">
                           <label>Primary engine</label>
                        </div>
                        <div className="col-lg-4">
                            <select id="vray_primary_engine" className="form-control" name="gi_primary_type" value={vray_primary_engine} onChange={e => setVray_primary_engine(e.target.value)}>
                                <option value="0">Irradiance map</option>
                                <option value="1">Photon map</option>
                                <option value="2">Brute force</option>
                                <option value="3">Light cache</option>
                            </select>
                        </div>
                        
                      
                        <div className="col-lg-2 my-auto text-lg-right">
                            <label>Secondary engine</label>
                        </div>
                    
                        <div className="col-lg-4">
                            <select id="vray_secondary_engine" className="form-control" name="gi_secondary_type"  value={vray_secondary_engine} onChange={e => setVray_secondary_engine(e.target.value)}>
                                <option value="0">None</option>
                                <option value="1">Photon map</option>
                                <option value="2">Brute force</option>
                                <option value="3">Light cache</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {giMode===false&&vray_primary_engine==="0"?(
                <div className="card">
                    <div className="card-header">Irradiance Map</div>
                    <div className="card-body">
              
                        <div className="form-group row">
                            <div className="col-lg-10">
                                <div className="row">
                                    <label className="col-lg-2 col-form-label my-auto">Precomputation</label>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="form-group m-0">
                                                    
                                                    <select id="vray_mode_irradiance_map" className="form-control" name="adv_irradmap_mode"
                                                    value={adv_irradmap_mode} onChange={e => setAdv_irradmap_mode(e.target.value)}>
                                                        <option value="0" >Single frame</option>
                                                        <option value="1">Multiframe incremental</option>
                                                        <option value="2">From file</option>
                                                        <option value="3">Add to current map</option>
                                                        <option value="4">Incremental add to current map</option>
                                                        <option value="5">Bucket mode</option>
                                                        <option value="6">Animation (prepass)</option>
                                                        <option value="7">Animation (rendering)</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={adv_irradmap_mode==="2"||adv_irradmap_mode==="7"?"form-group row":"d-none"}>
                            <div className="col-lg-10">
                                <div className="row">
                                    <label className="col-lg-2 col-form-label my-auto">File path <span className="text-danger">*</span></label>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="input-group">
                                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="c:\log\temp.cgd" readOnly />

                                                <div className="input-group-prepend">
                                                    <button className="input-group-text">Browse File</button>
                                                </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) :""}
            {giMode===false&&vray_primary_engine==="3" || giMode===false&& vray_secondary_engine==="3"?(
                <div className="card">
                    <div className="card-header">Light Cache</div>
                    <div className="card-body">
              
                        <div className="form-group row">
                            <div className="col-lg-10">
                                <div className="row">
                                    <label className="col-lg-2 col-form-label my-auto">Precomputation</label>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="form-group m-0">
                                                    <select id="vray_mode_light_cache" name="lightcache_mode" className="form-control"
                                                    value={lightcache_mode} onChange={e => setLightcache_mode(e.target.value)}>
                                                        <option value="0" selected="">Single frame</option>
                                                        <option value="1">Fly through</option>
                                                        <option value="2">From file</option>
                                                        <option value="3">Progressive path tracking</option>
                                                    </select>
                                                   
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={lightcache_mode==="2"?"form-group row":"d-none"}>
                            <div className="col-lg-10">
                                <div className="row">
                                    <label className="col-lg-2 col-form-label my-auto">File path <span className="text-danger">*</span></label>
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="input-group">
                                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="c:\log\temp.cgd" readOnly />

                                                <div className="input-group-prepend">
                                                    <button className="input-group-text">Browse File</button>
                                                </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) :""}
        </div>
    )
}