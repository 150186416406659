import React, { useState } from 'react'
import { connect } from 'react-redux';
import Stores from '../../../../../redux/store';
import ScenePath from '../../ScenePath';
import MaxOutputSettings from '../OutputSettings';
import MaxRenderTypeAndFrame from '../Vray/RenderTypeAndFrame';
import MaxResolution from '../Vray/Resolution';


function MaxParamSettings( props ) {


    const {
    
        data , // state from redux store for data
        
        renderwidth , // state from redux store for renderwidth
        
        renderheight, // state from redux store for renderheight
        
        camera, // state from redux store for camera
        
        output_grammar_corection, // state from redux store for output_grammar_corection
        
        gamma_correction, // state from redux store for gamma_correction
        
        input_gamma, // state from redux store for input_gamma
        
        output_gamma, // state from redux store for output_gamma
        
        outputNameFile, // state from redux store for outputNameFile
        
        output_extension, // state from redux store for output_extension
        
        ratio , // state from redux store for ratio
        
        job_type , // state from redux store for job_type
        
        start_frame , // state from redux store for start_frame
        
        end_frame , // state from redux store for end_frame
        
        frame_steps , // state from redux store for frame_steps
        
        frame , // state from redux store for frame
        
        single_render_tile , // state from redux store for single_render_tile
        
    }  = props
    
    /** Scene params options */
    const [path, setPath] = useState( data.scene_full_path ? data.scene_full_path :  "" )
    
    /** Resolution params */
    const [ResolutionWidth, SetResolutionWidth] = useState( renderwidth || data.scene_info.scene_param.width)
    const [ResolutionHeight, setResolutionHeight] = useState( renderheight || data.scene_info.scene_param.height)
    const [ResolutionRatio, setResolutionRatio] = useState(1);


    /** Render Frame */
    const [RenderType, setRenderType] = useState("multi")
    const [StartFrame, setStartFrame] = useState(start_frame)
    const [EndFrame, setEndFrame] = useState( end_frame )
    const [IncrementalStep, setIncrementalStep] = useState(frame_steps)
    const [Frame, SetFrame] = useState(start_frame)

    /** Ouput Settings  */
    const [OutputCamera, setOutputCamera] = useState(null)
    const [OutputFileName, setOutputFileName] = useState(null)
    const [OutputExtension, setOutputExtension] = useState(null)

    /** Gamma correction */
    const [isGammaCorrection, setIsGammaCorrection] = useState(gamma_correction)
    const [inputGamma, setInputGamma] = useState(input_gamma)
    const [outputGamma, setOutputGamma] = useState(output_gamma)


    return (
        <div>

            {/** Test path  */}
            <ScenePath  path={ResolutionWidth} />

            <MaxResolution
                /** Width state and update */
                width={ResolutionWidth}
                updateWith={ e => {
                    SetResolutionWidth(e)
                    Stores.dispatch({ type : "update_renderwidth"  , renderwidth : e  } )
                } }

                /** Update Height state */
                height={ResolutionHeight}
                updateHeight={ e => {
                    /** Update state */
                    setResolutionHeight(e)
                    /** Update to store */
                    Stores.dispatch({ type : "update_renderheight"  , renderheight : e  } )
                }}

                /** Update State ratio */
                ratio={ResolutionRatio}
                updateRatio={ e => { 
                    setResolutionRatio(e)
                    Stores.dispatch({ type : "update_ratio" , ratio : e })
                }}
                />
            
            {/** Render Type and frames */}
            <MaxRenderTypeAndFrame

                /** Render Type States */
                renderType={RenderType}
                updateRenderType={e => {
                    /** Update React state */
                    setRenderType(e)
                    /** Update Redux Store */
                    Stores.dispatch({ type : "update_render_type"  , render_type : e  } )
                }}

                /** Render Frames */
                /** Start frames */
                startFrame={StartFrame}
                updateStartFrame={ e => {
                    /** Update React state */
                    setStartFrame(e)
                    /** Update Redux Store */
                    Stores.dispatch({ type : "update_start_frame"  , start_frame : e  } )
                }}

                /** End Frame */
                endFrame={EndFrame}
                updateEndFrame={ e => {
                    /** Update React state */
                    setEndFrame(e)
                    /** Update Redux Store */
                    Stores.dispatch({ type : "update_end_frame"  , end_frame : e  } )
                }}

                /** Single Frame */
                frame={Frame}
                updateFrame={e => {
                    /** Update React state */
                    SetFrame(e)
                    /** Update Redux Store */
                    Stores.dispatch({ type : "update_frame"  , frame : e  } )
                }}
                
                /** Incremental step */
                incrementalStep={IncrementalStep}
                updateIncrementalStep={ e => {
                    /** Update React state */
                    setIncrementalStep(e)
                    /** Update Redux Store */
                    Stores.dispatch({ type : "update_frame_steps"  , frame_steps : e  } )
                }}

            />

            {/** output settings */}
            <MaxOutputSettings
                data={data}
                /** output camera */
                outputCamera={ OutputCamera }
                updateOutputCamera={  e => {
                    /** Update React state */
                    setOutputCamera(e) 
                    /** Update Redux Store */
                    Stores.dispatch({ type : "update_OutputCamera"  , OutputCamera : e  } )
                }}

                /** output file name */
                outputFileName={ OutputFileName }
                updateOutputFileName={ e => {
                    /** Update React state */
                    setOutputFileName(e)
                    /** Update Redux Store */
                    Stores.dispatch({ type : "update_OutputFileName"  , OutputFileName : e  } )
                }}

                /** Output extension */
                outputExtension={OutputExtension}
                updateOutputeExtension={ e => {
                    /** Update React state */
                    setOutputExtension(e) 
                    /** Update Redux Store */
                    Stores.dispatch({ type : "update_renderheight"  , renderheight : e  } )
                }}

                /** Output extension options data */

            />
            
        </div>
    )
}

/**
 * Connect Scene Params to redux stores
 * 
 */
export default connect( ({ sceneAnalyze }) =>  ({
    /** Scene path */
    path : sceneAnalyze.path,
    
    /** Render resolution */
    renderwidth : sceneAnalyze.renderwidth,
    renderheight : sceneAnalyze.renderheight,
    ratio : sceneAnalyze.ratio ,  

    /** Frame range */
    job_type : sceneAnalyze.job_type ,
    start_frame : sceneAnalyze.start_frame ,
    end_frame : sceneAnalyze.end_frame , 
    frame_steps : sceneAnalyze.frame_steps,
    single_render_tile : sceneAnalyze.single_render_tile, // on off

    /** Output settings */
    camera : sceneAnalyze.camera,
    outputNameFile : sceneAnalyze.outputNameFile ,
    output_extension : sceneAnalyze.output_extension ,
    camera : sceneAnalyze.camera ,

    /** Gamma Correction */
    output_grammar_corection : sceneAnalyze.output_grammar_corection ,
    gamma_correction : sceneAnalyze.gamma_correction ,
    input_gamma : sceneAnalyze.input_gamma ,
    output_gamma : sceneAnalyze.output_gamma ,


}))(React.memo(MaxParamSettings))