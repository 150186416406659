import React from 'react'

export default function WOAC4DEngineVersion () {
    return(
        <div className="form-group">
        <label htmlFor="render_engine_version" className="form-control-label semibold">Render Engine Version*</label>
        <select id="render_engine_version" className="form-control srf_require_field">
        <option selected>Choose engine version...</option>

          <optgroup label="V-Ray">
              <option value="vray_3">V-Ray 3</option>
          </optgroup>


          <optgroup label="Corona">
            <option value="corona6">Corona 6</option>
            <option value="corona5">Corona 5</option>
            <option value="corona4">Corona 4</option>
            <option value="corona3">Corona 3</option>

          </optgroup>
         
          <optgroup label="arnold" >
            <option value="c4dtoa_3">C4DtoA 3</option>
            <option value="c4dtoa_2">C4DtoA 2</option>
          </optgroup>
          <optgroup label="Cinema 4D" >
            <option value="standard">Standard</option>
            <option value="physical">Physical</option>
            <option value="prorender">ProRender</option>
          </optgroup>
         
        </select>
      </div>
        )
}