import {  MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact';
import React from 'react'

class JobsHistoryDetail extends React.Component {
    render() {
      return (
        <MDBCard>
            <MDBCardBody className="text-light">
            <MDBRow>
                <MDBCol lg="6">
                
                <table>
                <tbody>
                <tr>
                <td width="40%" className="font-semibold">Job ID:</td>
                <td className="col-detail">#1011547</td></tr> <tr><td className="font-semibold">Job name:</td>
                <td className="col-detail">sas</td></tr> <tr><td className="font-semibold">File path:</td>
                <td className="col-detail"><a className="cursor-pointer">candies/candies/H_0401.blend</a></td>
                </tr>
                <tr>
                <td className="font-semibold">Frame:</td>
                <td className="col-detail">[1-1] : 1</td>
                </tr>
                <tr>
                <td className="font-semibold">Submitted at:</td>
                    <td className="col-detail">2021-05-13 17:26:59</td>
                    </tr>
                    <tr>
                    <td className="font-semibold">Start Render at:</td>
                    <td className="col-detail">2021-05-13 17:27:55</td>
                    </tr>
                    <tr><td className="font-semibold">Completed at:</td>
                    <td className="col-detail">--</td>
                    </tr>
                        <tr>
                        <td className="font-semibold">Resolution (W-H):</td>
                        <td className="col-detail">1080-1080</td>
                        </tr>
                        <tr>
                        <td className="font-semibold">Note:</td>
                        <td className="col-detail">asas<i className="feather icon-edit-1 cursor-pointer editNote" />
                        </td>
                        </tr>
                        </tbody>
                        </table>
                        
                </MDBCol>
                <MDBCol lg="6">
                <table>
                <tbody>
                <tr>
                <td className="font-semibold">Selected scene:</td>
                <td className="col-detail">Scene</td>
                </tr>
                <tr><td className="font-semibold">Camera:</td>
                <td className="col-detail">Camera</td>
                </tr>
                <tr>
                <td className="font-semibold">Output format:</td>
                <td className="col-detail">PNG</td>
                </tr>
                <tr>
                <td className="font-semibold">Percentage:</td>
                <td className="col-detail">100</td>
                </tr>
                <tr>
                <td className="font-semibold">Samples:</td>
                <td className="col-detail">300</td>
                </tr>
                <tr>
                <td className="font-semibold">Render by:</td>
                <td className="col-detail">CPU</td>
                </tr>
                <tr>
                <td className="font-semibold">Blender version:</td>
                <td className="col-detail">Blender 2.82</td>
                </tr>
                <tr>
                <td className="font-semibold">Render engine:</td>
                <td className="col-detail">CYCLES</td>
                </tr>
                <tr>
                <td className="font-semibold">Package Type:</td>
                <td className="col-detail">
                <span className="flex"> 10 render nodes</span>
                </td></tr></tbody></table>
                </MDBCol>
                            
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    )}
}
export default JobsHistoryDetail ;
           