import React from 'react'
import SpaceBreadcrumb from './Breadcrumb/SpaceBreadcrumb'

export default function Layout({view}) {
    return (
        <div>
            { /**<SpaceBreadcrumb /> */}
            {view}
        </div>
    )
}
