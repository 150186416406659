
import { MDBBtn, MDBTooltip } from 'mdbreact'
import React from 'react'

class UploadingModal extends React.Component {
    render() {

      return (
        <div className="modal fade" id="UploadList" tabIndex="-1" aria-labelledby="UploadListLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-xl">
    <div className="modal-content bg-dark">
      <div className="modal-header">
        <h5 className="modal-title" id="UploadListLabel">Upload</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close">
        </button>
      </div>
      <div className="modal-body">
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">File Name</th>
          <th scope="col">File Type</th>
          <th scope="col">File Size</th>
          <th scope="col">Progress</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
      <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td><span className="badge bg-success-bright">max</span></td>
      <td>200mb</td>
      <td><progress value="25" max="100"></progress></td>
      <td>
      <MDBTooltip  domElement
      tag="span"
      placement="top">
      <span><MDBBtn className="Ripple-parent bg-danger-bright btn-floating"><i className="fas fa-stop text-danger"></i></MDBBtn></span>
      <span>Stop Upload</span>
      </MDBTooltip>
      </td>
    </tr>
      </tbody>
    </table>



      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
      )}
}
export default UploadingModal ;

