import { ThemeProvider } from "./utils/themeContext"
import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Switch,  useParams } from "react-router-dom"
import routes from "./configs/routes"
import withTracker from './utils/withTracker'
import gateKeeper from './middlewares/gateKeeper'
import Notfound from "./components/Notfound/Notfound"
import UploadBar from "./components/UploadBar/UploadBar"
import env from "./configs/env"
import Logger from "./utils/debug"
import { Builder } from "./app/Builder"
import Parallels from "./parallels"
import Header from './components/Header/Header'

export default () => {


  /**
   * Tell you know is this app in develop or go live.
   * 
   * Config env.js ;
   * if you want to turn off logger, go to env.js file in srf/ folder and change DEBUG=False
   * 
   */
  console.log(
    `%c${atob(`ICBfX19fX18gICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIF8gICAgICAgICAgICAgICAgICAgICAgIF9fXyAgICAgICAgICAgICAgICAgICAKIC8gX19fX18pICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgfCB8ICAgICAgICAgICAgICAgICAgICAgLyBfXykgICAgICAgICAgICAgICAgICAKKCAoX19fXyAgXyAgIF8gX19fXyAgX19fX18gIF9fX18gICAgIF9fX18gX19fX18gX19fXyAgIF9ffCB8X19fX18gIF9fX18gX19fICAgIF98IHxfXyBfX19fXyAgX19fXyBfX19fICAKIFxfX19fIFx8IHwgfCB8ICBfIFx8IF9fXyB8LyBfX18pICAgLyBfX18pIF9fXyB8ICBfIFwgLyBfICB8IF9fXyB8LyBfX18pX19fKSAgKF8gICBfX3xfX19fIHwvIF9fXykgICAgXCAKIF9fX19fKSApIHxffCB8IHxffCB8IF9fX198IHwgICAgICB8IHwgICB8IF9fX198IHwgfCAoIChffCB8IF9fX198IHwgIHxfX18gfCAgICB8IHwgIC8gX19fIHwgfCAgIHwgfCB8IHwKKF9fX19fXy98X19fXy98ICBfXy98X19fX18pX3wgICAgICB8X3wgICB8X19fX18pX3wgfF98XF9fX198X19fX18pX3wgIChfX18vICAgICB8X3wgIFxfX19fX3xffCAgIHxffF98X3wKICAgICAgICAgICAgICB8X3wgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA=`)}`,
    'color:green'      
  )

  console.log(
    `%c${atob(`IF9fX19fXyAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIF8gXyAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICBfICAgICBfICAgICAgICAgICAgIAooX19fX19fKSAgICAgICAgICAgICBfICAgICAgICBfICAgICAgICAgICAgICAgICAgICBfICAgICAgICAgICAgICAgICAgICB8IChfKSAgXyAgICAgICAgICAgICAgICAgICAgICAgICAgXyAgfCB8ICAgKF8pICAgICAgICAgICAgCiBfICAgICBfIF9fXyAgX19fXyBffCB8XyAgICBffCB8XyAgX19fXyBfICAgXyAgICBffCB8XyBfX18gICAgIF9fX19fICBfX3wgfF8gX3wgfF8gICAgX19fX18gX19fXyAgXyAgIF8gX3wgfF98IHxfXyAgXyBfX19fICAgX19fXyAKfCB8ICAgfCAvIF8gXHwgIF8gKF8gICBfKSAgKF8gICBfKS8gX19fKSB8IHwgfCAgKF8gICBfKSBfIFwgICB8IF9fXyB8LyBfICB8IChfICAgXykgIChfX19fIHwgIF8gXHwgfCB8IChfICAgXykgIF8gXHwgfCAgXyBcIC8gXyAgfAp8IHxfXy8gLyB8X3wgfCB8IHwgfHwgfF8gICAgIHwgfF98IHwgICB8IHxffCB8ICAgIHwgfHwgfF98IHwgIHwgX19fXyggKF98IHwgfCB8IHxfICAgLyBfX18gfCB8IHwgfCB8X3wgfCB8IHxffCB8IHwgfCB8IHwgfCAoIChffCB8CnxfX19fXy8gXF9fXy98X3wgfF98IFxfXykgICAgIFxfXylffCAgICBcX18gIHwgICAgIFxfXylfX18vICAgfF9fX19fKVxfX19ffF98ICBcX18pICBcX19fX198X3wgfF98XF9fICB8ICBcX18pX3wgfF98X3xffCB8X3xcX19fIHwKICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgKF9fX18vICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIChfX19fLyAgICAgICAgICAgICAgICAgICAgKF9fX19ffA==`)}`,
    'color:green'      
  )


  console.log(
    `%c${atob(`IF8gICAgIF8gICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgXyBfIF8gICAgIF8gICAgICAgICAgICAgICAgICAgIF8gICAgICAgICAgICAgICAgICAgICAgXyAKfCB8ICAgfCB8ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICBfICAgICAgICAgICAoXykgfCB8ICAgfCB8ICAgICAgICAgICAgICAgICAgfCB8ICAgICAgICAgICAgICAgICAgICB8IHwKfCB8X19ffCB8IF9fXyAgXyAgIF8gIF9fX18gICAgX19fX18gIF9fX18gX19fXyBfX18gIF8gICBfIF9fX18gX3wgfF8gICAgXyBfIF8gX3wgfCB8ICAgfCB8X18gIF9fX19fICAgICBfX19ffCB8IF9fXyAgIF9fXyBfX19fXyAgX198IHwKfF9fX19fICB8LyBfIFx8IHwgfCB8LyBfX18pICAoX19fXyB8LyBfX18pIF9fXykgXyBcfCB8IHwgfCAgXyAoXyAgIF8pICB8IHwgfCB8IHwgfCB8ICAgfCAgXyBcfCBfX18gfCAgIC8gX19fKSB8LyBfIFwgL19fXykgX19fIHwvIF8gIHwKIF9fX19ffCB8IHxffCB8IHxffCB8IHwgICAgICAvIF9fXyAoIChfXyggKF9ffCB8X3wgfCB8X3wgfCB8IHwgfHwgfF8gICB8IHwgfCB8IHwgfCB8ICAgfCB8XykgKSBfX19ffCAgKCAoX19ffCB8IHxffCB8X19fIHwgX19fXyggKF98IHwKKF9fX19fX198XF9fXy98X19fXy98X3wgICAgICBcX19fX198XF9fX18pX19fXylfX18vfF9fX18vfF98IHxffCBcX18pICAgXF9fXy98X3xcXylfKSAgfF9fX18vfF9fX19fKSAgIFxfX19fKVxfKV9fXy8oX19fL3xfX19fXylcX19fX3wKICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA=`)}`,
    'color:red'      
  )

  if(env.DEBUG){
    Logger("FONTS", "Please install FiraCode's Font ", "https://github.com/tonsky/FiraCode")
    Logger('DEVELOP:WARN', 'This website its under develop')
  }else {
    Logger('PRODUCTION:OK', 'Super Renders Farm')
  }

  return (
    <ThemeProvider>

      {
        /**
         * Parallels component run once time ! 
         */
      }
      <Parallels />

      
      <Router >
        <Header />

        <Switch>
            {
              /**
               * Matching browser's path with routers.js configuration.
               * 
               */
            }
            {routes.map((route, index) => {

              /**
               * This is a public route, GateKeeper are sleeping zzzzz, him will not decline this route !
               * 
               */
              if( route.public  )
                return (
                  <Route
                      // Route name
                      key={index}
                      // Regex Path of route
                      path={route.path}
                      // Is this route need to be exactly matching
                      exact={route.exact}
                      component={withTracker(props => {

                        /**
                         * Check out Private Route to see what's differents 
                         * 
                         */
                        
                        return (
                          /**
                           * Application builders
                           * 
                           */
                          Builder(
                            <route.layout {...props}>
                              <route.component {...props} />
                            </route.layout>,

                            /**
                             * Application middlewares
                             * You can add a middleware to any routes.
                             * Login or Logger or something else is fine :3 
                             * 
                             */
                            route.middlewares,
                            route.onRefused
                          )
                          

                        );
                      })}
                    /> 
                )
              
              /**
               * Private Route with GateKeeper
               * GateKeeper is a middleware, GateKeeper will decline all invalid user, kick them ass out of SRF Dashboard
               * 
               */
              return (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={withTracker(props => {
                      /**
                       * Register GateKeeper middleware.
                       * Imagin this is a door, and GateKeeper will ask you secret key to access our penhouse
                       * if you can answer, then you can continue push in to penhouse, but if not, you will go to login page
                       * 
                       */
                      return gateKeeper(
                        <route.layout {...props}>
                          <route.component {...props} />
                        </route.layout>,

                        /**
                         * Application middlewares
                         * You can add a middleware to any routes.
                         * Login or Logger or something else is fine :3 
                         * 
                         */
                        route.middlewares
                      )
                    })}
                  /> 
              )

            })}

            {
              /**
               * If React cannot recognize any route, this route will be show.
               * Like a 404 Page, you know ! 
               * 
               */
            }
          <Route component={ Notfound } />
        </Switch>
    </Router>
    <div>
        
    
    <footer className="fixed-bottom content-footer d-print-none p-2 me-2 bg-none">
      {
        /**
         * This two divs will keep UploadBar in center of screen.
         *
         */
      }
      {/** Keep this div */}
      <div></div>
      
      <div>

        {/**
          Becauce every router change. layout of the view will be re-render. so UploadBar cannot keep him's state. 
          Solution is set UploadBar in root App.js, static file then we can keep UploadBar's states 
          You can change betwen every page, but UploadBar still shown
        */}
        
        <UploadBar />

      </div>
      
      {/** Keep this div */}
      <div></div>
      
    </footer>
    </div>
  </ThemeProvider>
  )
}