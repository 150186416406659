import React, { Component } from "react";
import { MDBBtn, MDBCollapse } from "mdbreact";
import DropzoneToUpload from "../../DropzoneToUpload/DropzoneToUpload" 
import Translate from "../../Translate";
import CreactFolder from "./CreactFolder/CreactFolder";
import Tippy from "@tippyjs/react";



class SpacesUploadRow extends Component {
  
  state = {
    collapseID: "tips",
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }
  render() {

    return (
      <>
          <MDBCollapse id="tips" isOpen={this.state.collapseID} >
              <div className="card-body d-none d-xl-block bg-dark rounded" >
                <div className="d-flex" >
                  <div>
                    - <Translate text="Make sure you" /> <a href="https://support.superrendersfarm.com/hc/en-us/articles/360023844234-Package-your-projects" target="_blank" style={{textDecoration: 'none'}} className="text-success"><Translate text="package your projects" /></a> <Translate text="properly before uploading & rendering your scenes" />. 
                    <br />
                    - <Translate text="We strongly recommend uploading all the project files/scenes before start render jobs. The system then will temporarily pause all the uploads during rendering progress" />. 
                    <br />
                    - <Translate text="It's quite common if you want to simulate your local directory on our render farm, use feature <Create Folder> OR <Auto Keep Local Path (Sync Tool Only)>" /> (<a className="text-success" href="https://support.superrendersfarm.com/hc/en-us/articles/360046290853" target="_blank" style={{textDecoration: 'none'}}><Translate text="Learn more" /></a>)
                    <br />
                    - <Translate text="Please select your scene files to add new render job" />.
                  </div>
                  <div className="ms-auto">
                      <button type="button" className="btn-close" onClick={this.toggleCollapse("tips")} >
                          
                      </button>
                  </div>
                </div>
            </div>
          </MDBCollapse>

          <div className="d-flex mt-2">
            <MDBBtn color="dark" className="me-2" onClick={this.toggleCollapse("upload-form")}>
              <i className="fad fa-folder-upload me-2"></i>
              <Translate text="Upload"/>
            </MDBBtn>
            <MDBBtn color="dark" onClick={this.toggleCollapse("create-folder")}>
              <i className="fad fa-folder-plus me-2"></i>
              <Translate text="Create Folder"/>
            </MDBBtn>
              
            <Tippy content="More" placement="left">
              <div className="dropdown ms-auto">
                <button className="btn btn-dark btn-floating" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="ti-more-alt"></i>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a className="dropdown-item" href="#">Download All</a></li>
                  <li><a className="dropdown-item" href="#">Another action</a></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </div>
            </Tippy>
          </div>
        
          <div className="mt-2">
            <MDBCollapse id="upload-form" isOpen={this.state.collapseID}>
              <DropzoneToUpload />
            </MDBCollapse>
            <MDBCollapse id="create-folder" isOpen={this.state.collapseID}>
              <CreactFolder />
            </MDBCollapse>
          </div>
        </>
      );
    }
}

export default SpacesUploadRow;
