import React  from 'react'
import { Redirect } from 'react-router-dom'


const Form = ({ children, noNavbar, noFooter }) => {

    // if( !localStorage.getItem("jwt") && !localStorage.getItem("username") ) return <Redirect to="/login" />
  
    return (
    <div className="h-100 m-0 p-0 w-100 fixed-top" style={{zIndex:100000,backgroundColor:"#000"}}>
      {children}
    </div>
    )
  };

export default Form