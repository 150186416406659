import DataTable, { createTheme } from 'react-data-table-component';
import React from 'react'
import './jobs-history.scss'
import QuickTips from '../../components/QuickTips/QuickTips';
import JobsHistoryDetail from '../../components/JobsHistoryDetail/JobsHistoryDetail';
import FolderEmpty from '../../components/FolderEmpty/FolderEmpty';
// https://github.com/jbetancur/react-data-table-component


class JobsHistory extends React.Component {
    render() {
      return (
        <div >
          <div className="d-lg-flex d-block">
            <span className="my-auto h3 mb-0">Jobs History</span>
            <div className="ms-auto">
              <QuickTips />
            </div>
          </div>

          <DataTable
          columns={columns}
          data={data}
          pagination
          theme="solarized" customStyles={customStyles}
          Clicked
          expandableRows
          expandableRowDisabled={row => row.disabled}
          defaultSortField="id"
          expandableRowsComponent={<JobsHistoryDetail />}
          noDataComponent={<FolderEmpty />}
          noHeader
          />
        </div>
      )
    }
  };
export default JobsHistory ;


const customStyles = {
  rows: {
    style: {
      minHeight: '60px',
      borderBottomWidth: '0px !important',
      borderTop: 'rgba(81,81,81,1) 1px solid' 
    }
  },
  headCells: {
    style: {
      paddingLeft: '20px', // override the cell padding for head cells
      paddingRight: '20px',
    },
  },
  cells: {
    style: {
      paddingLeft: '20px', // override the cell padding for data cells
      paddingRight: '20px',
    },
  },
};
const columns = [
{
  name: 'ID',
  selector: 'id',
  sortable: true,
},
{
  name: 'Scene',
  selector: 'scene',
  sortable: true,
},
{
  name: 'Created at',
  selector: 'createdAt',
  sortable: true,
  right: true,
},
{
  name: 'Frames',
  selector: 'frames',
  sortable: true,
  right: true,
},
{
  name: 'Priority',
  selector: 'priority',
  sortable: true,
  right: true,
},
{
  name: 'Cost',
  selector: 'cost',
  sortable: true,
  right: true,
},
{
  name: 'Status',
  selector: 'status',
  sortable: true,
  right: true,
},
];

const data = [{ id: 1, scene: 'Conan the Barbarian.c4d', createdAt: '5/10/2021',frames:'0-500',priority:'diamon',cost:'$'+'915' , status:'Done' },
{ id: 2, scene: 'Conan the Barbarian.c4d', createdAt: '5/10/2021',frames:'0-500',priority:'diamon',cost:'$'+'915' , status:'Done' }];
createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#3dd598',
  },
  background: {
    default: '#293134',
  },
  context: {
    background: '#3dd598',
    text: '#FFFFFF',
  },
  divider: {
    default: 'rgba(255,255,255,.54)',
  },
  action: {
    button: 'rgba(255,255,255,.54)',
    hover: 'rgba(255,255,255,.9)',
    disabled: 'rgba(255,255,255,.12)',
  },
});