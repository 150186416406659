import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import Cache from 'i18next-localstorage-cache';
import postProcessor from 'i18next-sprintf-postprocessor';
import LanguageDetector from 'i18next-browser-languagedetector';

/**
 * 
 * List of supported lang
 */

import en from "../languages/en-us"
import vi from "../languages/vi-vn"
import pt from "../languages/pt-br"

i18n
    .use(Backend)
    .use(Cache)
    .use(LanguageDetector)
    .use(postProcessor)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
                "en-US" : en,
                "vi-VN" : vi,
                'pt-BR' :  pt
        },
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
    });

export default i18n