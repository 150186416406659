import { MDBBtn, MDBView } from 'mdbreact';
import React from 'react'
import Carousel from './Carousel'


class BuyCredits extends React.Component {
  render() {
    return (
      <div >
        
        <div className="row mb-4">
          <div className="col-lg-5">
            <div className="h3 text-light">Buy Credits</div>
            <small>Accept all popular global debit, credit card and PayPal payments</small>
          </div>
          <div className="col-lg-7">
            <div className="row d-none d-lg-flex">
              <div className="col-6">
                <div className="row">
                  <div className="col-3">
                    <MDBView>
                      <img src="../assets/media/image/Visa.png" className="img-fluid" alt="" />
                    </MDBView>
                  </div>
                  <div className="col-3">
                    <MDBView>
                      <img src="../assets/media/image/Mastercard.png" className="img-fluid" alt="" />
                    </MDBView>
                  </div>
                  <div className="col-3">
                    <MDBView>
                      <img src="../assets/media/image/American.png" className="img-fluid" alt="" />
                    </MDBView>
                  </div>
                  <div className="col-3">
                    <MDBView>
                      <img src="../assets/media/image/Discover.png" className="img-fluid" alt="" />
                    </MDBView>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-3">
                    <MDBView>
                      <img src="../assets/media/image/Paypal.png" className="img-fluid" alt="" />
                    </MDBView>
                  </div>
                  <div className="col-3">
                    <MDBView>
                      <img src="../assets/media/image/JCB.png" className="img-fluid" alt="" />
                    </MDBView>
                  </div>
                  <div className="col-3">
                    <MDBView>
                      <img src="../assets/media/image/Diners_Club_International.png" className="img-fluid" alt="" />
                    </MDBView>
                  </div>
                  <div className="col-3">
                    <MDBView>
                      <img src="../assets/media/image/DebitCard.png" className="img-fluid" alt="" />
                    </MDBView>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-5 mt-5">
          <div className="col-xl-1"/>
          <div className="col-xl-7">
        <h4 className="text-center">Select your pricing plan</h4>

            <Carousel show={3}>
              <div className="px-1">
                <div className="discount">
                  <div className="text-white h5 font-weight-bold">
                    100 credits
                  </div>
                  <p><i className="fal fa-check me-2 text-success"></i>Premium support</p>
                  <p><i className="fal fa-check me-2 text-success"></i>discount : 5%</p>
                  <div className="cost">
                    95 $
                  </div>
                  <MDBBtn className="text-black" color="success" block>BUY</MDBBtn>
                </div>
              </div>
              <div className="px-1">
                <div className="discount">
                  <div className="text-white h5 font-weight-bold">
                    500 credits
                  </div>
                  <p><i className="fal fa-check me-2 text-success"></i>Premium support</p>
                  <p><i className="fal fa-check me-2 text-success"></i>discount : 10%</p>
                  <div className="cost">
                  450 $
                  </div>
                  <MDBBtn className="text-black" color="success" block>BUY</MDBBtn>
                </div>
              
                </div>
                <div className="px-1">
                  <div className="discount">
                    <div className="text-white h5 font-weight-bold">
                      1000 credits
                    </div>
                    <p><i className="fal fa-check me-2 text-success"></i>Premium support</p>
                    <p><i className="fal fa-check me-2 text-success"></i>discount : 15%</p>
                    <div className="cost">
                    850 $
                    </div>
                    <MDBBtn className="text-black" color="success" block>BUY</MDBBtn>
                  </div>
                  
                </div>
                <div className="px-1">
                  <div className="discount">
                    <div className="text-white h5 font-weight-bold">
                      5000 credits
                    </div>
                    <p><i className="fal fa-check me-2 text-success"></i>Premium support</p>
                    <p><i className="fal fa-check me-2 text-success"></i>discount : 20%</p>
                    <div className="cost">
                    4000 $
                    </div>
                    <MDBBtn className="text-black" color="success" block>BUY</MDBBtn>
                  </div>
                  
                </div>
                <div className="px-1">
                  <div className="discount">
                    <div className="text-white h5 font-weight-bold">
                      10000 credits
                    </div>
                    <p><i className="fal fa-check me-2 text-success"></i>Premium support</p>
                    <p><i className="fal fa-check me-2 text-success"></i>discount : 30%</p>
                    <div className="cost">
                    7000 $
                    </div>
                    <MDBBtn className="text-black" color="success" block>Buy</MDBBtn>
                  </div>
                </div>
            </Carousel>
          </div>
          <div className="col-xl-1"/>
          <div className="col-xl-3 align-self-center">
            <div className="px-1">
            <h4 className="text-center">or custom</h4>
              <div className="discount">
                <div className="text-white h5 font-weight-bold mb-4">
                  Any amount
                </div>
                <p>Standart support</p>
                <p>discount : 5% 
                <div className="float-end">Cost = <span className="text-success"> 20$</span></div>
                </p>
                  <input className="promo form-control my-2" type="text" name="promo" placeholder="Promo code " />
                  <MDBBtn color="success" block>APPLY COUPON CODE</MDBBtn>

                  <input className="point form-control my-2" type="number" name="point" min="1" max="999999" placeholder="Amount point " />
                  <MDBBtn color="success" block>PROCESS PAYMENT</MDBBtn>
                
              </div>
            </div>
    
          </div>
        </div>
    </div>
    )}
}
export default BuyCredits ;