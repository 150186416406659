import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'

/**
* Render Application View but has middleware
* 
* @param {React.Component} ReactComponent Push a react component here 
* @param {App/Middleware} Middlewares This is list of Middlewares
*/
export function Builder(
    ReactComponent,
    Middlewares,
    errorMessages = (<h1>Middleware was decline your request !</h1>)
    ){

    const [passedMiddleware, setPassedMiddleware] = useState(false)
    
    /**
     * 
     * 
     */
    useEffect(() => {

        /**
         * Run once time 
         * 
         * 
         */
        if( Middlewares ){
            const isPass = Middlewares()

            if(isPass) setPassedMiddleware(true)
        }else {
            setPassedMiddleware(true)
        }

    }, [])

    /**
     * 
     * Return Content Components
     */
    return passedMiddleware ? ReactComponent : errorMessages
}