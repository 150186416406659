import { MDBBtn } from 'mdbreact'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Translate from '../components/Translate'
import Stores from '../redux/store'
import QuickTips from '../components/QuickTips/QuickTips'
import Tippy from '@tippyjs/react'

function FileManagerLayout( props ) {
    const {children , RedirectTo} = props 
    const [value, setValue] = useState()
    const refresh = ()=>{
        // re-renders the component
        setValue({})
        
    }
    if(RedirectTo) return ( <Redirect to={RedirectTo} /> )


    return (
        <div >
            <div className="d-flex">
                <div className="h3 text-light m-0">
                    Dropbox
                    <Tippy content={<Translate text="rebuild your working folder"/>} placement="right" >
                        <div className="btn btn-floating btn-sm text-light"  onClick={refresh}>
                        <i className="fad fa-sync" />
                        </div> 
                    </Tippy>
                    <small className="d-block" >{ Stores.getState().UserStates.email }</small>
                </div>
                <QuickTips />
            </div>
   
            <div className="card-body d-none d-xl-block bg-dark rounded mb-2">
                - <Translate text="Make sure you package your projects properly before uploading & rendering your scenes"/>.
                <br/>
                - <Translate text="We strongly recommend uploading all the project files/scenes before start render jobs. The system then will temporarily pause all the uploads during rendering progress"/>.
                <br/>
                - <Translate text="Use refresh button to rebuild your working folder if you think it is not up to date"/>.
                <br/>
                - <Translate text="Please select your scene files to add new render job"/>.
            </div>
            <div className="d-flex mb-2">
                <MDBBtn color="dark" className="me-1">
                <i className="fad fa-folder-upload me-2"></i><Translate text="Upload file"/>
                </MDBBtn>
            </div>
        
            {children}

        </div>
    )
}

export default  connect( state => ({
  RedirectTo : state.LinkStates.to
}))( React.memo(FileManagerLayout) ) ;