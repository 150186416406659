import React from 'react'
import { MDBContainer,MDBNotification } from 'mdbreact'

export default function AppNotification({title, description , delay = 5000 , onDone}) {

  if(onDone) {
    setTimeout(() => {
      onDone()
    }, delay );
  }
  return (
    <MDBContainer
        style={{
          position: "fixed",
          width: "auto",
          top: "10px",
          right: "10px",
          zIndex: 9999 ,
          
        }}
        >
        <MDBNotification
        show
        fade
        titleClassName="d-none"
        bodyClassName="text-white shadow"
        autohide={delay}
        message={
          <div className="row">
            <div className="col-2 align-item-center d-flex">
              <i className="fad fa-bell align-self-center h5 px-2 mt-2 text-black"></i>
            </div>
            <div className="col-10 align-item-center d-flex">
              <div className="align-self-center">
                <div className="w-100"><b>{title}</b></div>
                <div className="w-100"><small>{description}</small></div>
              </div>
            </div>
          </div>}
      style={{backgroundColor: "red"}}
      />
    </MDBContainer>
    )
}