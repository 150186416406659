const initialState = {
    // Dropzone's upload file
    files : [],
    background_uploading : false,
    isDone : false,
    prepairing_files : [],
    calculated_files : [],
    total_size : 0 ,
    upload_started : false ,
    percentages : 0 , // % 
    upload_speed : 0 , // MB/s

}

export default (state = initialState, action) => {
    switch (action.type) {

        /**
         * 
         * When user dispatch UPLOAD_STARTED.
         * Set state for upload_started and files data
         * 
         * 
         */
        case "UPLOAD_STARTED":
            return {
                ...state,
                upload_started : true,
                files : action.files
            };

        /**
         * 
         * Listen for Network Error
         * 
         */
        case "NETWORK_ERROR" :
            return {
                ... state,
            };

        /**
         * Reset state of redux store 
         * 
         */
        case "UPLOAD_DONE" :
            return {
                files : [],
                background_uploading : false,
                isDone : false,
                prepairing_files : [],
                calculated_files : [],
                total_size : 0 ,
                percentages : 0 , // % 
                upload_speed : 0 , // MB/s
                upload_started : false ,
            }
        default:
            return state
    }
};
