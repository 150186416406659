import { MDBBtn } from 'mdbreact';
import React from 'react';
import DataTable from 'react-data-table-component';
import Logger from '../../utils/debug';
import SpaceBreadcrumb from '../Spaces/Breadcrumb/SpaceBreadcrumb';
import FolderSkeleton from '../Spaces/Filemanager/FolderSkeleton';
import { SpacesCss } from '../Spaces/Filemanager/styles'
import tableActions from "./tableActions"

export default function ListView(props){

    const {isFolderEmpty, path , isLoading, data } = props 

    function FolderEmpty () {
        return <div></div>
    }



    /**
     * If user choose listing view, return this 
     *  
     */
     return (
        <DataTable
            // Column template 
           
            // Table data 
            data={data}
            // Dark template ???
            theme="dark"
            // Custom style for table
            customStyles={SpacesCss}
            // Hightlight on hover element
            highlightOnHover
            // Default sort 
            defaultSortField="id"
            // when table data empty, table content will use this component
            noDataComponent={FolderEmpty}
            // Data table title
            title={<SpaceBreadcrumb />}
            // On client selected an element
            onSelectedRowsChange={ item => { Logger("FILES:OK",item) } }
            // Set Selectable item
            selectableRows
            // Set Selectable item hight when user selected
            selectableRowsHighlight
            // table action, show when user selected items
            actions={tableActions()}
            // Right of action table,
            contextActions={<MDBBtn color="dark">Delete</MDBBtn>}
            // Class
            className="fade-in"
            // is current page loading
            progressPending={ isLoading }
            // Loading component will show 
            progressComponent={<FolderSkeleton />}
    />)
}