import React from 'react'
import {  MDBTooltip } from "mdbreact"
import { Link, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import S3Downloader from '../../../utils/S3.Download';
import aws from "aws-sdk"
import Logger from '../../../utils/debug';
import Stores from '../../../redux/store';
const _token = localStorage.getItem("jwt")


const s3DownloadFiles =  (S3 = {}  , files) => {
  

  if(!localStorage.getItem("uwf")) return window.location = "/login"
  const USERNAME = localStorage.getItem("uwf")
  
  const REALPATH = `${USERNAME}${files.path}/`
  
  const s3Download = new S3Downloader( )
  
  s3Download.initial(REALPATH, S3)

  s3Download.fetchAllSubItems() ;

  s3Download.on('data',  data => {
    console.log(data)
  })

}

/**
 * Delete an object from S3 
 * 
 * @param {AWS.S3} S3 Aws Sdk S3 class
 * @param {File} file File Params
 * 
 */
function DeleteCurrentObject(S3 = {} , file ){

  /**
   * Config AWS SDK, prepair for deleting object
   * 
   */
  aws.config.update(S3.config)

  /**
   * Initial Params of delete's file
   * 
   */
  let file_params = {
    Bucket : S3.config.Bucket, // Bucket Id
    Key : file.Key // Key is mean full path of object in S3 Bucket
  }

  /**
   * Initial S3 Client
   * 
   */
  let S3Client = new aws.S3()
  /**
   * Start delete object by AWS SDK
   * 
   */
  S3Client.deleteObject(file_params , (err, data ) => {
    /**
     * Error logger
     * 
     */
    if(err){
      Logger("DELETE_OBJECT:RESPONSE:FAIL", "Cannot delete object", file.name)
    }

    /**
     * On Success handler
     * 
     */
    if(data) {
      Logger("DELETE_OBJECT:RESPONSE:SUCCESS", "Deleted object", file.name)

      /**
       *  Get current DOM element
       * 
       */
      let currentFileItem = document.querySelector(`#srf-space-listing-id-${file.id}`)

      /**
       * Go to table and delete current object 
       * 
       */
      document.body.removeChild(currentFileItem.parentElement)
    }

    /**
     * End function
     */
  })
}


const folderMapping = (S3Browser) =>  [
    {
      name: 'Name',
      selector: 'name',
      cell: row => (
        <div  extension={row.fileExtension}>
              {(()=>{
                if (row.fileType === "folder"){
                  return (
                    <Link  to={row.path}  className="d-flex align-items-center">
                      <figure className="avatar avatar-sm me-3">
                        {(()=>{
                          if ( row.name.length === 1 && isNaN() && row.path.length === 2 ){
                            return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/hhd.png" alt="hhd"/></span>;
                          }
                          return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/folder.png" alt="folder" /></span>
                          })()}
                      </figure>
                      <span className="d-flex flex-column"  >
                        <span className="file-name">
                        {(() => {
                          if ( row.name.length === 1 && isNaN() && row.path.length === 2 ){
                            return ("("+row.name+":)")
                          }
                          else return row.name }) ()}
                          </span>
                      </span>
                    </Link>
                      
                  )
                }
                if(row.fileType === "file" && row.fileExtension != ""){
                  return (
                    <div className="d-flex align-items-center cursor-pointer" onClick={() => alert("hể ?")} >
                    <figure className="avatar avatar-sm me-3">
                    {(() => {
                      if(row.fileExtension === "max" ) {
                        return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/max.png" alt="max" /></span>
                      }
                      if(row.fileExtension === "c4d" ) {
                        return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/c4d.png" alt="c4d" /></span>
                      }
                      if(row.fileExtension === "blend" ) {
                        return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/blender.png" alt="blender" /></span>
                      }
                      if(row.fileExtension === "ma" ) {
                        return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/maya.png" alt="Maya Extension" /></span>
                      }
                      if(row.fileExtension === "mb" ) {
                        return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/maya.png" alt="Maya Extension" /></span>
                      }
                      if(row.fileExtension === "hip" ) {
                        return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/houdini.png" alt="Houdini Extension" /></span>
                      }
                      if(row.fileExtension === "hipnc" ) {
                        return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/houdini.png" alt="Houdini Extension" /></span>
                      }
                      if(row.fileExtension === "hiplc" ) {
                        return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/houdini.png" alt="Houdini Extension" /></span>
                      }
                      if(row.fileExtension === "nk" ) {
                        return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/nuke.png" alt="Nuke Extension" /></span>
                      }
                      if(row.fileExtension === "aep" ) {
                        return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/ae.png" alt="Adobe After Effect Extension" /></span>
                      }
                      return <span className="avatar-title rounded-pill"><img src="https://statics.superrendersfarm.com/app/assets/media/image/file.png" alt="Unknown File Extension" /></span>
                    })()}
                    </figure>
                    <span className="d-flex flex-column"  >
                      <span className="file-name mb-2">
                      {row.name}
                      </span>
                      <small className="text-muted">{(parseInt(row.size) / 1024).toFixed(2) + "MB"}</small>
                    </span>
                  </div>
                  )
                }
              }
                
              )()
            }

      </div>
    ),
        sortable: true,
        minWidth: '300px',
        wrap: true,
    },
      {
        name: 'Progress',
        maxWidth: '200px',
        cell: row => <MDBTooltip  domElement tag="span" placement="top">
        <span></span>
        <span>Your scene file is being analyzed, please wait! It usually takes 5-20 minutes depends on your project size.</span>
      </MDBTooltip>,
      },
    {
      name: 'File type',
      maxWidth: '200px',
      selector: 'fileType',
      cell: row => <div  className="badge bg-success-bright">
      {row.fileExtension != "" ?row.fileExtension: ""}
      </div>,
    },
    {
      name: 'Modified',
      selector: 'modified',
      cell: row => <div>
      {row.fileType === "file" && row.fileExtension != "" ? row.modified : ""}
      </div>,
      maxWidth: '200px',
      sortable: true,
    },
    {
      name: 'Scene History',
      selector: 'sceneHistory',
      maxWidth: '200px',
      sortable: true,
    },
    {
      name: 'Action',
      right: true,
      maxWidth: '100px',
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      cell: row => 
      (<div className="dropleft" id={`srf-space-listing-id-${row.id}`}>
        <button type="button" className="btn btn-dark btn-floating" data-bs-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
          <i className="ti-more-alt"></i>
        </button>
        <div className="dropdown-menu">
          <button onClick={()=> {s3DownloadFiles(S3Browser, row)}}  className="dropdown-item" type="button">Download</button>
          <button className="dropdown-item" type="button">Analyzyn</button>
          <button className="dropdown-item" onClick={ () => {DeleteCurrentObject(S3Browser, row)} } type="button">Delete</button>
        </div>
      </div>),
  
    }
  ]
  

export default folderMapping 