import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import ChangeWebsiteLanguage from '../../ChangeWebsiteLanguage/ChangeWebsiteLanguage';
import Translate from '../../Translate';

const SideBar = ({  toggled, handleToggleSidebar }) => {
     
return (
  <ProSidebar
    toggled={toggled}
    breakPoint="xl"
    onToggle={handleToggleSidebar}
  >
    <SidebarHeader className="logo text-center pt-3">
      <Link to="/">
        <img src="https://statics.superrendersfarm.com/app/assets/media/image/logo.png" alt="logo" />SUPER<span className="text-success">RENDERS</span>
      </Link>
    </SidebarHeader>
    <SidebarContent>
      <Menu popperArrow>
        <SubMenu title="Files Manager" icon={ <i className="fad fa-folders h5"></i>} defaultOpen>
          <MenuItem>
            <NavLink activeClassName="active" to="/spaces" className="my-1 p-2"><i className="fad fa-hdd me-2" />SRF Spaces</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink activeClassName="active" to="/dropbox" className="my-1 p-2"><i className="fab fa-dropbox me-2" />Dropbox</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink activeClassName="active" to="/google-drive" className="my-1 p-2"><i className="fab fa-google-drive me-2" />Google Drive</NavLink>
          </MenuItem>
        </SubMenu>
        <SubMenu title={"Render Jobs"} icon={ <i className="fas fa-tachometer-alt-fast h5"></i>} defaultOpen>
          <MenuItem>
            <NavLink activeClassName="active" to="/render-dashboard" className="my-1 p-2"><i className="fad fa-home me-2" />Render Dashboard</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink activeClassName="active" to="/jobs-history" className="my-1 p-2"><i className="fad fa-history me-2" />Jobs History</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink activeClassName="active" to="/plugins" className="my-1 p-2"><i className="fab fa-app-store me-2" />Plugins</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink activeClassName="active" to="/transfer-method" className="my-1 p-2"><i className="fad fa-exchange me-2" />Transfer Method</NavLink>
          </MenuItem>
        </SubMenu>
        <SubMenu title="Render Credits" icon={ <i className="fad fa-money-check h5"></i>} defaultOpen >
          <MenuItem>
            <NavLink activeClassName="active" to="/buy-credits" className="my-1 p-2"><i className="fad fa-shopping-cart me-2" />Buy Credits</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink activeClassName="active" to="/cost-calculator" className="my-1 p-2"><i className="fad fa-calculator-alt me-2" />Cost Calculator</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink activeClassName="active" to="/payment-invoices" className="my-1 p-2"><i className="fad fa-file-invoice me-2" />Payment Invoices</NavLink>
          </MenuItem>
        </SubMenu>
        <SubMenu title="Help" icon={ <i className="fad fa-user-headset h5"></i>} >
          <MenuItem>
            <NavLink activeClassName="active" to="/create-ticket" className="my-1 p-2"><i className="fad fa-clipboard-check me-2" />Create Ticket</NavLink>
          </MenuItem>
          <MenuItem>
            <a target="_blank" href="https://support.superrendersfarm.com/hc/en-us/categories/360001811753-Rendering" className="nav-item my-1 p-2">
              <i className="fad fa-question-circle me-2" />Support Portal
            </a>
          </MenuItem>
        </SubMenu>
      </Menu>
    </SidebarContent>
    <SidebarFooter>
      <Menu>
        <button type="button" className="btn btn-dark btn-sm" data-bs-toggle="modal" data-bs-target="#ChangeLanguage">
        <i className="fad fa-language me-2 mb-0 h5"></i> <Translate text="Language" />
        </button>
      </Menu>
    </SidebarFooter>
  </ProSidebar>
  )
        
    
}
export default SideBar ; 

