import React, { Component } from 'react'

class Notfound extends Component {
    render() {
        return (
            <section className="container">

        <div className="error-page">
          <div className="error-content">
                <div className="my-4">
                    <span className="error-page-item font-weight-bold">4</span>
                    <span className="error-page-item font-weight-bold">0</span>
                    <span className="error-page-item font-weight-bold">4</span>
                </div>
                <br /><h3><i className="fa fa-warning text-warning" /> We tried to find your page...</h3>
                <p>
                We could not find the page you were looking for.
                Either way, click <a href="https://superrendersfarm.com">here</a> to return to the front page.
                </p>
          </div>
        </div>
      </section>
        );
    }
}

export default Notfound;