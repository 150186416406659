const translate = {
  translation: {
      "Choose Your Language" : "Chọn Ngôn Ngữ",
      "Language" : "Ngôn ngữ" ,
      "Make sure you package your projects properly before uploading & rendering your scenes" : "Hãy chắc rằng bạn đã Package Project của bạn trước khi upload và render",
      "rebuild your working folder" : "rebuild working folder",
      "Use refresh button to rebuild your working folder if you think it is not up to date" : "Sử dụng nút <rebuild working folder> nếu thư mục chưa được cập nhập lại",
      "GENERAL QUICK START" : "BẮT ĐẦU NHANH",
      "How to render on our render farm" : "Cách render trên render farm của chúng tôi",
      "STEP 1. UPLOAD" : "BƯỚC 1. UPLOAD",
      "Remember to Package Your Project before uploading to Super Renders Farm space." : "Hãy nhớ package Project của bạn trước khi upload lên không gian Super Renders Farm.",
      "Upload now" : "Upload",
      "STEP 2. RENDERING" : "BƯỚC 2. TIẾN HÀNH RENDER",
      "Click on your scene file - make a render job - start rendering." : "Nhấp vào file scene của bạn - thực hiện thêm render job - bắt đầu rendering.",
      "Quick tips" : "Mẹo",
      "STEP 3. DOWNLOAD" : "BƯỚC 3. TẢI XUỐNG",
      "Connect to your GoogleDrive or Dropbox to get your render output files." : "Kết nối tới GoogleDrive hoặc Dropbox của bạn để nhận files đã render thành công.",
      "Method" : "Các phương thức",
      "Reload this page" : "Tải lại trang này",
      "SRF Spaces" : "SRF Spaces",
      "ZIP file not supported! it's recommended to extract it and reupload to your shared folder"  :"Tệp ZIP không được hỗ trợ! bạn nên giải nén nó và reupload vào share folder của bạn",
      "TIPS" : "TIPS",
      "Make sure you" : "Hãy chắc chắn rằng bạn đã",
      "package your projects" : "package projects",
      "properly before uploading & rendering your scenes" : "trước khi uploading & rendering scenes của bạn",
      "We strongly recommend uploading all the project files/scenes before start render jobs. The system then will temporarily pause all the uploads during rendering progress" : "Chúng tôi khuyên bạn nên tải lên đầy đủ các file/scenes trước khi tiến hành render. Hệ thống sẽ tự động dừng tất cả luồng upload khi đang render",
      "It's quite common if you want to simulate your local directory on our render farm, use feature <Create Folder> OR <Auto Keep Local Path (Sync Tool Only)>" : "Nó khá phổ biến nếu bạn muốn mô phỏng local directory của mình trong render farm của chúng tôi, hãy sử dụng tính năng <Tạo Folder> hoặc <Tự động giữ đường dẫn cục bộ (Sync Tool Only)>",
      "Learn more" : "Đọc thêm",
      "Please select your scene files to add new render job" : "Vui lòng chọn file scene của bạn và thêm mới vào render job",
      "Upload file" : "Upload file",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
      "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
       "" : "",
      
    }  
}
export default translate