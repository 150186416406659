import React  from 'react'

export default function WOAScenePath({}) {

    return (

    <div className="form-group">
        <label className="form-label semibold">Scene Path*
          <small className="text-muted"><i className="fad fa-folder ms-2" /> Your project saved in Farm hard drive as N:/.</small>
        </label>
        <div className="form-control-wrapper form-control-icon-left" style={{position:"relative"}}>
            <input id="scene_path" type="text" className="form-control srf_require_field" disabled placeholder="Scene Path" style={{paddingLeft:53}} />
            <div className="text-muted" style={{left:15,width:38,height:37,lineHeight:"37px",textAlign:"center",position:"absolute",top:0}}><i className="fa fa-folder" ></i> N:</div>
        </div>
    </div>
            
    )
}