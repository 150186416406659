import React from 'react';

function AnalysisResultPanel() {
    return (
        <div className="row">
            <div className="col-12" id="content_analyze_result_2085737">                                                                                                                                                                                                                              
            <i className="fa fa-warning" style={{color: 'orange'}} />
            <span className="scene-analyze-result-group-title"><strong>Warning</strong></span>
            <div className="analyze-result-group">
                <div className="text-block bs">
                <div>
                    <i className="fa fa-arrow-right color-orange" aria-hidden="true" />
                    Missing textures/assets: there are 2 textures/assets not found. Recommend: Please review your scene, press Shift + T and review your textures/assets list. 
                    <button type="button" className="view-details btn btn-sm btn-info see-more-popup" data-bs-toggle="collapse" data-bs-target="#see-more-popup-warning-detail-1609a3dfe3bff8">
                    View details
                    </button>
                    <div className="expand-hr fade" style={{display: 'block'}} id="see-more-popup-warning-detail-1609a3dfe3bff8">
                    <ul>
                        <li>D:/HP work/Brian Videos/KC_outside_Xsm_blur Black &amp; white.hdr</li>
                        <li>D:/HP work/Brian Videos/3d/brain 1/Texture/KC_outside_Xsm_blur Black &amp; white.hdr</li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default AnalysisResultPanel